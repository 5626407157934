import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Swiper, { Autoplay } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { SvgImage } from 'components/SvgImage'

import { selectCurrentVariantCohort } from 'root-redux/selects/common'

import { selectDynamicDiscount } from 'modules/purchase/redux/selects/common'

import highVoltage from 'assets/images/sprite/high-voltage.svg'

import { Cohort, DynamicDiscountTheme } from 'root-constants'

import { StyledTicker as S } from './Ticker.styles'
import { DEFAULT_DISCOUNT_NAME } from './constants'

Swiper.use([Autoplay])

export const Ticker: React.FC = () => {
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const cohort = useSelector(selectCurrentVariantCohort)
  const { t } = useTranslation()

  const tickerName =
    cohort === Cohort.DB_BELLY_WEEK2
      ? t('onboarding.welcomeBelly.ticker')
      : dynamicDiscount?.name || DEFAULT_DISCOUNT_NAME

  const tickerTheme =
    cohort === Cohort.DB_BELLY_WEEK2
      ? DynamicDiscountTheme.RED
      : dynamicDiscount?.theme

  return (
    <S.Root $theme={tickerTheme}>
      <Carousel
        slidesPerView="auto"
        loop
        speed={5000}
        observeParents
        observer
        spaceBetween={0}
        autoplay={{
          delay: 0,
          pauseOnMouseEnter: false,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide>
          <S.Sale>
            <SvgImage svg={highVoltage} />
            {tickerName}
          </S.Sale>
        </SwiperSlide>
        <SwiperSlide>
          <S.Sale>
            <SvgImage svg={highVoltage} />
            {tickerName}
          </S.Sale>
        </SwiperSlide>
        <SwiperSlide>
          <S.Sale>
            <SvgImage svg={highVoltage} />
            {tickerName}
          </S.Sale>
        </SwiperSlide>
        <SwiperSlide>
          <S.Sale>
            <SvgImage svg={highVoltage} />
            {tickerName}
          </S.Sale>
        </SwiperSlide>
        <SwiperSlide>
          <S.Sale>
            <SvgImage svg={highVoltage} />
            {tickerName}
          </S.Sale>
        </SwiperSlide>
      </Carousel>
    </S.Root>
  )
}
