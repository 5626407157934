import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import { selectUserAvailableSpins } from 'root-redux/selects/user'

import cookie50 from 'assets/animation/cookie50Off.json'
import cookie65 from 'assets/animation/cookie65Off.json'

import { StyledModal as S } from './CongratsModal.styles'

type TProps = {
  isShown: boolean
  onClose: () => void
}

export const CongratsModal: React.FC<TProps> = ({ isShown, onClose }) => {
  const { t } = useTranslation()
  const animationRef = useRef<HTMLDivElement>(null)
  const availableSpins = useSelector(selectUserAvailableSpins)

  useEffect(() => {
    if (animationRef.current && isShown) {
      lottie.loadAnimation({
        container: animationRef.current,
        animationData: availableSpins > 1 ? cookie50 : cookie65,
        name: 'cookie',
        autoplay: true,
        loop: false,
      })
    }

    return () => lottie.destroy('cookie')
  }, [availableSpins, isShown])

  return (
    <S.Wrapper $isShown={isShown}>
      <S.Content>
        <S.Title>{t('purchaseNewYear.congrats')}</S.Title>
        <S.Text $isShown={isShown}>{t('purchaseNewYear.youAreInLuck')}</S.Text>
        <S.CookieAnimation ref={animationRef} />
        <S.ButtonContainer $isShown={isShown}>
          <S.Button type="button" onClick={onClose}>
            {t('purchaseNewYear.grabMyTreat')}
          </S.Button>
        </S.ButtonContainer>
      </S.Content>
    </S.Wrapper>
  )
}
