import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { ContactWithSupport } from 'components/ContactWithSupport'
import { Container } from 'components/Container'
import { SvgImage } from 'components/SvgImage'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import firstStepIcon from 'assets/images/sprite/first-step.svg'
import secondStepIcon from 'assets/images/sprite/second-step.svg'
import thirdStepIcon from 'assets/images/sprite/third-step.svg'

import { PagesSource } from 'root-constants'

import { StyledHowItWorks as S } from './HowItWorks.styles'

type TProps = {
  buttonOrder: number
  onButtonClick: (event: any) => void
}
export const HowItWorks: React.FC<TProps> = ({
  buttonOrder,
  onButtonClick,
}) => {
  const { t } = useTranslation()
  const { buttonText } = useDynamicPaywallConfig()

  return (
    <S.Wrapper>
      <Container>
        <S.ChallengeLabel>
          {t('purchaseRef.dancebitChallenge')}
        </S.ChallengeLabel>

        <S.Title>{t`purchaseRef.howItWorks`}</S.Title>
        <S.Description>
          <Trans i18nKey="purchaseRef.simpleSteps" />
        </S.Description>

        <S.ListBody>
          <S.ListItem>
            <SvgImage svg={firstStepIcon} width={40} height={40} />
            <div>
              <S.ListItemTitle>{t`purchaseRef.firstStepTitle`}</S.ListItemTitle>
              <S.ListItemText>
                <Trans i18nKey="purchaseRef.firstStep" />
              </S.ListItemText>
            </div>
          </S.ListItem>
          <S.ListItem>
            <SvgImage svg={secondStepIcon} width={40} height={40} />
            <div>
              <S.ListItemTitle>{t`purchaseRef.secondStepTitle`}</S.ListItemTitle>
              <S.ListItemText>
                <Trans
                  i18nKey="purchaseRef.secondStep"
                  components={{
                    linkTag: (
                      <ContactWithSupport
                        text="https://account.dance-bit.com/contact-form"
                        source={PagesSource.PAYWALL}
                      />
                    ),
                  }}
                />
              </S.ListItemText>
            </div>
          </S.ListItem>
          <S.ListItem>
            <SvgImage svg={thirdStepIcon} width={40} height={40} />
            <div>
              <S.ListItemTitle>{t`purchaseRef.thirdStepTitle`}</S.ListItemTitle>
              <S.ListItemText>
                <Trans i18nKey="purchaseRef.thirdStep" />
              </S.ListItemText>
            </div>
          </S.ListItem>
        </S.ListBody>

        <S.Disclaimer>
          {t('purchaseRef.disclaimer')}
          <TermsOfUseLink source={PagesSource.PAYWALL} />
        </S.Disclaimer>

        <Button
          type="button"
          data-order={buttonOrder}
          data-text="get_my_plan"
          margin="24px auto"
          onClick={onButtonClick}
        >
          {buttonText || t`actions.getMyPlan`}
        </Button>
      </Container>
    </S.Wrapper>
  )
}
