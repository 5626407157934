import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { sendGAParamsAction } from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectGoogleAnalyticsId,
} from 'root-redux/selects/common'
import { selectUserCountryCode } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'

import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import {
  CookieConsentOption,
  EU_COUNTRY_CODES,
  GAConsent,
  PagePath,
} from 'root-constants'

export const useInitGoogleAnalyticsTracker = (): void => {
  const { pathname } = useLocation()
  const cohort = useSelector(selectCurrentVariantCohort)
  const googleAnalyticsId = useSelector(selectGoogleAnalyticsId)
  const userCountryCode = useSelector(selectUserCountryCode)
  const dispatch = useDispatch()

  const {
    isEUUser,
    hasAnalyticsAndPerformanceCookie,
    userCookieConsentAnswer,
  } = useCookieConsentAnswer()

  const isAnalyticsStorageAllowed = useMemo(
    () => hasAnalyticsAndPerformanceCookie,
    [hasAnalyticsAndPerformanceCookie],
  )

  const shouldGABePaused = useMemo(
    () =>
      (isEUUser && !userCookieConsentAnswer?.length) ||
      (isEUUser &&
        !!userCookieConsentAnswer?.length &&
        !isAnalyticsStorageAllowed),
    [isEUUser, userCookieConsentAnswer, isAnalyticsStorageAllowed],
  )

  useEffect(() => {
    if (!userCountryCode || shouldGABePaused) return

    if (googleAnalyticsId) {
      const initGtag = () => {
        const script = document.createElement('script')
        script.text = `window.dataLayer = window.dataLayer || [];
        gtag = function () { dataLayer.push(arguments); }
        gtag('js', new Date());`
        script.async = true
        document.head.appendChild(script)
      }
      const setUpGTM = () => {
        const script = document.createElement('script')
        script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`
        script.async = true
        document.head.appendChild(script)
      }

      initGtag()
      setUpGTM()

      !window.gtag &&
        window.gtag('consent', 'default', {
          ad_storage: 'denied',
          ad_user_data: 'denied',
          ad_personalization: 'denied',
          analytics_storage: 'granted',
          region: EU_COUNTRY_CODES,
        })

      if (userCookieConsentAnswer?.length) {
        window.gtag &&
          window.gtag('consent', 'update', {
            ad_user_data: userCookieConsentAnswer.includes(
              CookieConsentOption.ADVERTIZING_AND_TARGETING,
            )
              ? GAConsent.GRANTED
              : GAConsent.DENIED,
            ad_personalization:
              userCookieConsentAnswer.includes(
                CookieConsentOption.ADVERTIZING_AND_TARGETING,
              ) &&
              userCookieConsentAnswer.includes(
                CookieConsentOption.FUNCTIONAL_COOKIES,
              )
                ? GAConsent.GRANTED
                : GAConsent.DENIED,
            ad_storage: userCookieConsentAnswer.includes(
              CookieConsentOption.ADVERTIZING_AND_TARGETING,
            )
              ? GAConsent.GRANTED
              : GAConsent.DENIED,
            region: EU_COUNTRY_CODES,
          })
      }

      !!window.gtag &&
        window.gtag('config', googleAnalyticsId, {
          send_page_view: false,
        })

      dispatch(sendGAParamsAction())
    }
  }, [
    dispatch,
    googleAnalyticsId,
    shouldGABePaused,
    userCookieConsentAnswer,
    userCountryCode,
  ])

  useEffect(() => {
    const currentOnboardingVariant = cohort ? `/${cohort}` : ``

    if (window.location.href.includes(PagePath.LOCALHOST)) return

    const currentLocation = `${pathname}${currentOnboardingVariant}`
    googleAnalyticsLogger.logPageView(currentLocation)
  }, [cohort, pathname])
}
