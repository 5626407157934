import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { formatJaPriceWithCommas } from 'helpers/formatPriceWithCommas'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'

import { StyledSubscriptionItemInfo as S } from './SubscriptionItemInfo.styles'

export const SubscriptionItemInfo: React.FC = () => {
  const { t } = useTranslation()

  const { periodQuantity, amountToPay, currency } = usePurchaseStore()

  const calculatedOldPrice = useMemo(() => (amountToPay * 2).toFixed(2), [
    amountToPay,
  ])

  return (
    <>
      <S.PeriodContainer>
        <S.Period>
          {t('purchaseUpgrade.period', {
            periodQuantity,
          })}
        </S.Period>
      </S.PeriodContainer>

      <S.PricesContainer>
        <S.Old>{t('purchaseUpgrade.oldPrice')}</S.Old>
        <S.OldPrice>
          <Trans
            i18nKey="purchase1.checkout.price"
            values={{
              price: formatJaPriceWithCommas(calculatedOldPrice, currency),
              currency: CURRENCY_SYMBOLS[currency],
            }}
          />
        </S.OldPrice>
      </S.PricesContainer>

      <S.PricesContainer>
        <S.NewPriceContainer>
          <S.NewPrice>{t('purchaseUpgrade.newPrice')}</S.NewPrice>
          <S.Discount>{t('purchaseUpgrade.discount')}</S.Discount>
        </S.NewPriceContainer>
        <S.NewPrice>
          <Trans
            i18nKey="purchase1.checkout.price"
            values={{
              price: formatJaPriceWithCommas(amountToPay.toFixed(2), currency),
              currency: CURRENCY_SYMBOLS[currency],
            }}
          />
        </S.NewPrice>
      </S.PricesContainer>

      <S.AdditionalInfoBorder />
    </>
  )
}
