import { AvailableAttempt } from 'root-constants'

export const AUDIO_START_POINT = 0
export const GAME_STEP = 1

export const WHEEL_ANIMATION_SEGMENTS = {
  [AvailableAttempt.TWO_ATTEMPTS]: [120, 220],
  [AvailableAttempt.ONE_ATTEMPT]: [240, 320],
}

export const WHEEL_ANIMATION_START_POINTS = {
  [AvailableAttempt.TWO_ATTEMPTS]: 0,
  [AvailableAttempt.ONE_ATTEMPT]: 120,
  [AvailableAttempt.NO_ATTEMPTS]: 240,
}

export const ATTEMPT_NUMBERS = {
  [AvailableAttempt.TWO_ATTEMPTS]: 1,
  [AvailableAttempt.ONE_ATTEMPT]: 2,
}
