import styled from 'styled-components'

import { Container } from 'components/Container'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { PageTitle } from 'components/PageTitle'

export const StyledSupportiveMessage = {
  Wrapper: styled.div<{ $background: string }>`
    background: ${({ $background }) => $background};
  `,
  Container: styled(Container)<{ $backgroundImage: string }>`
    background-image: ${({ $backgroundImage }) => `url(${$backgroundImage})`};
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    height: calc(100vh - 52px);
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
  `,
  PageTitle: styled(PageTitle)`
    text-align: left;
  `,
  Image: styled.img`
    width: 100%;
  `,
  TextBlock: styled(Container)`
    position: absolute;
    bottom: 0;
  `,
  Description: styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 8px;
  `,
  NavigationButtonBelly: styled(NavigationButtonBelly)`
    background-color: transparent;
    border: none;
  `,
}
