import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { LoseWeightGraphBelly } from 'components/LoseWeightGraphBelly'
import { Spinner } from 'components/Spinner'
import { Ticker } from 'components/Ticker'

import {
  setScreenIdAction,
  setScreenNameAction,
  startFetching,
} from 'root-redux/actions/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useVatInfo } from 'hooks/useVatInfo'
import { useViewportValue } from 'hooks/useViewportValue'

import { formatJaPriceWithCommas } from 'helpers/formatPriceWithCommas'
import { getCalculatedPrice } from 'helpers/getCalculatedPrice'

import { CourseDescriptionV2 } from 'modules/purchase/components/CourseDescriptionV2'
import { FeaturedBlockV2 } from 'modules/purchase/components/FeaturedBlockV2'
import { MoneyBackGuarantee } from 'modules/purchase/components/MoneyBackGuarantee'
import { PhotoResultV3 } from 'modules/purchase/components/PhotoResultV3'
import {
  SLIDES_FEMALE,
  SLIDES_MALE,
} from 'modules/purchase/components/PhotoResultV3/constant'
import { ReceivedPlan } from 'modules/purchase/components/ReceivedPlan'
import { SubheaderWithTimerTwoWeek } from 'modules/purchase/components/SubheaderWithTimerTwoWeek/SubheaderWithTimerTwoWeek'
import { CURRENCY_SYMBOLS, WEEK_DURATION } from 'modules/purchase/constants'
import { usePurchaseAnalytics } from 'modules/purchase/hooks/usePurchaseAnalytics'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { CheckoutSeparateMethods } from 'modules/purchase/pages/CheckoutSeparateMethods'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'
import {
  selectDynamicDiscount,
  selectSubscription,
  selectSubscriptionId,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import usersRecommended from 'assets/images/recommended-by.png'

import { replaceHistory } from 'browser-history'
import { PageId } from 'page-constants'
import { ScreenId, ScreenName, TimeInterval } from 'root-constants'

import { StyledPurchaseTrial as S } from './PurchaseTrialV2.styles'

export const PurchaseTrialV2: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()
  const selectedSubscription = useSelector(selectSubscription)
  const { buttonText } = useDynamicPaywallConfig()
  const selectedSubscriptionId = useSelector(selectSubscriptionId)
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const hasIncludedVat = useVatInfo()

  const elemForComparisonRef = useRef<HTMLDivElement>(null)

  const {
    currency,
    trialPrice,
    trialPeriodDays,
    fullPrice,
    periodQuantity,
    periodName,
    discountPercentage,
    countryCode,
    taxAmount,
  } = usePurchaseStore()

  usePurchaseAnalytics({
    screenName: ScreenName.ONBOARDING,
    screenId: ScreenId.SHORT_SEVEN_DAYS,
  })

  const [isCheckoutShown, setIsCheckoutShown] = useState(false)

  useViewportValue(ScreenName.ONBOARDING, !isCheckoutShown)

  useLayoutEffect(() => {
    if (!selectedSubscription) {
      replaceHistory({
        pathname: PageId.CHOOSE_TRIAL_PRICE,
        search,
      })
    }
  }, [selectedSubscription, search])

  useEffect(() => {
    dispatch(setScreenNameAction(ScreenName.ONBOARDING))
    dispatch(setScreenIdAction(ScreenId.SHORT_SEVEN_DAYS))
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
  }, [dispatch])

  const handleShowPayment = useCallback((event) => {
    eventLogger.logPlansPageButtonTap({
      screenName: ScreenName.ONBOARDING,
      buttonNumber: event.target.getAttribute('data-order'),
      buttonText: event.target.getAttribute('data-text'),
    })

    setIsCheckoutShown(true)
  }, [])

  const getCurrentPrice = useCallback(
    (value?: number | string) =>
      hasIncludedVat && value
        ? getCalculatedPrice(value, taxAmount, countryCode)
        : value || '',

    [countryCode, hasIncludedVat, taxAmount],
  )

  return !selectedSubscription ? (
    <Spinner />
  ) : (
    <>
      {!isCheckoutShown && (
        <>
          <Ticker />
          <CourseDescriptionV2 />
          <S.Wrapper>
            <SubheaderWithTimerTwoWeek
              elemForComparisonRef={elemForComparisonRef}
              onButtonClick={handleShowPayment}
            />

            <S.AnimationContainer ref={elemForComparisonRef}>
              <LoseWeightGraphBelly />
              <S.UserRecommended>
                <S.Img src={usersRecommended} alt="Users recommended" />
                <S.Text>{t('onboarding.planPageIsReady.recommendedBy')}</S.Text>
              </S.UserRecommended>
            </S.AnimationContainer>

            <S.ButtonContainer>
              <S.Button
                type="button"
                data-order="2"
                data-text="continue"
                onClick={handleShowPayment}
              >
                {buttonText || t('actions.continue')}
              </S.Button>
            </S.ButtonContainer>

            <S.PricesWrapper>
              <S.PriceTitle $gridArea="priceToday">
                {t`purchase3.subscriptionsBlock.priceToday`}
              </S.PriceTitle>
              <S.PriceText $gridArea="trialText">
                {t('purchase3.subscriptionsBlock.trialText', {
                  trialPeriodDays,
                })}
              </S.PriceText>
              <S.PriceText $gridArea="trialPrice" $textAlign="right">
                {t('purchase3.subscriptionsBlock.price', {
                  price: formatJaPriceWithCommas(
                    getCurrentPrice(trialPrice),
                    currency,
                  ),
                  currency: CURRENCY_SYMBOLS[currency],
                })}
              </S.PriceText>
              <S.PriceText $gridArea="fullPriceDiscount">
                <S.Discount>
                  {t('purchase3.subscriptionsBlock.discount', {
                    discount: dynamicDiscount?.amount || discountPercentage,
                  })}
                </S.Discount>
              </S.PriceText>

              <S.PriceTitle $gridArea="priceAfter">{t`purchase3.subscriptionsBlock.priceAfter`}</S.PriceTitle>
              <S.PriceText $gridArea="fullPriceText">
                {periodName === TimeInterval.DAY
                  ? t('purchaseWeeklyOffer.paymentForm.planDescription', {
                      count: Math.round(periodQuantity / WEEK_DURATION),
                    })
                  : t('purchaseIntroOffer.monthlyPeriod', {
                      count: periodQuantity,
                    })}
              </S.PriceText>
              <S.PriceText $gridArea="fullPrice" $textAlign="right">
                {t('purchase3.subscriptionsBlock.price', {
                  price: formatJaPriceWithCommas(
                    getCurrentPrice(fullPrice),
                    currency,
                  ),
                  currency: CURRENCY_SYMBOLS[currency],
                })}
              </S.PriceText>
            </S.PricesWrapper>
            <ReceivedPlan />

            <S.ButtonContainer>
              <S.Button
                type="button"
                data-order="3"
                data-text="continue"
                onClick={handleShowPayment}
              >
                {buttonText || t('actions.continue')}
              </S.Button>
            </S.ButtonContainer>

            <S.Title>{t('purchase3.reviews.title')}</S.Title>
            <PhotoResultV3
              hasTitle={false}
              femaleSlides={SLIDES_FEMALE}
              maleSlides={SLIDES_MALE}
            />
            <S.SecurityInfo />
            <MoneyBackGuarantee />
            <FeaturedBlockV2 />
          </S.Wrapper>
        </>
      )}

      <S.CheckoutContainer $isVisible={isCheckoutShown}>
        <CheckoutSeparateMethods
          key={selectedSubscriptionId}
          isCheckoutShown={isCheckoutShown}
          setIsCheckoutShown={setIsCheckoutShown}
        />
      </S.CheckoutContainer>
    </>
  )
}
