import React, { FC, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectUserCountryCode } from 'root-redux/selects/user'

import { useVatInfo } from 'hooks/useVatInfo'

import { formatJaPriceWithCommas } from 'helpers/formatPriceWithCommas'
import { getCalculatedPrice } from 'helpers/getCalculatedPrice'

import {
  CURRENCY_SYMBOLS,
  Currency,
  GeneralProduct,
  TRIAL_ONE_MONTH_DURATION,
} from 'modules/purchase/constants'
import { selectTaxAmount } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { StyledSubscriptionItemVariant2 as S } from './SubscriptionItemVariant2.styles'

type TProps = {
  subscription: ISubscription
  isSelected: boolean
}

export const SubscriptionItemVariant2: FC<TProps> = ({
  subscription: {
    id,
    product,
    mainPrices: { periodQuantity, fullPrice },
    trialPrices: {
      fullPrice: trialCurrentPrice,
      daily,
      durationDays,
      oldPrices,
    },
    isDefault,
    currency,
  },
  isSelected,
}) => {
  const { t } = useTranslation()
  const hasVatInfo = useVatInfo()
  const taxAmount = useSelector(selectTaxAmount)
  const country = useSelector(selectUserCountryCode)

  const oldPriceWithFractionalPart = useMemo(() => {
    if (product === GeneralProduct.SEVEN_DAY || !trialCurrentPrice) {
      return oldPrices.beforeCustomDiscount?.fullPrice.toFixed(2)
    }

    return fullPrice.toFixed(2)
  }, [product, trialCurrentPrice, fullPrice, oldPrices])

  const customPriceWithFractionalPart = useMemo(() => daily?.toFixed(2), [
    daily,
  ])

  const hasMexicanCurrency = useMemo(() => currency === Currency.MXN, [
    currency,
  ])

  const oldPriceSecondWithFractionalPart = useMemo(
    () => oldPrices.beforeCustomDiscount?.daily.toFixed(2),
    [oldPrices],
  )

  const currentPriceWithFractionalPart = useMemo(
    () => (trialCurrentPrice || fullPrice).toFixed(2),
    [trialCurrentPrice, fullPrice],
  )

  const getCurrentPrice = useCallback(
    (value) =>
      hasVatInfo ? getCalculatedPrice(value, taxAmount, country) : value,
    [country, hasVatInfo, taxAmount],
  )

  const priceCent = useMemo(
    () => getCurrentPrice(customPriceWithFractionalPart).split('.'),
    [customPriceWithFractionalPart, getCurrentPrice],
  )

  return (
    <S.Wrapper $isSelected={isSelected} key={id}>
      <S.Content $isSelected={isSelected}>
        {isDefault && (
          <S.MostPopularBadge>{t`purchase1.subscription.mostPopularBadge`}</S.MostPopularBadge>
        )}
        <S.PlanContainer>
          <S.PlanPeriod $isSelected={isSelected}>
            {product === GeneralProduct.SEVEN_DAY ? (
              <Trans
                i18nKey="purchaseIntroOffer.weeklyPeriod"
                values={{ trialPeriodDays: durationDays }}
                components={[<br />]}
              />
            ) : (
              <Trans
                i18nKey="purchaseIntroOffer.monthlyPeriod"
                values={{
                  count: durationDays
                    ? Math.round(durationDays / TRIAL_ONE_MONTH_DURATION)
                    : periodQuantity,
                }}
                components={[<br />]}
              />
            )}
          </S.PlanPeriod>

          <S.PriceContainer>
            <div>
              <S.PreviousPrice $hasMxnCurrency={hasMexicanCurrency}>
                <Trans
                  i18nKey="purchase1.checkout.price"
                  values={{
                    price: formatJaPriceWithCommas(
                      getCurrentPrice(oldPriceWithFractionalPart),
                      currency,
                    ),
                    currency: CURRENCY_SYMBOLS[currency],
                  }}
                />
              </S.PreviousPrice>
              <S.CurrentPrice $hasMxnCurrency={hasMexicanCurrency}>
                <Trans
                  i18nKey="purchase1.checkout.price"
                  values={{
                    price: formatJaPriceWithCommas(
                      getCurrentPrice(currentPriceWithFractionalPart),
                      currency,
                    ),
                    currency: CURRENCY_SYMBOLS[currency],
                  }}
                />
              </S.CurrentPrice>
            </div>

            <S.CustomPreviousPrice>
              <Trans
                i18nKey="purchase1.checkout.price"
                values={{
                  price: formatJaPriceWithCommas(
                    getCurrentPrice(oldPriceSecondWithFractionalPart),
                    currency,
                  ),
                  currency: CURRENCY_SYMBOLS[currency],
                }}
              />
            </S.CustomPreviousPrice>
          </S.PriceContainer>
        </S.PlanContainer>

        <S.CustomPrice $isSelected={isSelected}>
          <S.PriceValue>
            <S.Currency $hasMxnCurrency={hasMexicanCurrency}>
              {CURRENCY_SYMBOLS[currency]}
            </S.Currency>
            <S.Zero $hasMxnCurrency={hasMexicanCurrency}>{priceCent[0]}</S.Zero>
            <S.CentsBlock>
              <S.Cents>.{priceCent[1]}</S.Cents>
              <S.Period $hasMxnCurrency={hasMexicanCurrency}>
                {t`purchase1.subscription.interval`}{' '}
                {t(`purchase1.subscription.day`)}
              </S.Period>
            </S.CentsBlock>
          </S.PriceValue>
        </S.CustomPrice>
      </S.Content>
    </S.Wrapper>
  )
}
