import React, { FC, useCallback } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  OPTION_VALUES,
  QUESTION,
} from 'pages/questions/SupportiveMessage/constants'

import { selectUserSatisfied } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledSupportiveMessage as S } from './SupportiveMessage.styles'

export const SupportiveMessage: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const userSatisfied = useSelector(selectUserSatisfied)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(QUESTION), [
    handleContinue,
  ])

  return (
    <S.Wrapper $background={OPTION_VALUES[userSatisfied].background}>
      <S.Container
        isLarge
        $backgroundImage={OPTION_VALUES[userSatisfied].image}
      >
        <S.Content>
          <S.TextBlock>
            <S.PageTitle marginBottom={8}>
              <Trans
                i18nKey={`onboarding.supportiveMessage.${userSatisfied}.title`}
              />
            </S.PageTitle>
            <S.Description>
              <Trans
                i18nKey={`onboarding.supportiveMessage.${userSatisfied}.firstParagraph`}
              />
            </S.Description>
            <S.Description>
              <Trans
                i18nKey={`onboarding.supportiveMessage.${userSatisfied}.secondParagraph`}
              />
            </S.Description>
            <S.NavigationButtonBelly type="button" onClick={handleClick}>
              <Trans i18nKey="actions.continue" />
            </S.NavigationButtonBelly>
          </S.TextBlock>
        </S.Content>
      </S.Container>
    </S.Wrapper>
  )
}
