import { PageId } from 'page-constants'

export const PAGES_WITHOUT_BACK_BUTTON = [
  PageId.WELCOME,
  PageId.MAIN_GOAL,
  PageId.MAIN_GOAL_2024,
  PageId.MAIN_GOAL_SOMATIC,
  PageId.MAIN_GOAL_VIDEO,
  PageId.PERSONAL_HISTORY,
  PageId.EMAIL_CONSENT,
  PageId.ENTER_EMAIL,
  PageId.ENTER_EMAIL_NO_ONBOARDING,
  PageId.PREPARE_PLAN,
  PageId.DANCE_VS_EXERCISES,
  PageId.SOCIAL_PROOF,
  PageId.NICE_TO_MEET,
  PageId.DANCING_STYLES_INTRO,
  PageId.RESULT,
  PageId.CHOOSE_TRIAL_PRICE,
  PageId.PURCHASE,
  PageId.PURCHASE_EMAIL,
  PageId.PURCHASE_INTRO_OFFER,
  PageId.PURCHASE_TRIAL,
  PageId.PURCHASE_UPGRADE,
  PageId.PURCHASE_UPGRADE_MEAL_PLAN,
  PageId.PURCHASE_FREE_OFFER,
  PageId.PURCHASE_UPGRADE_PDF,
  PageId.PURCHASE_DOP,
  PageId.PURCHASE_WITHOUT_INTRO,
  PageId.PURCHASE_WITHOUT_INTRO2,
  PageId.PURCHASE_WEEKLY_OFFER,
  PageId.CANCEL_OFFER_QUESTION,
  PageId.CANCEL_OFFER_QUESTION_V2,
  PageId.PURCHASE_CANCEL_OFFER,
  PageId.PURCHASE_CANCEL_OFFER_SOMATIC,
  PageId.PURCHASE_CANCEL_OFFER_FREE,
  PageId.PURCHASE_CANCEL_OFFER_MEAL_PLAN,
  PageId.PURCHASEV2,
  PageId.UPSELL,
  PageId.UPSELL_CANCEL_OFFER,
  PageId.DOWNLOAD,
  PageId.UPGRADE_LIFE,
  PageId.TEN_MILLION_PEOPLE,
  PageId.PURCHASE_TWO_PLANS,
  PageId.PREPAYWALL,
  PageId.WELCOME_WEIGHT_LOSS,
  PageId.PURCHASE_REF,
  PageId.PURCHASE_CANCEL_OFFER_REF,
  PageId.PURCHASE_TRIAL,
  PageId.PURCHASE_NEW_YEAR,
]
