import React, { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectIsStayFitFlow } from 'root-redux/selects/common'

import { DEFAULT_PLAN_BENEFITS } from 'modules/purchase/components/ReceivedPlan/constants'

import line from 'assets/images/sprite/dotted-line.svg'

import { MAIN_GOALS } from 'root-constants'

import { SubscriptionBlockWithDescription } from '../SubscriptionBlockWithDescription'
import { StyledReceivedPlan as S } from './ReceivedWeeklyPlan.styles'

type TProps = {
  className?: string
  elemForComparisonRef?: React.RefObject<HTMLDivElement>
  children?: React.ReactNode
}
export const ReceivedWeeklyPlan: FC<TProps> = ({
  className = '',
  elemForComparisonRef,
  children,
}) => {
  const { t } = useTranslation()

  const isStayFitGoal = useSelector(selectIsStayFitFlow)

  return (
    <>
      <S.ProgramPlanTitle>
        <Trans i18nKey="purchaseWithoutIntro.titleWeek" />
      </S.ProgramPlanTitle>

      <S.DynamicTimer />

      <S.ProgramPlanContainer className={className} ref={elemForComparisonRef}>
        <ul>
          <S.ListItem>
            <Trans
              i18nKey={DEFAULT_PLAN_BENEFITS.first}
              values={{
                context: isStayFitGoal
                  ? MAIN_GOALS.STAY_FIT
                  : MAIN_GOALS.LOSE_WEIGHT,
              }}
              components={[<strong />]}
            />
          </S.ListItem>
          <S.ListItem>
            <Trans
              i18nKey={DEFAULT_PLAN_BENEFITS.second}
              values={{
                context: isStayFitGoal
                  ? MAIN_GOALS.STAY_FIT
                  : MAIN_GOALS.LOSE_WEIGHT,
              }}
            />
          </S.ListItem>
          <S.ListItem>
            <Trans i18nKey={DEFAULT_PLAN_BENEFITS.third} />
          </S.ListItem>

          <S.ListItem>{t(DEFAULT_PLAN_BENEFITS.fourth)}</S.ListItem>
          <S.ListItem>{t(DEFAULT_PLAN_BENEFITS.fifth)}</S.ListItem>
        </ul>

        <S.Border svg={line} />

        <SubscriptionBlockWithDescription>
          {children}
        </SubscriptionBlockWithDescription>
      </S.ProgramPlanContainer>
    </>
  )
}
