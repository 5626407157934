import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AnswerWithCheckbox, answerWithCheckboxTheme } from 'storybook-ui'

import { Container } from 'components/Container'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import checkIcon from 'assets/images/checkbox-check-white.svg'

import { CDN_FOLDER_LINK } from 'root-constants'

import { StyledDancingStyleBelly as S } from './DancingStyleBelly.styles'
import { NEW_DANCE_STYLES, OPTION_VALUES, QUESTION } from './constants'

export const DancingStyleBelly: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId as string,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          setAnswers(answers.filter((item) => item !== value))
        }
      },
    }),
    [pageId, answers],
  )

  const handleClick = useCallback(() => handleContinue(answers), [
    handleContinue,
    answers,
  ])

  return (
    <>
      <Container isLarge>
        <PageTitle marginBottom={8}>
          <Trans i18nKey="onboarding.dancingStyle.question" />
        </PageTitle>
        <S.Subtitle>{t`onboarding.dancingStyle.description`}</S.Subtitle>

        {Object.values(OPTION_VALUES).map((style) => (
          <AnswerWithCheckbox
            {...optionProps}
            theme={answerWithCheckboxTheme.DANCEBIT}
            isFullWidth
            padding="8px 16px 8px 8px"
            margin="0 0 16px 0"
            borderRadius="16px"
            height="116px"
            iconSrc={checkIcon}
            key={style}
            value={style}
            checked={answers.includes(style)}
          >
            <S.DanceCard $isSelected={answers.includes(style)}>
              <video
                muted
                loop
                autoPlay
                controls={false}
                playsInline
                poster={`${CDN_FOLDER_LINK}/videos/${style}Cover.png`}
              >
                <source
                  src={`${CDN_FOLDER_LINK}/videos/${style}.mp4`}
                  type="video/mp4"
                />
              </video>
              <S.DanceInfo>
                {NEW_DANCE_STYLES.includes(style) && (
                  <S.New>{t`onboarding.dancingStyle.new`}</S.New>
                )}
                <S.DanceTitle>
                  {t(`onboarding.dancingStyle.${style}`, {
                    context: 'v2',
                  })}
                </S.DanceTitle>
                <S.Description>
                  {t(`onboarding.dancingStyle.${style}Description`)}
                </S.Description>
              </S.DanceInfo>
            </S.DanceCard>
          </AnswerWithCheckbox>
        ))}
      </Container>

      <NavigationButtonBelly
        type="button"
        disabled={!answers.length}
        onClick={handleClick}
      >
        {t`actions.continue`}
      </NavigationButtonBelly>
    </>
  )
}
