import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SvgImage } from 'components/SvgImage'

import { selectLanguage } from 'root-redux/selects/common'

import { formatJaPriceWithCommas } from 'helpers/formatPriceWithCommas'

import {
  CURRENCY_SYMBOLS,
  Currency,
  GeneralProduct,
} from 'modules/purchase/constants'

import { ISubscription } from 'models/subscriptions.model'

import arrowIcon from 'assets/images/sprite/pricesArrow.svg'

import { Locale } from 'root-constants'

import { StyledSubscriptionItemIntroOfferV2 as S } from './SubscriptionItemIntroOfferV2.styles'

type TProps = {
  subscription: ISubscription
  isSelected: boolean
}

export const SubscriptionItemIntroOfferV2: React.FC<TProps> = ({
  subscription: {
    id,
    product,
    mainPrices: { fullPrice, daily: fullDailyPrice },
    trialPrices: {
      fullPrice: trialCurrentPrice,
      daily: trialDailyPrice,
      durationDays,
      oldPrices,
    },
    isDefault,
    currency,
  },
  isSelected,
}) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)

  const oldPriceWithFractionalPart = useMemo(() => {
    if (product === GeneralProduct.SEVEN_DAY || !trialCurrentPrice) {
      return oldPrices.beforeCustomDiscount?.fullPrice.toFixed(2)
    }

    return fullPrice.toFixed(2)
  }, [product, trialCurrentPrice, fullPrice, oldPrices])

  const customPriceWithFractionalPart = useMemo(
    () => (trialDailyPrice || fullDailyPrice)?.toFixed(2),
    [trialDailyPrice, fullDailyPrice],
  )

  const hasMexicanCurrency = useMemo(() => currency === Currency.MXN, [
    currency,
  ])

  const hasSpanishLocale = useMemo(
    () => language === Locale.SPANISH && !hasMexicanCurrency,
    [language, hasMexicanCurrency],
  )

  const oldPriceSecondWithFractionalPart = useMemo(
    () => oldPrices.beforeCustomDiscount?.daily.toFixed(2),
    [oldPrices],
  )

  const currentPriceWithFractionalPart = useMemo(
    () => (trialCurrentPrice || fullPrice).toFixed(2),
    [trialCurrentPrice, fullPrice],
  )

  const trialDurationInWeeks = useMemo(() => durationDays / 7, [durationDays])

  return (
    <S.Wrapper key={id} $isSelected={isSelected} $isDefault={isDefault}>
      {isDefault && (
        <S.MostPopularBadge>{t`purchase1.subscription.mostPopularBadge`}</S.MostPopularBadge>
      )}
      <S.Content $isSelected={isSelected}>
        <S.PlanContainer>
          <S.PlanPeriod
            $hasMexicanCurrency={hasMexicanCurrency}
            $hasSpanishLocale={hasSpanishLocale}
            $isSelected={isSelected}
          >
            <Trans
              i18nKey="purchaseWeeklyOffer.paymentForm.planDescription"
              values={{ count: trialDurationInWeeks }}
            />
          </S.PlanPeriod>

          <S.PriceContainer>
            <S.PreviousPrice $hasMexicanCurrency={hasMexicanCurrency}>
              <Trans
                i18nKey="purchase1.checkout.price"
                values={{
                  price:
                    oldPriceWithFractionalPart &&
                    formatJaPriceWithCommas(
                      oldPriceWithFractionalPart,
                      currency,
                    ),
                  currency: CURRENCY_SYMBOLS[currency],
                }}
              />
            </S.PreviousPrice>

            <SvgImage svg={arrowIcon} width={16} height={16} />

            <S.CurrentPrice
              $hasMexicanCurrency={hasMexicanCurrency}
              $isSelected={isSelected}
            >
              <Trans
                i18nKey="purchase1.checkout.price"
                values={{
                  price: formatJaPriceWithCommas(
                    currentPriceWithFractionalPart,
                    currency,
                  ),
                  currency: CURRENCY_SYMBOLS[currency],
                }}
              />
            </S.CurrentPrice>
          </S.PriceContainer>
        </S.PlanContainer>

        <S.CustomPrice $isSelected={isSelected}>
          <S.CustomPreviousPrice>
            {oldPriceSecondWithFractionalPart && (
              <Trans
                i18nKey="purchase1.checkout.price"
                values={{
                  price: formatJaPriceWithCommas(
                    oldPriceSecondWithFractionalPart,
                    currency,
                  ),
                  currency: CURRENCY_SYMBOLS[currency],
                }}
              />
            )}
          </S.CustomPreviousPrice>
          <S.PriceValue
            $hasMexicanCurrency={hasMexicanCurrency}
            $hasSpanishLocale={hasSpanishLocale}
            $isSelected={isSelected}
          >
            <Trans
              i18nKey="purchase1.checkout.price"
              values={{
                price: formatJaPriceWithCommas(
                  customPriceWithFractionalPart,
                  currency,
                ),
                currency: CURRENCY_SYMBOLS[currency],
              }}
            />
          </S.PriceValue>
          <S.Period>
            {t`purchase1.subscription.interval`}{' '}
            {t(`purchase1.subscription.day`)}
          </S.Period>
        </S.CustomPrice>
      </S.Content>
    </S.Wrapper>
  )
}
