import styled, { css } from 'styled-components'

import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

import { Color } from 'root-constants'

type TProps = {
  $isDefault: boolean
  $isSelected: boolean
}

const commonTextStyles = css`
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
`

export const StyledChooseTrialPrice = {
  Wrapper: styled.div`
    max-width: 375px;
    margin: 0 auto;
    padding: 0 16px 30px;
  `,
  Title: styled.h2`
    padding: 16px 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  `,
  Subtitle: styled.h3`
    padding-bottom: 16px;
    ${commonTextStyles};
    font-style: normal;
  `,
  PriceDescription: styled.p`
    ${commonTextStyles};
    font-weight: 600;
    padding-bottom: 40px;
    color: ${Color.PRIMARY};
  `,
  OptionsContainer: styled.div`
    display: grid;
    grid-template-columns: repeat(3, 104px);
    grid-column-gap: 16px;
    justify-content: center;
    margin-bottom: 48px;
  `,
  OptionButton: styled.div<TProps>`
    color: ${Color.DARK};
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    width: 100%;
    padding: 20px 0;
    border: 1px solid ${Color.GRAY_20};
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px 0 #e8e8e8;
    transition: all 0.2s ease-out;
    border-radius: 16px;
    text-align: center;

    ${({ $isDefault }) =>
      $isDefault &&
      `
      border: 1px solid ${Color.PRIMARY};
      `}

    ${({ $isSelected }) =>
      $isSelected &&
      `
      background: ${Color.PRIMARY};
      color: ${Color.WHITE};
      `}
  `,
  BadgeContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 8px;
  `,
  Line: styled.div`
    width: 1px;
    height: 16px;
    margin-bottom: 16px;
    background: ${Color.PRIMARY};
  `,
  Text: styled.p`
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${Color.PRIMARY};
  `,
  NavigationButton: styled(NavigationButtonBelly)`
    background: #f5f6f7;
  `,
  Container: styled(ContainerBeforePinnedButton)`
    background: #f5f6f7;
  `,
}
