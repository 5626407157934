import { css } from 'styled-components'

import priceBadgeSelectedUrlBlue from 'assets/images/subscription-item-badge-blue.svg'
import priceBadgeSelectedUrlRed from 'assets/images/subscription-item-badge-red.svg'
import priceBadgeSelectedUrlYellow from 'assets/images/subscription-item-badge-yellow.svg'

import { AvailableAttempt, Color } from 'root-constants'

export const SubscriptionItemBadge = {
  [AvailableAttempt.TWO_ATTEMPTS]: css`
    color: ${Color.WHITE};
    background-image: url(${priceBadgeSelectedUrlBlue});
  `,
  [AvailableAttempt.ONE_ATTEMPT]: css`
    color: ${Color.WHITE};
    background-image: url(${priceBadgeSelectedUrlYellow});
  `,
  [AvailableAttempt.NO_ATTEMPTS]: css`
    color: ${Color.WHITE};
    background-image: url(${priceBadgeSelectedUrlRed});
  `,
}
export const SubscriptionItemBase = {
  [AvailableAttempt.TWO_ATTEMPTS]: {
    color: Color.PRIMARY,
    background: '#eaf7ff',
  },
  [AvailableAttempt.ONE_ATTEMPT]: {
    color: '#ffb701',
    background: '#fff5da',
  },
  [AvailableAttempt.NO_ATTEMPTS]: {
    color: '#f83b00',
    background: '#FEE',
  },
}
