import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSubscriptionBlockWithDescription = {
  Total: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
  `,
  TotalTitle: styled.div<{ $hasSmallText: boolean }>`
    font-size: ${({ $hasSmallText }) => ($hasSmallText ? '14px' : '15px')};
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  `,
  PriceContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  `,
  PriceBlock: styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
  `,
  PriceWithoutDiscount: styled.div<{ $hasSmallText: boolean }>`
    font-size: ${({ $hasSmallText }) => ($hasSmallText ? '12px' : '15px')};
    font-weight: 600;
    line-height: 20px;
    text-decoration-line: line-through;
    color: ${Color.GRAY_60};
  `,
  PriceWithDiscount: styled.div<{ $hasSmallText: boolean }>`
    font-size: ${({ $hasSmallText }) => ($hasSmallText ? '16px' : '18px')};
    font-weight: 700;
    line-height: 24px;
  `,
  YouSave: styled.div`
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: #50ab64;
  `,
  Disclaimer: styled.div`
    width: 100%;
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    color: ${Color.GRAY_80};
    margin-bottom: 16px;
  `,
  Benefit: styled.div`
    width: 100%;
    display: flex;
    padding: 12px;
    align-items: flex-start;
    gap: 12px;
    border-radius: 12px;
    background: #f6f7f9;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 16px;
    }
  `,
  TextBlock: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
  `,
  BenefitTitle: styled.div`
    color: #2d3240;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
  `,
  BenefitDescription: styled.div`
    color: ${Color.GRAY_80};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  `,
}
