import React, { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { PageTitle } from 'components/PageTitle'
import { SvgImage } from 'components/SvgImage'

import {
  selectCurrentUserCurrentWeight,
  selectCurrentUserDreamBody,
  selectCurrentUserGender,
  selectCurrentUserGoalWeight,
  selectCurrentUserLifestyle,
  selectCurrentUserMeasurementSystem,
  selectUserDancingLevel,
  selectUserHeight,
} from 'root-redux/selects/common'

import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { getBmiInImperialSystem } from 'helpers/getBmiInImperialSystem'
import { getBmiInMetricSystem } from 'helpers/getBmiInMetricSystem'

import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'

import { TPageProps } from 'models/common.model'

import markIcon from 'assets/images/sprite/bar-mark.svg'
import lifestyleIcon from 'assets/images/sprite/be-healthier.svg'
import desiredWeightIcon from 'assets/images/sprite/desired-weight.svg'
import bodyDreamIcon from 'assets/images/sprite/feel-confident.svg'
import fitnessLevelIcon from 'assets/images/sprite/fitness-level.svg'

import {
  Gender,
  MeasurementSystem,
  NORMAL_BMI_THRESHOLD,
  OBESE_BMI_THRESHOLD,
  OVERWEIGHT_BMI_THRESHOLD,
} from 'root-constants'

import { StyledFitnessProfile as S } from './FitnessProfile.styles'
import {
  BMI_OPTIONS,
  BMI_OPTIONS_LEGS,
  BMI_VALUES,
  QUESTION,
} from './constants'

export const FitnessProfile: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()
  const goalWeight = useSelector(selectCurrentUserGoalWeight) || 50
  const currentWeight = useSelector(selectCurrentUserCurrentWeight) || 55
  const height = useSelector(selectUserHeight) || 165
  const measurementSystem = useSelector(selectCurrentUserMeasurementSystem)
  const bodyDream = useSelector(selectCurrentUserDreamBody)
  const lifestyle = useSelector(selectCurrentUserLifestyle)
  const fitnessLevel = useSelector(selectUserDancingLevel)
  const gender = useSelector(selectCurrentUserGender)

  const { imageUrls } = useDynamicOBConfig()
  const { isSomaticFlow, isLegsFlow } = useCohortInfo()

  const [leftPosition, setLeftPosition] = useState('')

  const imageUrl = useMemo(() => {
    const [femaleImage, maleImage] = imageUrls || []
    const personalizedImg = gender === Gender.FEMALE ? femaleImage : maleImage

    return personalizedImg || femaleImage
  }, [imageUrls, gender])

  const calculatedBmi = useMemo(() => {
    if (measurementSystem === MeasurementSystem.METRIC) {
      return getBmiInMetricSystem(Number(currentWeight), Number(height))
    }

    return getBmiInImperialSystem(Number(currentWeight), Number(height))
  }, [currentWeight, height, measurementSystem])

  const calculateBmiPosition = useCallback(
    (min, max, value): number => ((value - min) / (max - min)) * 74,
    [],
  )

  const BMIValue = useMemo(() => {
    const bmiOptions = isLegsFlow ? BMI_OPTIONS_LEGS : BMI_OPTIONS

    if (calculatedBmi >= OBESE_BMI_THRESHOLD) {
      const value = calculateBmiPosition(OBESE_BMI_THRESHOLD, 40, calculatedBmi)
      setLeftPosition(`73% + ${value > 72 ? 72 : value}px`)
      return bmiOptions[BMI_VALUES.OBESE]
    }

    if (calculatedBmi >= OVERWEIGHT_BMI_THRESHOLD) {
      const value = calculateBmiPosition(
        OVERWEIGHT_BMI_THRESHOLD,
        OBESE_BMI_THRESHOLD,
        calculatedBmi,
      )
      setLeftPosition(`50% + ${value}px`)
      return bmiOptions[BMI_VALUES.OVERWEIGHT]
    }

    if (calculatedBmi >= NORMAL_BMI_THRESHOLD) {
      const value = calculateBmiPosition(
        NORMAL_BMI_THRESHOLD,
        OVERWEIGHT_BMI_THRESHOLD,
        calculatedBmi,
      )
      setLeftPosition(`25% + ${value}px`)
      return bmiOptions[BMI_VALUES.NORMAL]
    }

    const value = calculateBmiPosition(15, NORMAL_BMI_THRESHOLD, calculatedBmi)
    setLeftPosition(`${value < 0 ? 0 : value}px`)
    return bmiOptions[BMI_VALUES.UNDERWEIGHT]
  }, [calculateBmiPosition, calculatedBmi, isLegsFlow])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(''), [handleContinue])

  return (
    <>
      <ContainerBeforePinnedButton>
        <S.Container isLarge>
          <PageTitle marginBottom={16}>
            {t(
              isSomaticFlow
                ? 'onboarding.fitnessProfile.titleSomatic'
                : 'onboarding.fitnessProfile.title',
            )}
          </PageTitle>
          <S.CardContainer $bgImage={imageUrl || BMIValue.bgImage}>
            <S.CardHeader>
              {t('onboarding.fitnessProfile.bmi')}
              <S.Label $color={BMIValue.color}>{t(BMIValue.label)}</S.Label>
            </S.CardHeader>

            <S.BmiBar>
              <S.MarkPoint $left={leftPosition}>
                <S.Value>
                  {t('onboarding.fitnessProfile.you')}
                  {calculatedBmi}
                </S.Value>
                <S.MarkPointIcon svg={markIcon} />
              </S.MarkPoint>
            </S.BmiBar>
            <S.BmiValuesList>
              {Object.values(BMI_VALUES).map((value) => (
                <S.BmiValue key={value} $isSelected={value === BMIValue.value}>
                  {t(BMI_OPTIONS[value].label)}
                </S.BmiValue>
              ))}
            </S.BmiValuesList>

            <S.BmiCard $color={BMIValue.bgColor}>
              <S.Icon svg={BMIValue.icon} $color={BMIValue.color} />
              <S.BmiLabel>{t(BMIValue.title)}</S.BmiLabel>
              {t(BMIValue.description)}
            </S.BmiCard>

            <S.UserGoal>
              <SvgImage svg={desiredWeightIcon} width={32} height={32} />
              <S.GoalValue>
                <p>{t('onboarding.fitnessProfile.desiredWeight')}</p>
                <p>
                  {t(`commonComponents.weight`, {
                    weight: goalWeight,
                    measurementSystem: t(
                      `commonComponents.${measurementSystem}`,
                    ),
                  })}
                </p>
              </S.GoalValue>
            </S.UserGoal>
            <S.UserGoal>
              <SvgImage svg={bodyDreamIcon} width={32} height={32} />
              <S.GoalValue>
                <p>{t('onboarding.fitnessProfile.bodyDream')}</p>
                <p>{t(`onboarding.dreamBodyBelly.${bodyDream}`)}</p>
              </S.GoalValue>
            </S.UserGoal>
            <S.UserGoal>
              <SvgImage svg={lifestyleIcon} width={32} height={32} />
              <S.GoalValue>
                <p>{t('onboarding.fitnessProfile.lifestyle')}</p>
                <p>{t(`onboarding.activityLevel.${lifestyle}`)}</p>
              </S.GoalValue>
            </S.UserGoal>
            <S.UserGoal>
              <SvgImage svg={fitnessLevelIcon} width={32} height={32} />
              <S.GoalValue>
                <p>{t('onboarding.fitnessProfile.fitnessLevel')}</p>
                <p>{t(`onboarding.dancingLevel.${fitnessLevel}`)}</p>
              </S.GoalValue>
            </S.UserGoal>
          </S.CardContainer>
        </S.Container>
      </ContainerBeforePinnedButton>

      <NavigationButtonBelly
        type="button"
        onClick={handleClick}
      >{t`actions.continue`}</NavigationButtonBelly>
    </>
  )
}
