import React, { FC, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import lottie from 'lottie-web/build/player/lottie_light'

import { TermsOfUseLink } from 'components/TermsOfUseLink'

import giftAnimation from 'assets/animation/secretGift.json'
import riskFree from 'assets/images/risk-free-label.png'

import { PagesSource } from 'root-constants'

import { StyledSecretGiftCards as S } from './SecretGiftCards.styles'

export const SecretGiftCards: FC = () => {
  const { t } = useTranslation()

  const animationRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (animationRef.current) {
      lottie.loadAnimation({
        container: animationRef.current,
        animationData: giftAnimation,
        loop: true,
        name: 'giftAnimation',
      })
    }

    return () => lottie.destroy('giftAnimation')
  }, [])

  return (
    <S.Wrapper>
      <S.GiftBlockTitle>
        {t('purchaseWithoutIntro.whatYouGetGift')}
      </S.GiftBlockTitle>

      <S.SecretGift>
        <h2>{t('purchaseWithoutIntro.secretGift')}</h2>
        {t('purchaseWithoutIntro.giftDescription')}
        <S.GiftImage ref={animationRef} />
      </S.SecretGift>

      <S.RiskFree>
        <h2>{t('purchaseWithoutIntro.riskFree')}</h2>
        {t('purchaseWithoutIntro.contactSupport')}
        <TermsOfUseLink source={PagesSource.PAYWALL} />.
        <S.RiskFreeImg src={riskFree} />
      </S.RiskFree>
    </S.Wrapper>
  )
}
