/* eslint-disable camelcase */
import { ISubscription } from 'models/subscriptions.model'

type TAxonItem = {
  item_id: string
  item_name: string
  price: number
  quantity: number
}

export const getAxonItem = (subscriptions: ISubscription[]): TAxonItem[] =>
  subscriptions.map(({ id, mainPrices, trialPrices }) => ({
    item_id: `${id}`,
    item_category_id: 469,
    item_name: `${mainPrices.periodQuantity} ${mainPrices.periodName} plan`,
    price: trialPrices?.fullPrice || mainPrices.fullPrice,
    quantity: 1,
  }))
