import { Cohort, Locale } from 'root-constants'

export const SUPPORTED_LOCALES_MAP: Record<Cohort, string[]> = {
  [Cohort.DANCEBIT_FLOW_1]: [
    Locale.ENGLISH,
    Locale.SPANISH,
    Locale.FRENCH,
    Locale.DEUTSCH,
  ],
  [Cohort.DANCEBIT_EMAIL_1]: [
    Locale.ENGLISH,
    Locale.SPANISH,
    Locale.FRENCH,
    Locale.DEUTSCH,
  ],
  [Cohort.DANCEBIT_EMAIL_2]: [Locale.ENGLISH],
  [Cohort.DANCEBIT_EMAIL_3]: [Locale.ENGLISH],
  [Cohort.DANCEBIT_20_30_40]: [Locale.ENGLISH],
  [Cohort.DANCEBIT_CLEAR_FLOW]: [Locale.ENGLISH],
  [Cohort.DB_INTRO_2]: [
    Locale.ENGLISH,
    Locale.SPANISH,
    Locale.FRENCH,
    Locale.DEUTSCH,
  ],
  [Cohort.DB_INTRO]: [
    Locale.ENGLISH,
    Locale.SPANISH,
    Locale.FRENCH,
    Locale.DEUTSCH,
  ],
  [Cohort.DB_INTRO_FAST]: [
    Locale.ENGLISH,
    Locale.SPANISH,
    Locale.FRENCH,
    Locale.DEUTSCH,
    Locale.ITALIAN,
  ],
  [Cohort.DB_FLOW_3D]: [Locale.ENGLISH],
  [Cohort.DB_FLOW_7D]: [
    Locale.ENGLISH,
    Locale.SPANISH,
    Locale.FRENCH,
    Locale.DEUTSCH,
  ],
  [Cohort.DB_INTRO_DOP]: [Locale.ENGLISH],
  [Cohort.DANCEBIT_SHORT]: [Locale.ENGLISH],
  [Cohort.DANCEBIT_CHAT_UPGRADE_12]: [
    Locale.ENGLISH,
    Locale.SPANISH,
    Locale.DEUTSCH,
  ],
  [Cohort.DANCEBIT_CHAT_UPGRADE_12_PDF]: [Locale.ENGLISH],
  [Cohort.DANCEBIT_CHAT_UPGRADE_12_MEAL]: [Locale.ENGLISH],
  [Cohort.DB_FLOW_2]: [Locale.ENGLISH],
  [Cohort.DB_CLEAR_INTRO_FAST]: [Locale.ENGLISH],
  [Cohort.DB_INTRO_FAST_18]: [Locale.ENGLISH],
  [Cohort.DB_INTRO_FAST_40]: [Locale.ENGLISH],
  [Cohort.DB_ADULT]: [
    Locale.ENGLISH,
    Locale.SPANISH,
    Locale.FRENCH,
    Locale.ITALIAN,
    Locale.DEUTSCH,
    Locale.PORTUGUESE,
  ],
  [Cohort.DB_ADULT_18]: [
    Locale.ENGLISH,
    Locale.SPANISH,
    Locale.FRENCH,
    Locale.ITALIAN,
    Locale.DEUTSCH,
    Locale.PORTUGUESE,
  ],
  [Cohort.DB_ADULT_MX]: [
    Locale.ENGLISH,
    Locale.SPANISH,
    Locale.FRENCH,
    Locale.ITALIAN,
    Locale.DEUTSCH,
  ],
  [Cohort.DB_ADULT_VIDEO]: [Locale.ENGLISH],
  [Cohort.DB_ADULT_2]: [Locale.ENGLISH],
  [Cohort.DB_ADULT_WEEK]: [Locale.ENGLISH, Locale.SPANISH],
  [Cohort.DB_ADULT_SALE]: [
    Locale.ENGLISH,
    Locale.DEUTSCH,
    Locale.FRENCH,
    Locale.SPANISH,
    Locale.ITALIAN,
  ],
  [Cohort.GLORIA_INTRO_FAST]: [Locale.ENGLISH],
  [Cohort.YARINAS]: [Locale.ENGLISH],
  [Cohort.EMAIL_INTRO_FAST]: [Locale.ENGLISH],
  [Cohort.CANCEL_INTRO_FAST]: [Locale.ENGLISH],
  [Cohort.COHORT_2024]: [Locale.ENGLISH],
  [Cohort.SOMATIC_GOAL]: [Locale.ENGLISH],
  [Cohort.SOMATIC_AGE]: [Locale.ENGLISH],
  [Cohort.DB_TWO_PLANS]: [Locale.ENGLISH],
  [Cohort.DB_BELLY]: [
    Locale.ENGLISH,
    Locale.SPANISH,
    Locale.DEUTSCH,
    Locale.FRENCH,
    Locale.PORTUGUESE,
    Locale.ITALIAN,
    Locale.JAPANESE,
  ],
  [Cohort.DB_SOMATIC]: [Locale.ENGLISH],
  [Cohort.DB_BELLY_SALE]: [
    Locale.ENGLISH,
    Locale.SPANISH,
    Locale.DEUTSCH,
    Locale.FRENCH,
    Locale.PORTUGUESE,
    Locale.ITALIAN,
  ],
  [Cohort.DB_WHEEL]: [
    Locale.ENGLISH,
    Locale.SPANISH,
    Locale.DEUTSCH,
    Locale.FRENCH,
    Locale.PORTUGUESE,
  ],
  [Cohort.DB_WHEEL2]: [
    Locale.ENGLISH,
    Locale.SPANISH,
    Locale.DEUTSCH,
    Locale.FRENCH,
    Locale.PORTUGUESE,
  ],
  [Cohort.DB_LEGS]: [Locale.ENGLISH],
  [Cohort.DB_REF]: [
    Locale.ENGLISH,
    Locale.SPANISH,
    Locale.DEUTSCH,
    Locale.ITALIAN,
    Locale.FRENCH,
    Locale.PORTUGUESE,
  ],
  [Cohort.DB_INFLUENCER]: [Locale.ENGLISH],
  [Cohort.DB_BELLY_REF]: [
    Locale.ENGLISH,
    Locale.SPANISH,
    Locale.DEUTSCH,
    Locale.FRENCH,
    Locale.PORTUGUESE,
    Locale.ITALIAN,
    Locale.JAPANESE,
  ],
  [Cohort.DB_BELLY_WEEK2]: [Locale.ENGLISH],
  [Cohort.DB_BELLY_WEEK]: [
    Locale.ENGLISH,
    Locale.SPANISH,
    Locale.DEUTSCH,
    Locale.FRENCH,
    Locale.PORTUGUESE,
    Locale.ITALIAN,
    Locale.JAPANESE,
  ],
  [Cohort.DB_GIFT]: [Locale.ENGLISH],
}
