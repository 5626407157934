// eslint-disable-line max-lines
import {
  OnResumeSuccessHandler,
  OnTokenizeSuccessHandler,
} from '@primer-io/checkout-web'
import {
  PaymentMethodResult,
  PaymentRequestPaymentMethodEvent,
  Stripe,
  StripeCardNumberElement,
  StripeError,
} from '@stripe/stripe-js'
import { paymentApi } from 'api'
import i18n from 'i18next'

import {
  setErrorAction,
  setIsPrimerRetryProcessing,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import {
  fetchPredictedLtv,
  savePlanAdditionsAction,
  sendUserConfigAction,
  setIsSubscriptionUpgradedAction,
  updateUserConfigAction,
} from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectIsStayFitFlow,
  selectScreenId,
  selectUserName,
} from 'root-redux/selects/common'
import {
  selectIsPersonalDataAllowed,
  selectUUID,
  selectUserCart,
  selectUserOnboardingEmail,
  selectUserPaymentId,
  selectUserPaymentMethod,
  selectUserPaymentSystem,
} from 'root-redux/selects/user'

import { createIntroOfferProductId } from 'helpers/createIntroOfferProductId'

import {
  DEFAULT_CARDHOLDER_NAME,
  PaymentMethod,
  PaymentSystem,
} from 'modules/purchase/constants'
import { getStripePurchaseFailedEventParams } from 'modules/purchase/helpers/getStripePurchaseFailedEventParams'
import { getStripePurchaseStartedEventParams } from 'modules/purchase/helpers/getStripePurchaseStartedEventParams'
import { getStripePurchaseSuccessEventParams } from 'modules/purchase/helpers/getStripePurchaseSuccessEventParams'
import { getSubscriptionPlanId } from 'modules/purchase/helpers/getSubscriptionPlanId'
import { logGeneralAddToCartEvents } from 'modules/purchase/helpers/logGeneralEvents'
import {
  checkIsRetryAllowed,
  getPaymentFailedError,
  getRedirectUrl,
  logFailedPayment,
  logSuccessfulPayment,
} from 'modules/purchase/helpers/rootHelpers'
import { IPrimerSubscriptionBackupConfig } from 'modules/purchase/types'

import { IDynamicDiscount } from 'models/payment.model'
import { IAction, IAppState, TAppDispatchThunk } from 'models/store.model'
import { ISubscription, IUpgradeSubscription } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'

import {
  CENTS_IN_DOLLAR,
  Cohort,
  ONBOARDING_GOAL_EVENT,
  PRIMER_PAYMENT_ERRORS,
  PRIMER_SOFT_DECLINE,
  PlanAddition,
} from 'root-constants'

import {
  selectCreatedSubscriptionId,
  selectCurrency,
  selectIsFirstPaymentRetryPassed,
  selectPaymentClientSecret,
  selectPaymentMethod,
  selectPrimerClientSessionToken,
  selectSubscription,
  selectSubscriptionFullPrice,
  selectSubscriptionLookupKey,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionPlanId,
  selectSubscriptionTrialLookupKey,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
  selectUpgradeDiffSubscriptionAmountToPay,
} from '../selects/common'

const MODULE_NAME = 'PURCHASE'

export const SET_SUBSCRIPTION = `${MODULE_NAME}/SET_SUBSCRIPTION`
export const PURCHASE = `${MODULE_NAME}/PURCHASE`
export const CHECK_3D_SECURE = `${MODULE_NAME}/CHECK_3D_SECURE`
export const SET_3D_SECURE_IFRAME_URL = `${MODULE_NAME}/SET_3D_SECURE_IFRAME_URL`
export const RESET_3D_SECURE_IFRAME_URL = `${MODULE_NAME}/RESET_3D_SECURE_IFRAME_URL`
export const SET_PAYMENT_CLIENT_SECRET = `${MODULE_NAME}/SET_PAYMENT_CLIENT_SECRET`
export const SET_TRIAL_PERIOD_DAYS = `${MODULE_NAME}/SET_TRIAL_PERIOD_DAYS`
export const SET_CREATED_SUBSCRIPTION_ID = `${MODULE_NAME}/SET_CREATED_SUBSCRIPTION_ID`
export const CHECK_PAYMENT_REQUEST_BUTTON = `${MODULE_NAME}/CHECK_PAYMENT_REQUEST_BUTTON`
export const SET_PLAN_ADDITIONS = `${MODULE_NAME}/SET_PLAN_ADDITIONS`
export const SET_IS_SUBSCRIPTION_UPGRADED = `${MODULE_NAME}/SET_IS_UPGRADED`
export const UPGRADE_SUBSCRIPTION = `${MODULE_NAME}/UPGRADE_SUBSCRIPTION`
export const SET_PAYMENT_METHOD = `${MODULE_NAME}/SET_PAYMENT_METHOD`
export const SET_PRIMER_CLIENT_SESSION_TOKEN = `${MODULE_NAME}/SET_PRIMER_CLIENT_SESSION_TOKEN`
export const GET_PRIMER_CLIENT_SESSION_TOKEN = `${MODULE_NAME}/GET_PRIMER_CLIENT_SESSION_TOKEN`
export const UPDATE_PRIMER_CLIENT_SESSION = `${MODULE_NAME}/UPDATE_PRIMER_CLIENT_SESSION`
export const CANCEL_PRIMER_CLIENT_SESSION_DISCOUNT = `${MODULE_NAME}/CANCEL_PRIMER_CLIENT_SESSION_DISCOUNT`
export const SET_BACKUP_PRIMER_SUBSCRIPTION_CONFIG = `${MODULE_NAME}/SET_BACKUP_PRIMER_SUBSCRIPTION_CONFIG`
export const SET_PAYMENT_FORM_IS_LOADING = `${MODULE_NAME}/SET_PAYMENT_FORM_IS_LOADING`
export const SET_IS_FIRST_PAYMENT_RETRY_PASSED = `${MODULE_NAME}/SET_IS_FIRST_PAYMENT_RETRY_PASSED`
export const SET_DYNAMIC_DISCOUNT = `${MODULE_NAME}/SET_DYNAMIC_DISCOUNT`

const set3DSecureIframeUrlAction = (payload: string): IAction<string> => ({
  type: SET_3D_SECURE_IFRAME_URL,
  payload,
})

const reset3DSecureIframeUrlAction = (): IAction<any> => ({
  type: RESET_3D_SECURE_IFRAME_URL,
})

const setPaymentClientSecretAction = (payload: string): IAction<string> => ({
  type: SET_PAYMENT_CLIENT_SECRET,
  payload,
})

const setTrialPeriodDaysAction = (payload: number): IAction<number> => ({
  type: SET_TRIAL_PERIOD_DAYS,
  payload,
})

const setCreatedSubscriptionIdAction = (payload: string): IAction<string> => ({
  type: SET_CREATED_SUBSCRIPTION_ID,
  payload,
})

export const setPrimerClientSessionTokenAction = (
  payload: string,
): IAction<string> => ({
  type: SET_PRIMER_CLIENT_SESSION_TOKEN,
  payload,
})

export const setBackupPrimerSubscriptionConfigAction = (
  payload: IPrimerSubscriptionBackupConfig | null,
): IAction<IPrimerSubscriptionBackupConfig | null> => ({
  type: SET_BACKUP_PRIMER_SUBSCRIPTION_CONFIG,
  payload,
})

const getErrorActionPayload = ({ type, message }: StripeError): string =>
  message || type

export const setSelectedSubscriptionAction = (
  payload: ISubscription | IUpgradeSubscription | null,
): IAction<ISubscription | IUpgradeSubscription | null> => ({
  type: SET_SUBSCRIPTION,
  payload,
})

export const setUpgradeSubscriptionAction = (
  payload: IUpgradeSubscription,
): IAction<IUpgradeSubscription> => ({
  type: UPGRADE_SUBSCRIPTION,
  payload,
})

export const setPlanAdditionsAction = (
  payload: PlanAddition[],
): IAction<PlanAddition[]> => ({
  type: SET_PLAN_ADDITIONS,
  payload,
})

export const setIsFirstPaymentRetryPassedAction = (
  payload: boolean,
): IAction<boolean> => ({
  type: SET_IS_FIRST_PAYMENT_RETRY_PASSED,
  payload,
})

export const setDynamicDiscountAction = (
  payload: IDynamicDiscount,
): IAction<IDynamicDiscount> => ({
  type: SET_DYNAMIC_DISCOUNT,
  payload,
})

export function updatePrimerClientSessionAction(
  retryCallback: () => void,
): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const clientToken = selectPrimerClientSessionToken(state)
    const paymentId = selectUserPaymentId(state)

    dispatch(startFetching(UPDATE_PRIMER_CLIENT_SESSION))

    try {
      const response = await paymentApi.updatePrimerClientSession({
        uuid,
        paymentId,
        clientToken,
      })

      dispatch(stopFetching(UPDATE_PRIMER_CLIENT_SESSION))

      if (response.success && response.data && response.data.should_retry) {
        dispatch(stopFetching(PURCHASE))
        dispatch(setPrimerClientSessionTokenAction(response.data.client_token))
        dispatch(setIsPrimerRetryProcessing(true))

        retryCallback()
        return
      }

      const error = i18n.t(
        PRIMER_PAYMENT_ERRORS[response.data.payment_error] ||
          PRIMER_PAYMENT_ERRORS.COMMON_ERROR,
      )

      dispatch(setIsPrimerRetryProcessing(false))
      dispatch(setErrorAction(error))
      dispatch(stopFetching(UPDATE_PRIMER_CLIENT_SESSION))
      dispatch(stopFetching(PURCHASE))
    } catch (error: any) {
      dispatch(setIsPrimerRetryProcessing(false))
      dispatch(setErrorAction(error.toString()))
      dispatch(stopFetching(PURCHASE))
      dispatch(stopFetching(UPDATE_PRIMER_CLIENT_SESSION))
    }
  }
}

export const purchaseAction = ({
  stripe,
  card,
  name,
  createPaymentResFromDigitalWallet,
}: {
  stripe: Stripe
  card?: StripeCardNumberElement
  name?: string
  createPaymentResFromDigitalWallet?: PaymentRequestPaymentMethodEvent
}): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
): Promise<void> => {
  const state = getState()
  const priceId = selectSubscriptionLookupKey(state)
  const planId = selectSubscriptionPlanId(state)
  const trialPrice = selectSubscriptionTrialPeriodPrice(state)
  const uuid = selectUUID(state)
  const currentPrice = selectSubscriptionFullPrice(state)
  const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
  const periodName = selectSubscriptionPeriodName(state)
  const periodQuantity = selectSubscriptionPeriodQuantity(state)
  const currency = selectCurrency(state)
  const isStayFitGoal = selectIsStayFitFlow(state)
  const cohort = selectCurrentVariantCohort(state) as Cohort
  const email = selectUserOnboardingEmail(state)
  const selectedSubscription = selectSubscription(state)
  const isPersonalDataAllowed = selectIsPersonalDataAllowed(state)
  const paymentMethod = selectPaymentMethod(state)
  const userCart = selectUserCart(state)
  const userName = selectUserName(state)

  const upsellPlanIds = getSubscriptionPlanId(userCart)

  if (!priceId || !currentPrice) {
    console.error('Error: no subscriptions plan selected')
    return
  }

  dispatch(startFetching(PURCHASE))

  const commonPurchaseStartedParams = getStripePurchaseStartedEventParams(state)
  const commonPurchaseSuccessParams = getStripePurchaseSuccessEventParams(state)
  const commonPurchaseFailedParams = getStripePurchaseFailedEventParams(state)

  eventLogger.logPurchaseStarted({
    ...commonPurchaseStartedParams,
    paymentMethod,
    paymentSystem: PaymentSystem.STRIPE,
    goal: isStayFitGoal
      ? ONBOARDING_GOAL_EVENT.STAY_FIT
      : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
  })

  logGeneralAddToCartEvents({
    currency,
    email,
    periodQuantity,
    periodName,
    fullPrice: currentPrice,
    selectedSubscription,
    uuid,
    isPersonalDataAllowed,
    userName,
  })

  dispatch(
    sendUserConfigAction({
      payment_currency: currency,
      payment_method: paymentMethod,
      payment_system: PaymentSystem.STRIPE,
      is_download_visited: false,
      subscription_price: `${currentPrice}`,
      subscription_duration: `${periodQuantity}${periodName}`,
      price_id: priceId,
      screen_name: commonPurchaseSuccessParams.screenName,
      product_name: commonPurchaseSuccessParams.productName,
      period_quantity: periodQuantity,
      trial_price: `${trialPrice}`,
      trial_period: `${trialPeriodDays}`,
    }),
  )

  try {
    const createPaymentResponse =
      card && !createPaymentResFromDigitalWallet
        ? await stripe.createPaymentMethod({
            type: 'card',
            card,
            billing_details: { name: name || DEFAULT_CARDHOLDER_NAME },
          })
        : (createPaymentResFromDigitalWallet as PaymentMethodResult)

    if (!createPaymentResponse?.paymentMethod && createPaymentResponse?.error) {
      const {
        error: { type, code, message },
      } = createPaymentResponse

      eventLogger.logPurchaseFailed({
        ...commonPurchaseFailedParams,
        error: {
          type,
          code,
          description: message,
        },
        paymentMethod,
        paymentSystem: PaymentSystem.STRIPE,
        goal: isStayFitGoal
          ? ONBOARDING_GOAL_EVENT.STAY_FIT
          : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
      })
      dispatch(setErrorAction(message || type))
      dispatch(stopFetching(PURCHASE))
      createPaymentResFromDigitalWallet?.complete('fail')
      return
    }

    const createSubscriptionResponse = await paymentApi.createSubscription({
      uuid,
      planId,
      trialPeriodDays,
      cohort,
      upsellPlanIds,
    })

    if (
      !createSubscriptionResponse.success ||
      !createSubscriptionResponse.data
    ) {
      if (createSubscriptionResponse.status === 404) {
        dispatch(setErrorAction(i18n.t('purchase1.linkIsNotValid')))
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      if (createSubscriptionResponse.status === 409) {
        dispatch(setErrorAction(i18n.t('purchase1.haveSubscription')))
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
      dispatch(stopFetching(PURCHASE))
      createPaymentResFromDigitalWallet?.complete('fail')
      return
    }

    dispatch(
      setPaymentClientSecretAction(
        createSubscriptionResponse.data.purchase.client_secret,
      ),
    )
    dispatch(
      setTrialPeriodDaysAction(
        createSubscriptionResponse.data.purchase.trial_period_days,
      ),
    )
    dispatch(
      setCreatedSubscriptionIdAction(
        createSubscriptionResponse.data.purchase.subscription_id,
      ),
    )

    const cardPaymentResponseFirst = await stripe.confirmCardPayment(
      createSubscriptionResponse.data.purchase.client_secret,
      {
        payment_method: createPaymentResponse.paymentMethod.id,
        save_payment_method: true,
        return_url: getRedirectUrl(),
      },
    )

    if (
      !cardPaymentResponseFirst?.paymentIntent &&
      cardPaymentResponseFirst?.error
    ) {
      logFailedPayment({
        ...commonPurchaseFailedParams,
        paymentResponse: cardPaymentResponseFirst.error,
        paymentMethod,
        paymentSystem: PaymentSystem.STRIPE,
        goal: isStayFitGoal
          ? ONBOARDING_GOAL_EVENT.STAY_FIT
          : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
      })

      if (!checkIsRetryAllowed(cardPaymentResponseFirst)) {
        dispatch(
          setErrorAction(getErrorActionPayload(cardPaymentResponseFirst.error)),
        )
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      const retryResponseFirst = await paymentApi.retryPayment({
        uuid,
        stripeError: cardPaymentResponseFirst.error as StripeError,
      })

      if (!retryResponseFirst.data.should_retry) {
        dispatch(
          setErrorAction(getErrorActionPayload(cardPaymentResponseFirst.error)),
        )
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      dispatch(
        setPaymentClientSecretAction(
          retryResponseFirst.data.subscription.client_secret,
        ),
      )
      dispatch(
        setTrialPeriodDaysAction(
          retryResponseFirst.data.subscription.trial_period_days,
        ),
      )
      dispatch(
        setCreatedSubscriptionIdAction(
          retryResponseFirst.data.subscription.subscription_id,
        ),
      )

      const cardPaymentResponseSecond = await stripe.confirmCardPayment(
        retryResponseFirst.data.subscription.client_secret,
        {
          payment_method: createPaymentResponse.paymentMethod.id,
          save_payment_method: true,
          return_url: getRedirectUrl(),
        },
      )

      if (
        cardPaymentResponseSecond?.paymentIntent &&
        !cardPaymentResponseSecond?.error
      ) {
        logSuccessfulPayment({
          ...commonPurchaseSuccessParams,
          price:
            cardPaymentResponseSecond.paymentIntent.amount / CENTS_IN_DOLLAR,
          trialPrice:
            cardPaymentResponseSecond.paymentIntent.amount / CENTS_IN_DOLLAR,
          productId: createSubscriptionResponse.data.purchase.product_id,
          upsellProductIds: createSubscriptionResponse.data.purchase.upsells?.map(
            (upsell) => upsell.product_id,
          ),
          trialPeriodDays:
            retryResponseFirst.data.subscription.trial_period_days,
          subscriptionId: retryResponseFirst.data.subscription.subscription_id,
          discountApplied:
            retryResponseFirst.data.subscription.discount_applied,
          paymentMethod,
          paymentSystem: PaymentSystem.STRIPE,
          goal: isStayFitGoal
            ? ONBOARDING_GOAL_EVENT.STAY_FIT
            : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
        })
        dispatch(
          sendUserConfigAction(
            {
              discount_applied: !!retryResponseFirst.data.subscription
                .discount_applied,
            },
            null,
          ),
        )

        dispatch(savePlanAdditionsAction())
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('success')
        return
      }

      logFailedPayment({
        ...commonPurchaseFailedParams,
        paymentResponse: cardPaymentResponseSecond.error,
        goal: isStayFitGoal
          ? ONBOARDING_GOAL_EVENT.STAY_FIT
          : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
        paymentMethod,
        paymentSystem: PaymentSystem.STRIPE,
      })

      if (!checkIsRetryAllowed(cardPaymentResponseSecond)) {
        dispatch(
          setErrorAction(
            getErrorActionPayload(cardPaymentResponseSecond.error),
          ),
        )
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      const retryResponseSecond = await paymentApi.retryPayment({
        uuid,
        stripeError: cardPaymentResponseSecond.error as StripeError,
      })

      if (!retryResponseSecond.data.should_retry) {
        dispatch(
          setErrorAction(
            getErrorActionPayload(cardPaymentResponseSecond.error),
          ),
        )
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      dispatch(
        setPaymentClientSecretAction(
          retryResponseSecond.data.subscription.client_secret,
        ),
      )
      dispatch(
        setTrialPeriodDaysAction(
          retryResponseSecond.data.subscription.trial_period_days,
        ),
      )
      dispatch(
        setCreatedSubscriptionIdAction(
          retryResponseSecond.data.subscription.subscription_id,
        ),
      )

      const cardPaymentResponseThird = await stripe.confirmCardPayment(
        retryResponseSecond.data.subscription.client_secret,
        {
          payment_method: createPaymentResponse.paymentMethod.id,
          save_payment_method: true,
          return_url: getRedirectUrl(),
        },
      )

      if (
        cardPaymentResponseThird?.paymentIntent &&
        !cardPaymentResponseThird?.error
      ) {
        logSuccessfulPayment({
          ...commonPurchaseSuccessParams,
          price:
            cardPaymentResponseThird.paymentIntent.amount / CENTS_IN_DOLLAR,
          trialPrice:
            cardPaymentResponseThird.paymentIntent.amount / CENTS_IN_DOLLAR,
          productId: createSubscriptionResponse.data.purchase.product_id,
          upsellProductIds: createSubscriptionResponse.data.purchase.upsells?.map(
            (upsell) => upsell.product_id,
          ),
          trialPeriodDays:
            retryResponseSecond.data.subscription.trial_period_days,
          subscriptionId: retryResponseSecond.data.subscription.subscription_id,
          discountApplied:
            retryResponseSecond.data.subscription.discount_applied,
          paymentMethod,
          paymentSystem: PaymentSystem.STRIPE,
          goal: isStayFitGoal
            ? ONBOARDING_GOAL_EVENT.STAY_FIT
            : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
        })
        dispatch(
          sendUserConfigAction(
            {
              discount_applied: !!retryResponseSecond.data.subscription
                .discount_applied,
            },
            null,
          ),
        )
        dispatch(savePlanAdditionsAction())
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('success')
        return
      }

      logFailedPayment({
        ...commonPurchaseFailedParams,
        paymentResponse: cardPaymentResponseThird.error,
        paymentMethod,
        paymentSystem: PaymentSystem.STRIPE,
        goal: isStayFitGoal
          ? ONBOARDING_GOAL_EVENT.STAY_FIT
          : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
      })

      if (!checkIsRetryAllowed(cardPaymentResponseThird)) {
        dispatch(
          setErrorAction(getErrorActionPayload(cardPaymentResponseThird.error)),
        )
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      const retryResponseThird = await paymentApi.retryPayment({
        uuid,
        stripeError: cardPaymentResponseThird.error as StripeError,
      })

      if (!retryResponseThird.data.should_retry) {
        dispatch(
          setErrorAction(getErrorActionPayload(cardPaymentResponseThird.error)),
        )
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      dispatch(
        setPaymentClientSecretAction(
          retryResponseThird.data.subscription.client_secret,
        ),
      )
      dispatch(
        setTrialPeriodDaysAction(
          retryResponseThird.data.subscription.trial_period_days,
        ),
      )
      dispatch(
        setCreatedSubscriptionIdAction(
          retryResponseThird.data.subscription.subscription_id,
        ),
      )

      const cardPaymentResponseFourth = await stripe.confirmCardPayment(
        retryResponseThird.data.subscription.client_secret,
        {
          payment_method: createPaymentResponse.paymentMethod.id,
          save_payment_method: true,
          return_url: getRedirectUrl(),
        },
      )

      if (
        cardPaymentResponseFourth?.paymentIntent &&
        !cardPaymentResponseFourth?.error
      ) {
        logSuccessfulPayment({
          ...commonPurchaseSuccessParams,
          price:
            cardPaymentResponseFourth.paymentIntent.amount / CENTS_IN_DOLLAR,
          trialPrice:
            cardPaymentResponseFourth.paymentIntent.amount / CENTS_IN_DOLLAR,
          productId: createSubscriptionResponse.data.purchase.product_id,
          upsellProductIds: createSubscriptionResponse.data.purchase.upsells?.map(
            (upsell) => upsell.product_id,
          ),
          trialPeriodDays:
            retryResponseThird.data.subscription.trial_period_days,
          subscriptionId: retryResponseThird.data.subscription.subscription_id,
          discountApplied:
            retryResponseThird.data.subscription.discount_applied,
          paymentMethod,
          paymentSystem: PaymentSystem.STRIPE,
          goal: isStayFitGoal
            ? ONBOARDING_GOAL_EVENT.STAY_FIT
            : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
        })
        dispatch(
          sendUserConfigAction(
            {
              discount_applied: !!retryResponseThird.data.subscription
                .discount_applied,
            },
            null,
          ),
        )
        dispatch(savePlanAdditionsAction())
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('success')
        return
      }

      logFailedPayment({
        ...commonPurchaseFailedParams,
        paymentResponse: cardPaymentResponseFourth.error,
        paymentMethod,
        paymentSystem: PaymentSystem.STRIPE,

        goal: isStayFitGoal
          ? ONBOARDING_GOAL_EVENT.STAY_FIT
          : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
      })

      dispatch(
        setErrorAction(getErrorActionPayload(cardPaymentResponseFourth.error)),
      )

      // Needed for reset invoice on BE
      await paymentApi.retryPayment({
        uuid,
        stripeError: cardPaymentResponseFourth.error as StripeError,
      })

      dispatch(stopFetching(PURCHASE))
      createPaymentResFromDigitalWallet?.complete('fail')
      return
    }

    const { paymentIntent } = cardPaymentResponseFirst

    if (paymentIntent.status === 'requires_payment_method') {
      eventLogger.logPurchaseFailed({
        ...commonPurchaseFailedParams,
        error: {
          type: 'requires_payment_method',
        },
        goal: isStayFitGoal
          ? ONBOARDING_GOAL_EVENT.STAY_FIT
          : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
        paymentMethod,
        paymentSystem: PaymentSystem.STRIPE,
      })
      dispatch(setErrorAction(i18n.t('purchase1.paymentFailed')))
      dispatch(stopFetching(PURCHASE))
      createPaymentResFromDigitalWallet?.complete('fail')
      return
    }

    const threeDSesureURL = paymentIntent.next_action?.redirect_to_url?.url

    if (paymentIntent.status === 'requires_action' && threeDSesureURL) {
      dispatch(set3DSecureIframeUrlAction(threeDSesureURL))
      dispatch(stopFetching(PURCHASE))
      return
    }

    const predictedLtv = await fetchPredictedLtv(uuid)

    logSuccessfulPayment({
      ...commonPurchaseSuccessParams,
      predictedLtv,
      price: currentPrice,
      productId: createSubscriptionResponse.data.purchase.product_id,
      upsellProductIds: createSubscriptionResponse.data.purchase.upsells?.map(
        (upsell) => upsell.product_id,
      ),
      trialPeriodDays:
        createSubscriptionResponse.data.purchase.trial_period_days,
      subscriptionId: createSubscriptionResponse.data.purchase.subscription_id,
      paymentMethod,
      paymentSystem: PaymentSystem.STRIPE,
      goal: isStayFitGoal
        ? ONBOARDING_GOAL_EVENT.STAY_FIT
        : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
    })

    dispatch(savePlanAdditionsAction())
    dispatch(stopFetching(PURCHASE))
    createPaymentResFromDigitalWallet?.complete('success')
  } catch (error: any) {
    dispatch(setErrorAction(error.toString()))
    dispatch(stopFetching(PURCHASE))
    createPaymentResFromDigitalWallet?.complete('fail')
  }
}

export const paymentElementPurchaseAction = ({
  stripe,
  elements,
}: {
  stripe: Stripe
  elements?: any
}): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
): Promise<void> => {
  const state = getState()
  const priceId = selectSubscriptionLookupKey(state)
  const planId = selectSubscriptionPlanId(state)
  const trialPrice = selectSubscriptionTrialPeriodPrice(state)
  const uuid = selectUUID(state)
  const currentPrice = selectSubscriptionFullPrice(state)
  const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
  const trialPriceId = selectSubscriptionTrialLookupKey(state)
  const periodName = selectSubscriptionPeriodName(state)
  const periodQuantity = selectSubscriptionPeriodQuantity(state)
  const currency = selectCurrency(state)
  const isStayFitGoal = selectIsStayFitFlow(state)
  const cohort = selectCurrentVariantCohort(state) as Cohort
  const email = selectUserOnboardingEmail(state)
  const selectedSubscription = selectSubscription(state)
  const isPersonalDataAllowed = selectIsPersonalDataAllowed(state)
  const paymentMethod = selectPaymentMethod(state)
  const userCart = selectUserCart(state)
  const screenId = selectScreenId(state)
  const userName = selectUserName(state)

  const upsellPlanIds = getSubscriptionPlanId(userCart)

  if (!priceId || !currentPrice) {
    console.error('Error: no subscriptions plan selected')
    return
  }

  dispatch(startFetching(PURCHASE))

  const commonPurchaseStartedParams = getStripePurchaseStartedEventParams(state)
  const commonPurchaseSuccessParams = getStripePurchaseSuccessEventParams(state)
  const commonPurchaseFailedParams = getStripePurchaseFailedEventParams(state)

  eventLogger.logPurchaseStarted({
    ...commonPurchaseStartedParams,
    paymentMethod,
    paymentSystem: PaymentSystem.STRIPE,
    goal: isStayFitGoal
      ? ONBOARDING_GOAL_EVENT.STAY_FIT
      : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
  })

  logGeneralAddToCartEvents({
    currency,
    email,
    periodQuantity,
    periodName,
    fullPrice: currentPrice,
    selectedSubscription,
    uuid,
    isPersonalDataAllowed,
    userName,
  })

  dispatch(
    sendUserConfigAction({
      payment_currency: currency,
      payment_method: paymentMethod,
      payment_system: PaymentSystem.STRIPE,
      is_download_visited: false,
      subscription_price: `${currentPrice}`,
      subscription_duration: `${periodQuantity}${periodName}`,
      price_id: priceId,
      trial_price_id: trialPriceId,
      screen_id: screenId,
      screen_name: commonPurchaseSuccessParams.screenName,
      product_name: commonPurchaseSuccessParams.productName,
      period_quantity: periodQuantity,
      trial_price: `${trialPrice}`,
      trial_period: `${trialPeriodDays}`,
    }),
  )

  try {
    const createPaymentResponse = await stripe.createPaymentMethod({
      elements,
      params: {
        type: paymentMethod,
      },
    })

    if (!createPaymentResponse?.paymentMethod && createPaymentResponse?.error) {
      const {
        error: { type, code, message },
      } = createPaymentResponse

      eventLogger.logPurchaseFailed({
        ...commonPurchaseFailedParams,
        error: {
          type,
          code,
          description: message,
        },
        paymentMethod,
        paymentSystem: PaymentSystem.STRIPE,
        goal: isStayFitGoal
          ? ONBOARDING_GOAL_EVENT.STAY_FIT
          : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
      })
      dispatch(setErrorAction(message || type))
      dispatch(stopFetching(PURCHASE))
      return
    }

    const createSubscriptionResponse = await paymentApi.createSubscription({
      uuid,
      planId,
      trialPeriodDays,
      cohort,
      upsellPlanIds,
    })

    if (
      !createSubscriptionResponse.success ||
      !createSubscriptionResponse.data
    ) {
      if (createSubscriptionResponse.status === 404) {
        dispatch(setErrorAction(i18n.t('purchase1.linkIsNotValid')))
        dispatch(stopFetching(PURCHASE))
        return
      }

      if (createSubscriptionResponse.status === 409) {
        dispatch(setErrorAction(i18n.t('purchase1.haveSubscription')))
        dispatch(stopFetching(PURCHASE))
        return
      }

      dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
      dispatch(stopFetching(PURCHASE))
      return
    }

    dispatch(
      setPaymentClientSecretAction(
        createSubscriptionResponse.data.purchase.client_secret,
      ),
    )
    dispatch(
      setTrialPeriodDaysAction(
        createSubscriptionResponse.data.purchase.trial_period_days,
      ),
    )
    dispatch(
      setCreatedSubscriptionIdAction(
        createSubscriptionResponse.data.purchase.subscription_id,
      ),
    )

    const cardPaymentResponseFirst = await stripe.confirmPayment({
      confirmParams: {
        payment_method: createPaymentResponse.paymentMethod.id,
        save_payment_method: true,
        return_url: getRedirectUrl(),
      },
      clientSecret: createSubscriptionResponse.data.purchase.client_secret,
    })

    if (cardPaymentResponseFirst?.error) {
      logFailedPayment({
        ...commonPurchaseFailedParams,
        paymentResponse: cardPaymentResponseFirst.error,
        paymentMethod,
        paymentSystem: PaymentSystem.STRIPE,
        goal: isStayFitGoal
          ? ONBOARDING_GOAL_EVENT.STAY_FIT
          : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
      })

      if (!checkIsRetryAllowed(cardPaymentResponseFirst)) {
        dispatch(
          setErrorAction(getErrorActionPayload(cardPaymentResponseFirst.error)),
        )
        dispatch(stopFetching(PURCHASE))
        return
      }

      const retryResponseFirst = await paymentApi.retryPayment({
        uuid,
        stripeError: cardPaymentResponseFirst.error as StripeError,
      })

      if (!retryResponseFirst.data.should_retry) {
        dispatch(
          setErrorAction(getErrorActionPayload(cardPaymentResponseFirst.error)),
        )
        dispatch(stopFetching(PURCHASE))
        return
      }

      dispatch(
        setPaymentClientSecretAction(
          retryResponseFirst.data.subscription.client_secret,
        ),
      )
      dispatch(
        setTrialPeriodDaysAction(
          retryResponseFirst.data.subscription.trial_period_days,
        ),
      )
      dispatch(
        setCreatedSubscriptionIdAction(
          retryResponseFirst.data.subscription.subscription_id,
        ),
      )

      const cardPaymentResponseSecond = await stripe.confirmPayment({
        confirmParams: {
          payment_method: createPaymentResponse.paymentMethod.id,
          save_payment_method: true,
          return_url: getRedirectUrl(),
        },
        clientSecret: retryResponseFirst.data.subscription.client_secret,
      })

      if (!cardPaymentResponseSecond?.error) {
        logSuccessfulPayment({
          ...commonPurchaseSuccessParams,
          price: currentPrice,
          trialPrice,
          productId: createSubscriptionResponse.data.purchase.product_id,
          upsellProductIds: createSubscriptionResponse.data.purchase.upsells?.map(
            (upsell) => upsell.product_id,
          ),
          trialPeriodDays:
            retryResponseFirst.data.subscription.trial_period_days,
          subscriptionId: retryResponseFirst.data.subscription.subscription_id,
          discountApplied:
            retryResponseFirst.data.subscription.discount_applied,
          paymentMethod,
          paymentSystem: PaymentSystem.STRIPE,
          goal: isStayFitGoal
            ? ONBOARDING_GOAL_EVENT.STAY_FIT
            : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
        })
        dispatch(
          sendUserConfigAction(
            {
              discount_applied: !!retryResponseFirst.data.subscription
                .discount_applied,
            },
            null,
          ),
        )
        dispatch(savePlanAdditionsAction())
        dispatch(stopFetching(PURCHASE))
        return
      }

      logFailedPayment({
        ...commonPurchaseFailedParams,
        paymentResponse: cardPaymentResponseSecond.error,
        goal: isStayFitGoal
          ? ONBOARDING_GOAL_EVENT.STAY_FIT
          : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
        paymentMethod,
        paymentSystem: PaymentSystem.STRIPE,
      })

      if (!checkIsRetryAllowed(cardPaymentResponseSecond)) {
        dispatch(
          setErrorAction(
            getErrorActionPayload(cardPaymentResponseSecond.error),
          ),
        )
        dispatch(stopFetching(PURCHASE))
        return
      }

      const retryResponseSecond = await paymentApi.retryPayment({
        uuid,
        stripeError: cardPaymentResponseSecond.error as StripeError,
      })

      if (!retryResponseSecond.data.should_retry) {
        dispatch(
          setErrorAction(
            getErrorActionPayload(cardPaymentResponseSecond.error),
          ),
        )
        dispatch(stopFetching(PURCHASE))
        return
      }

      dispatch(
        setPaymentClientSecretAction(
          retryResponseSecond.data.subscription.client_secret,
        ),
      )
      dispatch(
        setTrialPeriodDaysAction(
          retryResponseSecond.data.subscription.trial_period_days,
        ),
      )
      dispatch(
        setCreatedSubscriptionIdAction(
          retryResponseSecond.data.subscription.subscription_id,
        ),
      )

      const cardPaymentResponseThird = await stripe.confirmPayment({
        confirmParams: {
          payment_method: createPaymentResponse.paymentMethod.id,
          save_payment_method: true,
          return_url: getRedirectUrl(),
        },
        clientSecret: retryResponseSecond.data.subscription.client_secret,
      })

      if (!cardPaymentResponseThird?.error) {
        logSuccessfulPayment({
          ...commonPurchaseSuccessParams,
          price: currentPrice,
          trialPrice,
          productId: createSubscriptionResponse.data.purchase.product_id,
          upsellProductIds: createSubscriptionResponse.data.purchase.upsells?.map(
            (upsell) => upsell.product_id,
          ),
          trialPeriodDays:
            retryResponseSecond.data.subscription.trial_period_days,
          subscriptionId: retryResponseSecond.data.subscription.subscription_id,
          discountApplied:
            retryResponseSecond.data.subscription.discount_applied,
          paymentMethod,
          paymentSystem: PaymentSystem.STRIPE,
          goal: isStayFitGoal
            ? ONBOARDING_GOAL_EVENT.STAY_FIT
            : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
        })
        dispatch(
          sendUserConfigAction(
            {
              discount_applied: !!retryResponseSecond.data.subscription
                .discount_applied,
            },
            null,
          ),
        )
        dispatch(savePlanAdditionsAction())
        dispatch(stopFetching(PURCHASE))
        return
      }

      logFailedPayment({
        ...commonPurchaseFailedParams,
        paymentResponse: cardPaymentResponseThird.error,
        paymentMethod,
        paymentSystem: PaymentSystem.STRIPE,
        goal: isStayFitGoal
          ? ONBOARDING_GOAL_EVENT.STAY_FIT
          : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
      })

      if (!checkIsRetryAllowed(cardPaymentResponseThird)) {
        dispatch(
          setErrorAction(getErrorActionPayload(cardPaymentResponseThird.error)),
        )
        dispatch(stopFetching(PURCHASE))
        return
      }

      const retryResponseThird = await paymentApi.retryPayment({
        uuid,
        stripeError: cardPaymentResponseThird.error as StripeError,
      })

      if (!retryResponseThird.data.should_retry) {
        dispatch(
          setErrorAction(getErrorActionPayload(cardPaymentResponseThird.error)),
        )
        dispatch(stopFetching(PURCHASE))
        return
      }

      dispatch(
        setPaymentClientSecretAction(
          retryResponseThird.data.subscription.client_secret,
        ),
      )
      dispatch(
        setTrialPeriodDaysAction(
          retryResponseThird.data.subscription.trial_period_days,
        ),
      )
      dispatch(
        setCreatedSubscriptionIdAction(
          retryResponseThird.data.subscription.subscription_id,
        ),
      )

      const cardPaymentResponseFourth = await stripe.confirmPayment({
        confirmParams: {
          payment_method: paymentMethod,
          save_payment_method: true,
          return_url: getRedirectUrl(),
        },
        clientSecret: retryResponseThird.data.subscription.client_secret,
      })

      if (!cardPaymentResponseFourth?.error) {
        logSuccessfulPayment({
          ...commonPurchaseSuccessParams,
          price: currentPrice,
          trialPrice,
          productId: createSubscriptionResponse.data.purchase.product_id,
          upsellProductIds: createSubscriptionResponse.data.purchase.upsells?.map(
            (upsell) => upsell.product_id,
          ),
          trialPeriodDays:
            retryResponseThird.data.subscription.trial_period_days,
          subscriptionId: retryResponseThird.data.subscription.subscription_id,
          discountApplied:
            retryResponseThird.data.subscription.discount_applied,
          paymentMethod,
          paymentSystem: PaymentSystem.STRIPE,
          goal: isStayFitGoal
            ? ONBOARDING_GOAL_EVENT.STAY_FIT
            : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
        })
        dispatch(
          sendUserConfigAction(
            {
              discount_applied: !!retryResponseThird.data.subscription
                .discount_applied,
            },
            null,
          ),
        )
        dispatch(savePlanAdditionsAction())
        dispatch(stopFetching(PURCHASE))
        return
      }

      logFailedPayment({
        ...commonPurchaseFailedParams,
        paymentResponse: cardPaymentResponseFourth.error,
        paymentMethod,
        paymentSystem: PaymentSystem.STRIPE,

        goal: isStayFitGoal
          ? ONBOARDING_GOAL_EVENT.STAY_FIT
          : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
      })

      dispatch(
        setErrorAction(getErrorActionPayload(cardPaymentResponseFourth.error)),
      )

      // Needed for reset invoice on BE
      await paymentApi.retryPayment({
        uuid,
        stripeError: cardPaymentResponseFourth.error as StripeError,
      })

      dispatch(stopFetching(PURCHASE))
      return
    }

    const predictedLtv = await fetchPredictedLtv(uuid)

    logSuccessfulPayment({
      ...commonPurchaseSuccessParams,
      predictedLtv,
      price: currentPrice,
      productId: createSubscriptionResponse.data.purchase.product_id,
      upsellProductIds: createSubscriptionResponse.data.purchase.upsells?.map(
        (upsell) => upsell.product_id,
      ),
      trialPeriodDays:
        createSubscriptionResponse.data.purchase.trial_period_days,
      subscriptionId: createSubscriptionResponse.data.purchase.subscription_id,
      paymentMethod,
      paymentSystem: PaymentSystem.STRIPE,
      goal: isStayFitGoal
        ? ONBOARDING_GOAL_EVENT.STAY_FIT
        : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
    })

    dispatch(savePlanAdditionsAction())
    dispatch(stopFetching(PURCHASE))
  } catch (error: any) {
    dispatch(setErrorAction(error.toString()))
    dispatch(stopFetching(PURCHASE))
  }
}

export const purchaseUpgrade = (): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
): Promise<void> => {
  const state = getState()
  const planId = selectSubscriptionPlanId(state)
  const priceId = selectSubscriptionLookupKey(state)
  const uuid = selectUUID(state)
  const fullPrice = selectSubscriptionFullPrice(state)
  const amountToPay = selectUpgradeDiffSubscriptionAmountToPay(state)
  const paymentMethod = selectUserPaymentMethod(state)
  const periodQuantity = selectSubscriptionPeriodQuantity(state)
  const periodName = selectSubscriptionPeriodName(state)
  const currency = selectCurrency(state)
  const isStayFitGoal = selectIsStayFitFlow(state)
  const paymentSystem = selectUserPaymentSystem(state)
  const cohort = selectCurrentVariantCohort(state)
  const selectedSubscription = selectSubscription(state)

  dispatch(startFetching(PURCHASE))

  const commonPurchaseStartedParams = getStripePurchaseStartedEventParams(state)

  eventLogger.logPurchaseStarted({
    ...commonPurchaseStartedParams,
    paymentMethod,
    paymentSystem,
    isUpgraded: true,
    amountToPay: `${amountToPay}`,
    productId: priceId,
    priceDetails: {
      currency,
      price: commonPurchaseStartedParams.priceDetails.price,
    },
    goal: isStayFitGoal
      ? ONBOARDING_GOAL_EVENT.STAY_FIT
      : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
  })

  const {
    ...commonPurchaseSuccessParams
  } = getStripePurchaseSuccessEventParams(state)

  const commonPurchaseFailedParams = getStripePurchaseFailedEventParams(state)

  try {
    const createSubscriptionResponse =
      paymentSystem === PaymentSystem.PRIMER
        ? await paymentApi.upgradePrimerSubscription({
            uuid,
            planId,
            cohort: cohort as Cohort,
          })
        : await paymentApi.upgradeSubscription({
            uuid,
            planId,
            cohort: cohort as Cohort,
          })

    if (!createSubscriptionResponse.success) {
      const errorText = i18n.t(
        getPaymentFailedError(createSubscriptionResponse.data?.decline_code),
      )

      eventLogger.logPurchaseFailed({
        error: { type: createSubscriptionResponse.data },
        paymentMethod,
        isUpgraded: true,
        amountToPay: `${amountToPay}`,
        ...commonPurchaseFailedParams,
        paymentSystem,
        productId: priceId,
        goal: isStayFitGoal
          ? ONBOARDING_GOAL_EVENT.STAY_FIT
          : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
      })

      dispatch(setErrorAction(i18n.t(errorText)))
      dispatch(stopFetching(PURCHASE))
      return
    }

    dispatch(setIsSubscriptionUpgradedAction(true))

    eventLogger.logPurchaseCompleted({
      priceDetails: {
        price: fullPrice,
        currency,
        trial: !!selectedSubscription?.trialPrices?.durationDays,
      },
      paymentMethod,
      utmSource: 'livechat',
      isUpgraded: true,
      amountToPay: `${amountToPay}`,
      ...commonPurchaseSuccessParams,
      paymentSystem,
      productId: createSubscriptionResponse.data?.upgrade?.product_id,
      productName: `dancebit_${periodQuantity}_${periodName}_${fullPrice}_upgrade`,
      goal: isStayFitGoal
        ? ONBOARDING_GOAL_EVENT.STAY_FIT
        : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
    })

    dispatch(savePlanAdditionsAction())
    dispatch(stopFetching(PURCHASE))
  } catch (error: any) {
    dispatch(setErrorAction(error.toString()))
    dispatch(stopFetching(PURCHASE))
  }
}

export const purchaseUpgradeV2 = (): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
): Promise<void> => {
  const state = getState()
  const priceId = selectSubscriptionLookupKey(state)
  const planId = selectSubscriptionPlanId(state)
  const uuid = selectUUID(state)
  const fullPrice = selectSubscriptionFullPrice(state)
  const amountToPay = selectUpgradeDiffSubscriptionAmountToPay(state)
  const paymentMethod = selectUserPaymentMethod(state)
  const periodQuantity = selectSubscriptionPeriodQuantity(state)
  const periodName = selectSubscriptionPeriodName(state)
  const currency = selectCurrency(state)
  const isStayFitGoal = selectIsStayFitFlow(state)
  const paymentSystem = selectUserPaymentSystem(state)
  const cohort = selectCurrentVariantCohort(state)

  dispatch(startFetching(PURCHASE))

  const commonPurchaseStartedParams = getStripePurchaseStartedEventParams(state)

  const {
    ...commonPurchaseSuccessParams
  } = getStripePurchaseSuccessEventParams(state)

  const commonPurchaseFailedParams = getStripePurchaseFailedEventParams(state)

  eventLogger.logPurchaseStarted({
    paymentMethod,
    isUpgraded: true,
    ...commonPurchaseStartedParams,
    amountToPay: `${amountToPay}`,
    productId: priceId,
    priceDetails: {
      currency,
      price: commonPurchaseStartedParams.priceDetails.price,
    },
    goal: isStayFitGoal
      ? ONBOARDING_GOAL_EVENT.STAY_FIT
      : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
  })

  try {
    const createSubscriptionResponse =
      paymentSystem === PaymentSystem.PRIMER
        ? await paymentApi.upgradePrimerSubscription({
            uuid,
            planId,
            cohort: cohort as Cohort,
          })
        : await paymentApi.upgradeSubscription({
            uuid,
            planId,
            cohort: cohort as Cohort,
          })

    if (
      !createSubscriptionResponse.success ||
      !createSubscriptionResponse.data
    ) {
      switch (createSubscriptionResponse.status) {
        case 402:
          dispatch(setErrorAction(i18n.t('purchase1.insufficientFunds')))
          break
        case 404:
          dispatch(setErrorAction(i18n.t('purchase1.linkIsNotValid')))
          break
        case 409:
          dispatch(setErrorAction(i18n.t('purchase1.haveSubscription')))
          break
        default:
          dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
      }

      eventLogger.logPurchaseFailed({
        error: { type: createSubscriptionResponse.data },
        paymentMethod,
        isUpgraded: true,
        amountToPay: `${amountToPay}`,
        ...commonPurchaseFailedParams,
        productId: priceId,
        goal: isStayFitGoal
          ? ONBOARDING_GOAL_EVENT.STAY_FIT
          : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
      })

      dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
      dispatch(stopFetching(PURCHASE))
      return
    }

    dispatch(setIsSubscriptionUpgradedAction(true))

    eventLogger.logPurchaseCompleted({
      priceDetails: {
        price: fullPrice,
        currency,
        trial: !!createSubscriptionResponse.data.upgrade.trial_period_days,
      },
      paymentMethod,
      isUpgraded: true,
      amountToPay: `${amountToPay}`,
      ...commonPurchaseSuccessParams,
      productId: createSubscriptionResponse.data.upgrade.product_id,
      productName: `dancebit_${periodQuantity}_${periodName}_${fullPrice}_upgrade`,
      goal: isStayFitGoal
        ? ONBOARDING_GOAL_EVENT.STAY_FIT
        : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
    })

    dispatch(savePlanAdditionsAction())
    dispatch(stopFetching(PURCHASE))
  } catch (error: any) {
    dispatch(setErrorAction(error.toString()))
    dispatch(stopFetching(PURCHASE))
  }
}

export const check3DSecure = (stripe: Stripe): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
): Promise<void> => {
  const state = getState()
  const priceId = selectSubscriptionLookupKey(state)
  const trialPriceId = selectSubscriptionLookupKey(state)
  const currentPrice = selectSubscriptionFullPrice(state)
  const clientSecret = selectPaymentClientSecret(state)
  const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
  const subscriptionId = selectCreatedSubscriptionId(state)
  const currency = selectCurrency(state)
  const periodName = selectSubscriptionPeriodName(state)
  const periodQuantity = selectSubscriptionPeriodQuantity(state)
  const trialPrice = selectSubscriptionTrialPeriodPrice(state)
  const isStayFitGoal = selectIsStayFitFlow(state)

  if (!priceId || !currentPrice) {
    console.error('Error: no subscriptions plan selected')
    return
  }

  if (!clientSecret) {
    console.error('Error: client secret is needed')
    return
  }

  dispatch(startFetching(CHECK_3D_SECURE))

  const response = await stripe.retrievePaymentIntent(clientSecret)
  const commonPurchaseSuccessParams = getStripePurchaseSuccessEventParams(state)
  const commonPurchaseFailedParams = getStripePurchaseFailedEventParams(state)

  if (response.paymentIntent?.status === 'succeeded') {
    logSuccessfulPayment({
      price: currentPrice,
      trialPeriodDays,
      subscriptionId,
      productId: trialPriceId
        ? createIntroOfferProductId({
            priceId,
            trialPriceId,
            trialPeriodQuantity: trialPeriodDays,
          })
        : priceId,
      ...commonPurchaseSuccessParams,
      goal: isStayFitGoal
        ? ONBOARDING_GOAL_EVENT.STAY_FIT
        : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
    })

    dispatch(
      sendUserConfigAction(
        {
          payment_currency: currency,
          payment_method: PaymentMethod.CREDIT_CARD,
          is_download_visited: false,
          subscription_price: `${currentPrice}`,
          subscription_duration: `${periodQuantity}${periodName}`,
          price_id: priceId,
          trial_price: `${trialPrice}`,
          trial_period: `${trialPeriodDays}`,
        },
        null,
      ),
    )
    return
  }

  if (response.paymentIntent?.status === 'requires_payment_method') {
    eventLogger.logPurchaseFailed({
      error: {
        type: 'requires_payment_method',
      },
      goal: isStayFitGoal
        ? ONBOARDING_GOAL_EVENT.STAY_FIT
        : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
      ...commonPurchaseFailedParams,
    })
    dispatch(reset3DSecureIframeUrlAction())
    dispatch(setErrorAction(i18n.t('purchase1.paymentFailed')))
    dispatch(stopFetching(CHECK_3D_SECURE))
    return
  }

  if (response.error) {
    const {
      error: { type, code, message },
    } = response

    eventLogger.logPurchaseFailed({
      error: {
        type,
        code,
        description: message,
      },
      goal: isStayFitGoal
        ? ONBOARDING_GOAL_EVENT.STAY_FIT
        : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
      ...commonPurchaseFailedParams,
    })
    dispatch(reset3DSecureIframeUrlAction())
    dispatch(setErrorAction(message || type))
    dispatch(stopFetching(CHECK_3D_SECURE))
    return
  }

  dispatch(reset3DSecureIframeUrlAction())
  dispatch(setErrorAction(i18n.t('purchase1.3dSecureError')))
  dispatch(stopFetching(CHECK_3D_SECURE))
}

export const setPaymentMethodAction = (
  payload: PaymentMethod,
): IAction<PaymentMethod> => ({
  type: SET_PAYMENT_METHOD,
  payload,
})

export function getPrimerClientSessionTokenAction(): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const planId = selectSubscriptionPlanId(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const cohort = selectCurrentVariantCohort(state) as Cohort
    const userCart = selectUserCart(state)

    const upsellPlanIds = getSubscriptionPlanId(userCart)

    dispatch(startFetching(GET_PRIMER_CLIENT_SESSION_TOKEN))

    const response = await paymentApi.getPrimerClientSessionToken({
      uuid,
      cohort,
      planId,
      trialPeriodDays,
      upsellPlanIds,
    })

    if (response.success && response.data) {
      dispatch(setPrimerClientSessionTokenAction(response.data.client_token))
    }

    dispatch(stopFetching(GET_PRIMER_CLIENT_SESSION_TOKEN))
  }
}

export function primerConfirmPaymentAction(
  paymentId: string,
  handler: OnTokenizeSuccessHandler,
): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const priceId = selectSubscriptionLookupKey(state)
    const uuid = selectUUID(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const currentPrice = selectSubscriptionFullPrice(state)
    const trialPrice = selectSubscriptionTrialPeriodPrice(state)
    const currency = selectCurrency(state)
    const periodName = selectSubscriptionPeriodName(state)
    const periodQuantity = selectSubscriptionPeriodQuantity(state)
    const paymentMethod = selectPaymentMethod(state)
    const isStayFitGoal = selectIsStayFitFlow(state)

    const commonPurchaseFailedParams = getStripePurchaseFailedEventParams(state)

    dispatch(
      setBackupPrimerSubscriptionConfigAction({
        paymentCurrency: currency,
        paymentMethod,
        subscriptionPrice: currentPrice,
        subscriptionDuration: `${periodQuantity}${periodName}`,
        priceId,
        trialPrice,
        trialPeriod: `${trialPeriodDays}`,
        paymentSystem: PaymentSystem.PRIMER,
      }),
    )

    try {
      const response = await paymentApi.confirmPrimerSubscription({
        uuid,
        paymentId,
      })

      if (!response.data && !window.navigator.onLine) {
        dispatch(setErrorAction(i18n.t(PRIMER_PAYMENT_ERRORS.COMMON_ERROR)))
        dispatch(stopFetching(PURCHASE))
        dispatch(setIsPrimerRetryProcessing(false))
        return
      }

      if (!response.success && response?.data?.error) {
        eventLogger.logPurchaseFailed({
          ...commonPurchaseFailedParams,
          paymentMethod,
          error: response?.data?.error,
          paymentSystem: PaymentSystem.PRIMER,
          goal: isStayFitGoal
            ? ONBOARDING_GOAL_EVENT.STAY_FIT
            : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
        })
        dispatch(setErrorAction(response?.data?.error))
        dispatch(stopFetching(PURCHASE))
        dispatch(setIsPrimerRetryProcessing(false))

        handler.handleFailure(response?.data?.error)
        return
      }

      dispatch(savePlanAdditionsAction())
      dispatch(stopFetching(PURCHASE))
      dispatch(setIsPrimerRetryProcessing(false))
      handler.handleSuccess()
    } catch (error: any) {
      dispatch(setErrorAction(error.toString()))
      dispatch(setIsPrimerRetryProcessing(false))
      dispatch(stopFetching(PURCHASE))
      handler.handleFailure(error.toString())
    }
  }
}

export function primerPurchaseAction(
  token: string,
  handler: OnTokenizeSuccessHandler,
  retryPaymentCallback: () => void,
): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const paymentMethod = selectPaymentMethod(state)
    const isStayFitGoal = selectIsStayFitFlow(state)
    const trialPeriodPrice = selectSubscriptionTrialPeriodPrice(state)
    const priceId = selectSubscriptionLookupKey(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const currentPrice = selectSubscriptionFullPrice(state)
    const trialPrice = selectSubscriptionTrialPeriodPrice(state)
    const currency = selectCurrency(state)
    const periodName = selectSubscriptionPeriodName(state)
    const periodQuantity = selectSubscriptionPeriodQuantity(state)
    const discountTried = selectIsFirstPaymentRetryPassed(state)

    const commonPurchaseFailedParams = getStripePurchaseFailedEventParams(state)
    const commonPurchaseSuccessParams = getStripePurchaseSuccessEventParams(
      state,
    )

    dispatch(
      sendUserConfigAction({
        payment_currency: currency,
        payment_method: paymentMethod,
        payment_system: PaymentSystem.PRIMER,
        is_download_visited: false,
        subscription_price: `${currentPrice}`,
        subscription_duration: `${periodQuantity}${periodName}`,
        price_id: priceId,
        screen_name: commonPurchaseSuccessParams.screenName,
        product_name: commonPurchaseSuccessParams.productName,
        period_quantity: periodQuantity,
        trial_price: `${trialPrice}`,
        trial_period: `${trialPeriodDays}`,
      }),
    )

    dispatch(startFetching(PURCHASE))

    try {
      const response = await paymentApi.createPrimerSubscription({
        uuid,
        token,
      })

      if (!response.data && !window.navigator.onLine) {
        dispatch(setErrorAction(i18n.t(PRIMER_PAYMENT_ERRORS.COMMON_ERROR)))
        dispatch(stopFetching(PURCHASE))
        dispatch(setIsPrimerRetryProcessing(false))
        return
      }

      if (!response.data.payment.is_success) {
        const error = {
          type: response.data.payment.status_reason.type,
          description: response.data.payment.status_reason.message,
          code: response.data.payment.status_reason.code,
        }

        eventLogger.logPurchaseFailed({
          ...commonPurchaseFailedParams,
          paymentMethod,
          error,
          paymentSystem: PaymentSystem.PRIMER,
          goal: isStayFitGoal
            ? ONBOARDING_GOAL_EVENT.STAY_FIT
            : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
        })

        handler.handleFailure(error.description)

        dispatch(
          setErrorAction(
            i18n.t(
              PRIMER_PAYMENT_ERRORS[error.code] ||
                PRIMER_PAYMENT_ERRORS.COMMON_ERROR,
            ),
          ),
        )
        dispatch(stopFetching(PURCHASE))
        dispatch(setIsPrimerRetryProcessing(false))

        if (
          response?.data.payment.status_reason.declineType ===
            PRIMER_SOFT_DECLINE &&
          paymentMethod === PaymentMethod.CREDIT_CARD &&
          !discountTried
        ) {
          dispatch(updatePrimerClientSessionAction(retryPaymentCallback))
        }

        return
      }

      dispatch(
        setPrimerClientSessionTokenAction(response.data.payment.client_token),
      )

      if (response.data.payment.client_token) {
        dispatch(
          updateUserConfigAction({
            payment_id: response.data.payment.payment_id,
          }),
        )

        handler.continueWithNewClientToken(response.data.payment.client_token)
        return
      }

      dispatch(
        primerConfirmPaymentAction(response.data.payment.payment_id, handler),
      )

      const predictedLtv = await fetchPredictedLtv(uuid)

      logSuccessfulPayment({
        productId: response.data.purchase.product_id,
        upsellProductIds: response.data.purchase.upsells?.map(
          (upsell) => upsell.product_id,
        ),
        ...commonPurchaseSuccessParams,
        predictedLtv,
        price: currentPrice,
        trialPrice: trialPeriodPrice,
        trialPeriodDays,
        discountApplied: response.data.purchase.discount_applied,
        subscriptionId: response.data.purchase.subscription_id,
        paymentMethod,
        paymentSystem: PaymentSystem.PRIMER,
        goal: isStayFitGoal
          ? ONBOARDING_GOAL_EVENT.STAY_FIT
          : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
      })

      dispatch(
        sendUserConfigAction({
          discount_applied: !!response.data.purchase.discount_applied,
        }),
      )
    } catch (error: any) {
      dispatch(setErrorAction(error.toString()))
      dispatch(setIsPrimerRetryProcessing(false))
      dispatch(stopFetching(PURCHASE))
      handler.handleFailure(error.toString())
    }
  }
}

export function cancelPrimerClientSessionDiscount({
  clientToken,
}: {
  clientToken: string
}): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    dispatch(startFetching(CANCEL_PRIMER_CLIENT_SESSION_DISCOUNT))

    try {
      const response = await paymentApi.updatePrimerClientSession({
        uuid,
        clientToken,
      })

      dispatch(stopFetching(CANCEL_PRIMER_CLIENT_SESSION_DISCOUNT))

      if (!response.success && response?.data?.error) {
        dispatch(setErrorAction(i18n.t(PRIMER_PAYMENT_ERRORS.COMMON_ERROR)))

        return
      }
    } catch (error: any) {
      dispatch(stopFetching(CANCEL_PRIMER_CLIENT_SESSION_DISCOUNT))
      dispatch(setErrorAction(error.toString()))
      dispatch(stopFetching(PURCHASE))
    }
  }
}

export function primerResumePurchaseAction(
  currentPaymentId: string | undefined,
  resumeToken: string,
  handler: OnResumeSuccessHandler,
  retryPaymentCallback: () => void,
): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const paymentMethod = selectPaymentMethod(state)
    const isStayFitGoal = selectIsStayFitFlow(state)
    const paymentId = selectUserPaymentId(state)
    const trialPeriodPrice = selectSubscriptionTrialPeriodPrice(state)
    const priceId = selectSubscriptionLookupKey(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const currentPrice = selectSubscriptionFullPrice(state)
    const trialPrice = selectSubscriptionTrialPeriodPrice(state)
    const currency = selectCurrency(state)
    const periodName = selectSubscriptionPeriodName(state)
    const periodQuantity = selectSubscriptionPeriodQuantity(state)
    const discountTried = selectIsFirstPaymentRetryPassed(state)

    const commonPurchaseSuccessParams = getStripePurchaseSuccessEventParams(
      state,
    )
    const commonPurchaseFailedParams = getStripePurchaseFailedEventParams(state)

    dispatch(startFetching(PURCHASE))

    dispatch(
      sendUserConfigAction({
        payment_currency: currency,
        payment_method: paymentMethod,
        payment_system: PaymentSystem.PRIMER,
        is_download_visited: false,
        subscription_price: `${currentPrice}`,
        subscription_duration: `${periodQuantity}${periodName}`,
        price_id: priceId,
        screen_name: commonPurchaseSuccessParams.screenName,
        product_name: commonPurchaseSuccessParams.productName,
        period_quantity: periodQuantity,
        trial_price: `${trialPrice}`,
        trial_period: `${trialPeriodDays}`,
      }),
    )

    try {
      const response = await paymentApi.resumePrimerSubscription({
        uuid,
        paymentId: currentPaymentId || paymentId,
        resumeToken,
      })

      if (!response.data && !window.navigator.onLine) {
        dispatch(setErrorAction(i18n.t(PRIMER_PAYMENT_ERRORS.COMMON_ERROR)))
        dispatch(stopFetching(PURCHASE))
        dispatch(setIsPrimerRetryProcessing(false))
        return
      }

      if (!response.data.payment.is_success) {
        const error = {
          type: response.data.payment.status_reason.type,
          description: response.data.payment.status_reason.message,
          code: response.data.payment.status_reason.code,
        }

        eventLogger.logPurchaseFailed({
          ...commonPurchaseFailedParams,
          paymentMethod,
          error,
          paymentSystem: PaymentSystem.PRIMER,
          goal: isStayFitGoal
            ? ONBOARDING_GOAL_EVENT.STAY_FIT
            : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
        })

        handler.handleFailure(error.description)

        dispatch(
          setErrorAction(
            i18n.t(
              PRIMER_PAYMENT_ERRORS[error.code] ||
                PRIMER_PAYMENT_ERRORS.COMMON_ERROR,
            ),
          ),
        )
        dispatch(stopFetching(PURCHASE))
        dispatch(setIsPrimerRetryProcessing(false))

        if (
          response?.data.payment.status_reason.declineType ===
            PRIMER_SOFT_DECLINE &&
          paymentMethod === PaymentMethod.CREDIT_CARD &&
          !discountTried
        ) {
          dispatch(updatePrimerClientSessionAction(retryPaymentCallback))
        }

        return
      }

      if (response.data.payment.client_token) {
        handler.continueWithNewClientToken(response.data.payment.client_token)
        return
      }

      dispatch(
        primerConfirmPaymentAction(response.data.payment.payment_id, handler),
      )
      dispatch(
        setPrimerClientSessionTokenAction(response.data.payment.client_token),
      )

      const predictedLtv = await fetchPredictedLtv(uuid)

      logSuccessfulPayment({
        productId: response.data.purchase.product_id,
        upsellProductIds: response.data.purchase.upsells?.map(
          (upsell) => upsell.product_id,
        ),
        ...commonPurchaseSuccessParams,
        predictedLtv,
        price: currentPrice,
        trialPrice: trialPeriodPrice,
        trialPeriodDays,
        discountApplied: response.data.purchase.discount_applied,
        subscriptionId: response.data.purchase.subscription_id,
        paymentMethod,
        paymentSystem: PaymentSystem.PRIMER,
        goal: isStayFitGoal
          ? ONBOARDING_GOAL_EVENT.STAY_FIT
          : ONBOARDING_GOAL_EVENT.LOSE_WEIGHT,
      })

      dispatch(
        sendUserConfigAction({
          discount_applied: !!response.data.purchase.discount_applied,
        }),
      )
      handler.handleSuccess()
    } catch (error: any) {
      dispatch(setErrorAction(error.toString()))
      dispatch(setIsPrimerRetryProcessing(false))
      dispatch(stopFetching(PURCHASE))
      handler.handleFailure(error.toString())
    }
  }
}
