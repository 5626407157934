import React, { useCallback, useEffect, useLayoutEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Container } from 'components/Container'

import { getSubscriptionHighestDiscountAction } from 'root-redux/actions/common'
import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectLanguage,
} from 'root-redux/selects/common'

import { useVatInfo } from 'hooks/useVatInfo'

import {
  DISCOUNT_VALUES,
  DiscountValue,
} from 'modules/purchase/components/PersonalPlan/constants'
import { CANCEL_OFFER_ADDITIONAL_DISCOUNT } from 'modules/purchase/constants'
import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'
import { CANCEL_OFFER_PAYWALLS } from 'modules/purchase/pages/CancelOfferQuestion/constants'
import {
  selectDynamicDiscount,
  selectHighestDiscountPercentage,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  CDN_FOLDER_LINK,
  Cohort,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { StyledCancelOfferQuestion as S } from './CancelOfferQuestionV2.styles'

export const CancelOfferQuestionV2: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()

  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const highestDiscountPercentage = useSelector(selectHighestDiscountPercentage)
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const language = useSelector(selectLanguage)

  const hasIncludedVat = useVatInfo()
  const { isSaleFlow, isTrialCohort } = useCohortInfo()

  const cohortToUse = (parentCohort || cohort) as Cohort

  const discountPercentage = useMemo(
    () =>
      dynamicDiscount?.amount && isSaleFlow
        ? dynamicDiscount.amount + CANCEL_OFFER_ADDITIONAL_DISCOUNT
        : highestDiscountPercentage ||
          DISCOUNT_VALUES[cohortToUse] ||
          DiscountValue.SIXTY_FIVE,
    [
      isSaleFlow,
      dynamicDiscount?.amount,
      highestDiscountPercentage,
      cohortToUse,
    ],
  )

  useEffect(() => {
    eventLogger.logCancelOfferPageShown()
  }, [])

  useLayoutEffect(() => {
    if (isTrialCohort) {
      const pricesTags: string = hasIncludedVat
        ? SubscriptionTags.TAX
        : SubscriptionTags.NO_TAX

      dispatch(
        getSubscriptionHighestDiscountAction(
          SubscriptionListType.PURCHASE,
          `${SubscriptionTags.CANCEL_OFFER},${pricesTags}`,
        ),
      )
    }
  }, [dispatch, isTrialCohort, hasIncludedVat, cohortToUse])

  const questionImageLink = useMemo(
    () => `${CDN_FOLDER_LINK}/images/before-after-female_${language}.jpg`,
    [language],
  )

  const handleNextPage = useCallback(() => {
    eventLogger.logCancelOfferPageClosed()

    goTo({
      pathname:
        (CANCEL_OFFER_PAYWALLS[cohortToUse as Cohort] as PageId) ||
        PageId.PURCHASE_CANCEL_OFFER,
      search,
    })
  }, [cohortToUse, search])

  return (
    <>
      <S.Container>
        <Container>
          <S.Title>
            <Trans i18nKey="purchaseCancelOffer.questionPage.didYouKnow" />
          </S.Title>
          <S.Subtitle>
            <Trans i18nKey="purchaseCancelOffer.questionPage.subtitle" />
          </S.Subtitle>
          <S.ImageContainer>
            <S.Image src={questionImageLink} alt="main-image" />
            <S.ImageDescription>
              {t('purchaseCancelOffer.questionPage.imageText')}
            </S.ImageDescription>
          </S.ImageContainer>

          <S.Text>
            <Trans
              i18nKey="purchaseCancelOffer.questionPage.findSuccess"
              values={{ percentage: discountPercentage }}
            />
          </S.Text>
        </Container>
      </S.Container>
      <S.NavigationButton type="button" onClick={handleNextPage}>
        {t`purchaseCancelOffer.questionPage.gotIt`}
      </S.NavigationButton>
    </>
  )
}
