import { css } from 'styled-components'

import { Color, DynamicDiscountTheme } from 'root-constants'

export const DEFAULT_DISCOUNT_NAME = 'LAST DAYS OF MEGA SALE'

export const ThemeTickerConfig = {
  [DynamicDiscountTheme.CORAL]: css`
    background: linear-gradient(91deg, #ff5343 8.21%, #e1226c 95.97%);
  `,
  [DynamicDiscountTheme.YELLOW]: css`
    background: linear-gradient(91deg, #ffce3c -8.21%, #ff9000 95.97%);
  `,
  [DynamicDiscountTheme.VIOLET]: css`
    background: linear-gradient(270deg, #7470ff 0%, #e2a3f8 103.03%);
  `,
  [DynamicDiscountTheme.BLACK]: css`
    background-color: ${Color.BLACK};
  `,
  [DynamicDiscountTheme.RED]: css`
    background: linear-gradient(90deg, #ff5e32 0.54%, #e30004 96.76%);
  `,
}
