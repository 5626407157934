import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useTargetHiddenOnScroll } from 'hooks/useTargetHiddenOnScroll'

import { FIFTEEN_MINUTES_IN_SECONDS } from 'modules/purchase/constants'
import { getDecoratedTimerValue } from 'modules/purchase/helpers/rootHelpers'
import { useTimerForTarget } from 'modules/purchase/hooks/useTimerForTarget'

import { StyledSubheaderWithTimerTwoWeek as S } from './SubheaderWithTimerTwoWeek.styles'

type TProps = {
  onButtonClick: (event) => void
  elemForComparisonRef: React.RefObject<HTMLDivElement>
}
export const SubheaderWithTimerTwoWeek: React.FC<TProps> = ({
  elemForComparisonRef,
  onButtonClick,
}) => {
  const { t } = useTranslation()
  const isButtonVisible = useTargetHiddenOnScroll(elemForComparisonRef)

  const timerElementHourRef = useRef<HTMLDivElement>(null)
  const timerElementMinRef2 = useRef<HTMLDivElement>(null)
  const timerElementMinRef = useRef<HTMLDivElement>(null)
  const timerElementSecRef = useRef<HTMLDivElement>(null)
  const timerElementSecRef2 = useRef<HTMLDivElement>(null)

  useTimerForTarget(
    {
      hours: timerElementHourRef,
      minutes: timerElementMinRef,
      seconds: timerElementSecRef,
    },
    FIFTEEN_MINUTES_IN_SECONDS,
    true,
  )

  useEffect(() => {
    const nextTimerValue = FIFTEEN_MINUTES_IN_SECONDS - 1

    const formattedTime = getDecoratedTimerValue(nextTimerValue, false)

    if (timerElementMinRef.current && timerElementMinRef2.current) {
      timerElementMinRef.current.innerHTML = formattedTime.minutes
      timerElementMinRef2.current.innerHTML = formattedTime.minutes
    }

    if (timerElementSecRef.current && timerElementSecRef2.current) {
      timerElementSecRef.current.innerHTML = formattedTime.seconds
      timerElementSecRef2.current.innerHTML = formattedTime.seconds
    }
  }, [])

  return (
    <>
      <S.TimerContainer $isButtonVisible={isButtonVisible}>
        {isButtonVisible ? (
          <S.TimerText $isButtonVisible={isButtonVisible}>
            {t`purchase3.timer.text`}
          </S.TimerText>
        ) : (
          <>
            <S.Title>{t`purchase3.timer.title`}</S.Title>
            <S.Text>{t`purchase3.timer.text`}</S.Text>
          </>
        )}

        <S.Timer>
          <S.TimerSign ref={timerElementMinRef}>14</S.TimerSign>:
          <S.TimerSign ref={timerElementSecRef}>59</S.TimerSign>
        </S.Timer>

        <S.ButtonContainer $isButtonVisible={isButtonVisible}>
          <S.TimerButton
            data-order="1"
            data-text="get_now"
            onClick={onButtonClick}
          >{t`purchaseWithoutIntro.subheaderTimer.getNowButton`}</S.TimerButton>
        </S.ButtonContainer>
      </S.TimerContainer>
    </>
  )
}
