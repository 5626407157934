import React, {
  Dispatch,
  FC,
  InputHTMLAttributes,
  SetStateAction,
  forwardRef,
  useCallback,
} from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import de from 'date-fns/locale/de'
import es from 'date-fns/locale/es'
import fr from 'date-fns/locale/fr'
import it from 'date-fns/locale/it'
import ja from 'date-fns/locale/ja'
import pt from 'date-fns/locale/pt'

import { DISPLAYING_DATE_FORMAT } from 'root-constants'

import { StyledCustomDatePicker as S } from './CustomDatePicker.styles'

registerLocale('es', es)
registerLocale('fr', fr)
registerLocale('pt', pt)
registerLocale('de', de)
registerLocale('it', it)
registerLocale('ja', ja)

type TProps = {
  onChange: Dispatch<SetStateAction<Date>>
  selectedDate: Date | null
  minDate: Date
  toggleDatePickerVisibility: () => void
  language: string
}

type TInputProps = InputHTMLAttributes<HTMLInputElement>

export const CustomDatePicker: FC<TProps> = ({
  onChange,
  selectedDate,
  minDate,
  toggleDatePickerVisibility,
  language,
  ...props
}) => {
  const handleDateFieldClick = (onClick) => {
    toggleDatePickerVisibility()
    onClick()
  }

  const handleOutsideDatePickerClick = () => {
    toggleDatePickerVisibility()
  }

  const handleDateChange = useCallback(
    (date: Date | null) => {
      if (date) {
        onChange(date)
      }
    },
    [onChange],
  )

  const CustomInput: FC = forwardRef<HTMLInputElement, TInputProps>(
    (inputProps, ref) => (
      <S.WrapperDataField
        onClick={() => {
          handleDateFieldClick(inputProps.onClick)
        }}
      >
        <S.DateField ref={ref} {...inputProps}>
          {inputProps.value}
        </S.DateField>
        <S.CalendarIcon />
      </S.WrapperDataField>
    ),
  )

  return (
    <S.Wrapper>
      <DatePicker
        customInput={<CustomInput />}
        showIcon
        locale={language}
        onCalendarClose={handleOutsideDatePickerClick}
        selected={selectedDate}
        onChange={handleDateChange}
        minDate={minDate}
        dateFormat={DISPLAYING_DATE_FORMAT}
        popperModifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, -55],
            },
            fn(state) {
              return state
            },
          },
        ]}
        {...props}
      />
    </S.Wrapper>
  )
}
