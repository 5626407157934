import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Timer } from 'modules/purchase/components/Timer'
import { selectDynamicDiscount } from 'modules/purchase/redux/selects/common'

import { StyledDynamicTimer as S } from './DynamicTimer.styles'

type TProps = {
  className?: string
}
export const DynamicTimer: React.FC<TProps> = ({ className = '' }) => {
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  return (
    <S.Container $theme={dynamicDiscount?.theme} className={className}>
      <S.Text $theme={dynamicDiscount?.theme}>
        <Trans
          i18nKey="purchaseWithoutIntro.offerExpiresIn"
          components={{
            timer: <Timer />,
          }}
        />
      </S.Text>
    </S.Container>
  )
}
