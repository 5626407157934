import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Swiper, { Autoplay } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { selectAnswers } from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'

import { BEFORE_AFTER_SLIDES } from 'modules/purchase/components/CourseDescriptionV2/constants'

import { PageId } from 'page-constants'

import { StyledCourseDescription as S } from './CourseDescriptionV2.styles'

Swiper.use([Autoplay])

export const CourseDescriptionV2: React.FC = () => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const { title } = useDynamicPaywallConfig()

  const weight = useMemo(() => {
    const goalWeight = userAnswers?.goalWeight || 50
    const unit = t(
      `commonComponents.${userAnswers?.measurementSystem || 'metric'}`,
    )

    return `${goalWeight}\u00A0${unit}`
  }, [t, userAnswers?.goalWeight, userAnswers?.measurementSystem])

  return (
    <>
      <S.Wrapper>
        <S.Course>
          {title || (
            <Trans
              i18nKey="purchaseTrialV2.courseDescription.description"
              values={{
                name: userAnswers?.[PageId.NAME],
              }}
              components={{
                br: <br />,
                name: <span />,
              }}
            />
          )}
        </S.Course>
        <S.ReachTarget>
          <Trans
            i18nKey="purchaseTrialV2.courseDescription.reachTarget"
            values={{
              weight,
            }}
            components={{
              weight: <span />,
            }}
          />
        </S.ReachTarget>

        <S.CarouselContainer>
          <Carousel
            slidesPerView={1}
            loop
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
          >
            {BEFORE_AFTER_SLIDES.map((img) => (
              <SwiperSlide key={img}>
                <S.Image src={img} />
              </SwiperSlide>
            ))}
          </Carousel>
        </S.CarouselContainer>
      </S.Wrapper>

      <S.GoalContainer>
        <S.Goal>
          <Trans i18nKey="purchaseTrialV2.courseDescription.beInLoveWithBody" />
        </S.Goal>
        <S.Goal>
          <Trans i18nKey="purchaseTrialV2.courseDescription.longLastingResults" />
        </S.Goal>

        <S.Goal>
          <Trans i18nKey="purchaseTrialV2.courseDescription.healthierLife" />
        </S.Goal>
      </S.GoalContainer>
    </>
  )
}
