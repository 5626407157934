import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledPersonalPlan = {
  Wrapper: styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 auto 17px;
    padding: 16px 5px 16px 24px;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    background: linear-gradient(0deg, #f5f5f7, #f5f5f7), #f5f5f7;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  WrapperV2: styled.div`
    position: relative;
    display: flex;
    margin: 0 auto 16px;
    padding: 16px 0 20px 16px;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    background: linear-gradient(74deg, #fdd 8.9%, #fff3ec 86.19%);

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  SpecialOffer: styled.div`
    height: 47px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${Color.WHITE};
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;

    svg {
      margin-left: 4px;
    }

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Title: styled.h2`
    margin-bottom: 11px;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
  `,
  TitleV2: styled.h2`
    margin-bottom: 8px;
    color: ${Color.DARK};
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  `,
  DiscountTextV2: styled.p`
    border-radius: 20px;
    padding: 2px 10px 4px;
    background: #e22a4d;
    color: ${Color.WHITE};
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    width: fit-content;
  `,
  PreviousDiscount: styled.p`
    position: relative;
    color: #6a7384;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;

    span {
      border-radius: 16px;
      background: ${Color.WHITE};
      padding: 0 4px;
    }

    &:before {
      position: absolute;
      content: '';
      width: calc(100% - 4px);
      height: 1px;
      background: #6a7384;
      top: 50%;
    }
  `,
  DiscountText: styled.p`
    padding: 6px 16px;
    background: #b1446b;
    border-radius: 8px;
    line-height: 24px;
    font-weight: 700;
    font-size: 16px;
    color: ${Color.WHITE};
    width: fit-content;

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      font-size: 20px;
    }
  `,
  Picture: styled.picture`
    max-width: 115px;
    max-height: 130px;
    min-width: 125px;

    @media (min-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      max-width: 135px;
    }
  `,
  PictureV2: styled.picture`
    position: absolute;
    right: 0;
    top: 0;
    width: 203px;
    height: 156px;
  `,
  PinnedBanner: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 72px;
    background: linear-gradient(74deg, #fdd 8.9%, #fff3ec 86.19%);

    button {
      margin: 12px 16px 0 0;
      max-width: 148px;
      height: 48px;
      line-height: normal;
    }

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
      left: 50%;
      transform: translateX(-50%);
    }
  `,
  Image: styled.img`
    height: 72px;
  `,
}
