import styled from 'styled-components'

export const StyledSubscriptionsBlockIntroOffer = {
  Wrapper: styled.div`
    margin: 0 auto;
    max-width: 384px;
    width: 100%;
    padding: 24px 16px 0;
    border-radius: 24px;
  `,
  ButtonContainer: styled.div`
    margin: 24px 0 16px;
    button {
      max-width: 100%;
    }
  `,
}
