import styled, { css, keyframes } from 'styled-components'

const appearAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const tipStyles = css`
  position: absolute;
  padding: 4px;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: left;
  opacity: 0;
  animation-name: ${appearAnimation};
  animation-timing-function: linear;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  color: white;
  border-radius: 7px;
  transform: translateX(-50%);
  white-space: nowrap;
`

export const StyledLoseWeightGraphAge = {
  Wrapper: styled.div`
    min-height: 212px;
    position: relative;
  `,
  GraphContent: styled.div`
    position: relative;
  `,
  TipNow: styled.p`
    ${tipStyles};

    top: 48px;
    left: 25px;
    border-radius: 4px;
    background: rgba(235, 53, 36, 0.16);
    color: #eb3524;
    animation-delay: 400ms;
  `,
  TipAfter: styled.p`
    ${tipStyles};

    top: 96px;
    left: 216px;
    border-radius: 4px;
    background: #55d16f;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
    animation-delay: 1200ms;
  `,
  WrapperWeightLoss: styled.span`
    ${tipStyles};

    top: 76px;
    left: 120px;
    border-radius: 4px;
    background: #f5f6f7;
    color: #8e9199;
    animation-delay: 1000ms;
  `,
  WeightStaysOff: styled.span<{ $isLongWord: boolean }>`
    ${tipStyles};

    top: 148px;
    right: ${({ $isLongWord }) => ($isLongWord ? '-34px' : '-44px')};
    padding: 0;
    color: #55d16f;
    text-align: right;
    animation-delay: 1500ms;
  `,
  ExpectedResultsContainer: styled.div`
    ${tipStyles};

    top: 36px;
    left: 202px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation-delay: 1200ms;
  `,
  ExpectedResults: styled.div`
    color: #8e9199;
  `,
  GraphImg: styled.img``,
}
