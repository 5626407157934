import { Currency } from 'modules/purchase/constants'

export function formatJaPriceWithCommas(
  price: number | string,
  currency: string,
): number | string {
  return currency === Currency.JP
    ? Number(price).toLocaleString('ja-JP')
    : price
}
