import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectUserAvailableSpins,
  selectUserCountryCode,
} from 'root-redux/selects/user'

import { useVatInfo } from 'hooks/useVatInfo'

import { formatJaPriceWithCommas } from 'helpers/formatPriceWithCommas'
import { getCalculatedPrice } from 'helpers/getCalculatedPrice'

import {
  CURRENCY_SYMBOLS,
  Currency,
  DECORATING_DISCOUNT,
  DEFAULT_DYNAMIC_DISCOUNT,
  GeneralProduct,
  ONE_HUNDRED_PERCENTS,
  TRIAL_ONE_MONTH_DURATION,
} from 'modules/purchase/constants'
import { selectTaxAmount } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { SEVEN_DAY_TRIAL_DURATION } from 'root-constants'

import { StyledSubscriptionItemIntroOffer as S } from './SubscriptionItemNewYear.styles'

type TProps = {
  subscription: ISubscription
  isSelected: boolean
}

export const SubscriptionItemNewYear: React.FC<TProps> = ({
  subscription: {
    id,
    product,
    mainPrices: { periodQuantity, fullPrice, daily: mainDailyPrice },
    trialPrices: {
      fullPrice: trialCurrentPrice,
      daily: trialDailyPrice,
      durationDays,
      oldPrices,
    },
    isDefault,
    currency,
  },
  isSelected,
}) => {
  const { t } = useTranslation()
  const hasVatInfo = useVatInfo()
  const taxAmount = useSelector(selectTaxAmount)
  const country = useSelector(selectUserCountryCode)
  const availableSpins = useSelector(selectUserAvailableSpins)

  const customPriceWithFractionalPart = useMemo(
    () => (trialDailyPrice || mainDailyPrice)?.toFixed(2),
    [trialDailyPrice, mainDailyPrice],
  )

  const hasSmallPrices = useMemo(
    () => currency === Currency.MXN || currency === Currency.BRL,
    [currency],
  )

  const currentPriceWithFractionalPart = useMemo(
    () => (trialCurrentPrice || fullPrice).toFixed(2),
    [trialCurrentPrice, fullPrice],
  )

  const getCurrentPrice = useCallback(
    (value?: number | string) =>
      hasVatInfo && value
        ? getCalculatedPrice(value, taxAmount, country)
        : value || '',

    [country, hasVatInfo, taxAmount],
  )

  const getOldPrice = useCallback(
    ({
      price,
      isFormattedDiscount,
    }: {
      price: number
      isFormattedDiscount?: boolean
    }) => {
      const discount =
        oldPrices.beforeCustomDiscount?.percentOfDiscount ||
        DEFAULT_DYNAMIC_DISCOUNT

      const calculatedOldPrice =
        price / ((ONE_HUNDRED_PERCENTS - discount) / ONE_HUNDRED_PERCENTS)

      if (!isFormattedDiscount) {
        return (+getCurrentPrice(calculatedOldPrice)).toFixed(2)
      }

      return (
        Math.ceil(+getCurrentPrice(calculatedOldPrice)) - DECORATING_DISCOUNT
      ).toFixed(2)
    },
    [getCurrentPrice, oldPrices],
  )

  const { oldDailyPrice, oldSubscriptionPrice } = useMemo(
    () => ({
      oldDailyPrice: getCurrentPrice(oldPrices.beforeCustomDiscount?.daily),
      oldSubscriptionPrice:
        product === GeneralProduct.SEVEN_DAY || !trialCurrentPrice
          ? getCurrentPrice(oldPrices.beforeCustomDiscount?.fullPrice)
          : getOldPrice({
              price: trialCurrentPrice,
              isFormattedDiscount: false,
            }),
    }),
    [getCurrentPrice, getOldPrice, trialCurrentPrice, product, oldPrices],
  )

  return (
    <S.Wrapper
      key={id}
      $isSelected={isSelected}
      $availableSpins={availableSpins}
    >
      <S.Content $isSelected={isSelected} $availableSpins={availableSpins}>
        {isDefault && (
          <S.MostPopularBadge
            $availableSpins={availableSpins}
          >{t`purchase1.subscription.mostPopularBadge`}</S.MostPopularBadge>
        )}
        <S.PlanContainer>
          <S.PlanPeriod $isSelected={isSelected}>
            {durationDays === Number(SEVEN_DAY_TRIAL_DURATION) ||
            periodQuantity === Number(SEVEN_DAY_TRIAL_DURATION) ? (
              <Trans
                i18nKey="purchaseIntroOffer.weeklyPeriod"
                values={{ trialPeriodDays: durationDays || periodQuantity }}
              />
            ) : (
              <Trans
                i18nKey="purchaseIntroOffer.monthlyPeriod"
                values={{
                  count: durationDays
                    ? Math.round(durationDays / TRIAL_ONE_MONTH_DURATION)
                    : periodQuantity,
                }}
              />
            )}
          </S.PlanPeriod>

          <S.PriceContainer>
            {oldSubscriptionPrice && (
              <S.PreviousPrice $hasSmallPrices={hasSmallPrices}>
                <Trans
                  i18nKey="purchase1.checkout.price"
                  values={{
                    price: formatJaPriceWithCommas(
                      oldSubscriptionPrice,
                      currency,
                    ),
                    currency: CURRENCY_SYMBOLS[currency],
                  }}
                />
              </S.PreviousPrice>
            )}
            <S.CurrentPrice $hasSmallPrices={hasSmallPrices}>
              <Trans
                i18nKey="purchase1.checkout.price"
                values={{
                  price: formatJaPriceWithCommas(
                    getCurrentPrice(currentPriceWithFractionalPart),
                    currency,
                  ),
                  currency: CURRENCY_SYMBOLS[currency],
                }}
              />
            </S.CurrentPrice>
          </S.PriceContainer>
        </S.PlanContainer>

        <S.CustomPreviousPrice $hasSmallPrices={hasSmallPrices}>
          {oldDailyPrice && (
            <Trans
              i18nKey="purchase1.checkout.price"
              values={{
                price: formatJaPriceWithCommas(oldDailyPrice, currency),
                currency: CURRENCY_SYMBOLS[currency],
              }}
            />
          )}
        </S.CustomPreviousPrice>
        <S.CustomPrice
          $isSelected={isSelected}
          $availableSpins={availableSpins}
          $hasSmallPrices={hasSmallPrices}
        >
          <S.PriceValue $hasSmallPrices={hasSmallPrices}>
            <Trans
              i18nKey="purchase1.checkout.price"
              values={{
                price: formatJaPriceWithCommas(
                  getCurrentPrice(customPriceWithFractionalPart),
                  currency,
                ),
                currency: CURRENCY_SYMBOLS[currency],
              }}
            />
          </S.PriceValue>
          <S.Period>
            {t`purchase1.subscription.interval`}{' '}
            {t(`purchase1.subscription.day`)}
          </S.Period>
        </S.CustomPrice>
      </S.Content>
    </S.Wrapper>
  )
}
