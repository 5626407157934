import { Currency } from 'modules/purchase/constants'

import reachImage1 from 'assets/images/reach-img1.png'
import reachImage2 from 'assets/images/reach-img2.png'
import reachImage3 from 'assets/images/reach-img3.png'
import reachImageLegs1 from 'assets/images/reach-img-legs-1.png'
import reachImageLegs2 from 'assets/images/reach-img-legs-2.png'
import reachImageLegs3 from 'assets/images/reach-img-legs-3.png'

export const SLIDES_RESULT = [reachImage2, reachImage3, reachImage1]
export const SLIDES_RESULT_LEGS = [
  reachImageLegs1,
  reachImageLegs2,
  reachImageLegs3,
]

export const SUBSCRIPTION_PERIOD = 6

export const CUSTOMER_REVIEWS_SOMATIC = [
  {
    author: 'purchaseCancelOffer.customerReviews.firstAuthor',
    review: 'purchaseCancelOffer.customerReviewsSomatic.firstReview',
  },
  {
    author: 'purchaseCancelOffer.customerReviews.secondAuthor',
    review: 'purchaseCancelOffer.customerReviewsSomatic.secondReview',
  },
  {
    author: 'purchaseCancelOffer.customerReviews.thirdAuthor',
    review: 'purchaseCancelOffer.customerReviewsSomatic.thirdReview',
  },
]

export const SECRET_GIFT_COST = {
  [Currency.USD]: 19.99,
  [Currency.BRL]: 108.74,
  [Currency.MXN]: 338.23,
}
