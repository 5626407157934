import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectUserCountryCode } from 'root-redux/selects/user'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useVatInfo } from 'hooks/useVatInfo'

import { formatJaPriceWithCommas } from 'helpers/formatPriceWithCommas'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/purchase/redux/selects/common'

import { StyledSubscriptionsBlockTrial as S } from './SubscriptionsBlockTrial.styles'

export const SubscriptionsBlockTrial: React.FC = () => {
  const { t } = useTranslation()
  const currency = useSelector(selectCurrency)
  const trialPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const price = useSelector(selectSubscriptionFullPrice)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const country = useSelector(selectUserCountryCode)
  const hasVatInfo = useVatInfo()
  const { subscriptionBlockTitle } = useDynamicPaywallConfig()

  return (
    <S.Wrapper>
      <S.PriceTitle $gridArea="priceToday">
        {subscriptionBlockTitle || t`purchase3.subscriptionsBlock.priceToday`}
      </S.PriceTitle>
      <S.Text $gridArea="trialText">
        {t('purchase3.subscriptionsBlock.trialText', { trialPeriodDays })}
      </S.Text>
      <S.Text $gridArea="trialPrice" $textAlign="right">
        {t('purchase3.subscriptionsBlock.price', {
          price: formatJaPriceWithCommas(trialPrice, currency),
          currency: CURRENCY_SYMBOLS[currency],
        })}
      </S.Text>

      <S.Text $gridArea="trialPriceVatInfo" $textAlign="right">
        {hasVatInfo && (
          <S.VatInfo>{t('purchase1.inclVat', { context: country })}</S.VatInfo>
        )}
      </S.Text>

      <S.PriceTitle $gridArea="priceAfter">{t`purchase3.subscriptionsBlock.priceAfter`}</S.PriceTitle>
      <S.Text $gridArea="fullPriceText">
        {t('purchase3.subscriptionsBlock.fullPriceText', { periodQuantity })}
      </S.Text>
      <S.Text $gridArea="fullPrice" $textAlign="right">
        {t('purchase3.subscriptionsBlock.price', {
          price: formatJaPriceWithCommas(price, currency),
          currency: CURRENCY_SYMBOLS[currency],
        })}
      </S.Text>

      <S.Text $gridArea="fullPriceDiscount">
        <S.Discount>{t`purchase3.subscriptionsBlock.discount`}</S.Discount>
      </S.Text>
      <S.Text $gridArea="fullPriceVatInfo" $textAlign="right">
        {hasVatInfo && (
          <S.VatInfo>{t('purchase1.inclVat', { context: country })}</S.VatInfo>
        )}
      </S.Text>
    </S.Wrapper>
  )
}
