import React, { FC, useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Container } from 'components/Container'
import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { Glasses } from 'components/Glasses'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'
import { PageTitle } from 'components/PageTitle'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { DailyWaterStyles as S } from './DailyWater.styles'
import { QUESTION } from './constants'

export const DailyWater: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()

  const [selectedQuantity, setSelectedQuantity] = useState<string>('')

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(() => handleContinue(selectedQuantity), [
    handleContinue,
    selectedQuantity,
  ])

  return (
    <>
      <ContainerBeforePinnedButton>
        <Container isLarge>
          <PageTitle marginBottom={32}>
            <Trans i18nKey="onboarding.dailyWater.title" />
          </PageTitle>

          <S.GlassesContainer>
            <Glasses
              selectedQuantity={selectedQuantity}
              setSelectedQuantity={setSelectedQuantity}
            />
          </S.GlassesContainer>
        </Container>
      </ContainerBeforePinnedButton>

      <NavigationButtonBelly
        type="button"
        onClick={handleClick}
        disabled={!selectedQuantity}
      >
        {t('actions.continue')}
      </NavigationButtonBelly>
    </>
  )
}
