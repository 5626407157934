import likeIcon from 'assets/images/like-icon.svg'
import lovelyIcon from 'assets/images/lovely-icon.svg'
import sadIcon from 'assets/images/sad-icon.svg'

export const QUESTION = 'How satisfied are you with your current body image?'

export const enum SatisfactionLevel {
  COMPLETELY_SATISFIED = 'completelySatisfied',
  LOVE_BODY = 'loveBody',
  COMPLETELY_DISSATISFIED = 'completelyDissatisfied',
}

export const OPTION_VALUES = [
  {
    value: SatisfactionLevel.COMPLETELY_SATISFIED,
    text: 'onboarding.satisfied.completelySatisfied',
    iconSrc: lovelyIcon,
  },
  {
    value: SatisfactionLevel.LOVE_BODY,
    text: 'onboarding.satisfied.loveBody',
    iconSrc: likeIcon,
  },
  {
    value: SatisfactionLevel.COMPLETELY_DISSATISFIED,
    text: 'onboarding.satisfied.completelyDissatisfied',
    iconSrc: sadIcon,
  },
]
