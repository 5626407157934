import styled from 'styled-components'

import { Button } from 'components/Button'
import { GoalChart } from 'components/GoalChart'

import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'

import { Color } from 'root-constants'

type TTitleProps = {
  $gridArea: string
}

type TTextProps = TTitleProps & {
  $textAlign?: string
}
export const StyledPurchaseTrial = {
  Wrapper: styled.div`
    max-width: 360px;
    padding: 0 20px;
    margin: 0 auto;
  `,
  ButtonContainer: styled.div`
    margin-bottom: 40px;
  `,
  Button: styled(Button)`
    max-width: 320px;
  `,
  Chart: styled(GoalChart)`
    margin-bottom: 0;
  `,
  SecurityInfo: styled(SecurityInfo)`
    margin-top: 40px;
    border-radius: 24px;

    p {
      font-size: 12px;
    }
  `,
  CheckoutContainer: styled.div<{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  `,
  AnimationContainer: styled.div`
    position: relative;
    min-height: 220px;
    border-radius: 16px;
    background: ${Color.WHITE};
    overflow: hidden;
    padding-bottom: 20px;
    margin-bottom: 20px;
  `,
  UserRecommended: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 16px;
  `,
  Img: styled.img`
    margin-right: 8px;
    max-width: 92px;
  `,
  Text: styled.p`
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    color: ${Color.DISABLED};
  `,
  Title: styled.h2`
    color: ${Color.DARK};
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
  `,
  PricesWrapper: styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
      'priceToday priceToday'
      'trialText trialPrice'
      'fullPriceDiscount trialPriceVatInfo'
      'priceAfter priceAfter'
      'fullPriceText fullPrice'
      'fullPriceVatInfo fullPriceVatInfo';
    padding: 0 13px 40px;
  `,
  PriceTitle: styled.p<TTitleProps>`
    grid-area: ${({ $gridArea }) => $gridArea};
    padding-bottom: 16px;
    margin-bottom: 11px;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    border-bottom: 1px solid ${Color.LIGHT};

    &:last-of-type {
      padding-top: 16px;
      margin-top: 11px;
      border-top: 1px solid ${Color.LIGHT};
    }
  `,
  PriceText: styled.span<TTextProps>`
    grid-area: ${({ $gridArea }) => $gridArea};
    position: relative;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    min-height: 10px;
    text-align: ${({ $textAlign }) => $textAlign || 'left'};
    text-transform: capitalize;
  `,
  Discount: styled.div`
    padding: 4px 12px;
    margin-top: 8px;
    background-color: ${Color.PRIMARY};
    border-radius: 16px;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-align: center;
    color: ${Color.WHITE};
    width: fit-content;
  `,
  VatInfo: styled.span`
    text-align: right;
    color: #b4b9c0;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  `,
}
