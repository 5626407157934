import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Spinner } from 'components/Spinner'

import { resetErrorAction } from 'root-redux/actions/common'

import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useHasPayPalButton } from 'hooks/useHasPayPalButton'
import { useVatInfo } from 'hooks/useVatInfo'

import { createProductId } from 'helpers/createProductId'
import { formatJaPriceWithCommas } from 'helpers/formatPriceWithCommas'
import { getCalculatedPrice } from 'helpers/getCalculatedPrice'

import { CreditCardFormCancelOffer } from 'modules/purchase/components/CreditCardFormCancelOffer'
import { PayPalButton } from 'modules/purchase/components/PayPalButton'
import { PaymentRequestButton } from 'modules/purchase/components/PaymentRequestButton'
import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { Separator } from 'modules/purchase/components/Separator'
import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { logGeneralCheckoutEvents } from 'modules/purchase/helpers/logGeneralEvents'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'
import { selectTaxAmount } from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import securitySystems from 'assets/images/security-systems.png'

import { Cohort, ScreenName } from 'root-constants'

import { StyledCheckoutCancelOffer as S } from './CheckoutCancelOffer.styles'

type TProps = {
  setIsCheckoutShown: (value: boolean) => void
  isCheckoutShown: boolean
}

export const CheckoutCancelOffer: React.FC<TProps> = ({
  setIsCheckoutShown,
  isCheckoutShown,
}) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  const taxAmount = useSelector(selectTaxAmount)

  const creditCardPaymentRef = useRef<HTMLDivElement>(null)

  const {
    uuid,
    cohort,
    subscriptions,
    currency,
    fetchingActionsList,
    fullPrice,
    fullPriceTax,
    periodQuantity,
    oldPriceBeforeCustomPercentDiscount,
    periodName,
    screenName,
    screenId,
    threeDSecureIframeUrl,
    countryCode,
    stripeAccountId,
    stripeAccountName,
    selectedSubscription,
  } = usePurchaseStore()

  const hasExcludedVat = useVatInfo(true)
  const hasVatInfo = useVatInfo()
  const hasPayPalButton = useHasPayPalButton()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState(false)

  const productId = useMemo(
    () =>
      createProductId({
        periodName,
        periodQuantity,
        price: fullPrice,
      }),
    [periodName, periodQuantity, fullPrice],
  )

  const isCheckoutReady = useMemo(
    () => !fetchingActionsList.includes(CHECK_PAYMENT_REQUEST_BUTTON),
    [fetchingActionsList],
  )

  useAmplitudeInitialization(cohort as Cohort, ScreenName.CHECKOUT)

  useEffect(() => {
    if (productId && isCheckoutShown && selectedSubscription) {
      eventLogger.logPurchaseShown({
        accountName: stripeAccountName,
        accountId: stripeAccountId,
        productId,
        screenName,
        screenId,
      })

      logGeneralCheckoutEvents({
        uuid,
        subscriptions,
        selectedSubscription,
        isPersonalDataAllowed,
      })
    }
  }, [
    isPersonalDataAllowed,
    uuid,
    subscriptions,
    selectedSubscription,
    isCheckoutShown,
    stripeAccountId,
    stripeAccountName,
    productId,
    screenId,
    screenName,
  ])

  useEffect(() => {
    const { current: elem } = creditCardPaymentRef

    if (elem && isCheckoutShown && isCheckoutReady) {
      elem.scrollIntoView(true)
    }
  }, [isCheckoutReady, isCheckoutShown])

  const handleCloseCheckout = useCallback(() => {
    dispatch(resetErrorAction())
    setIsCheckoutShown(false)

    googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
    eventLogger.logPurchaseScreenClosed({
      productId,
      screenId,
      screenName,
    })
  }, [
    dispatch,
    setIsCheckoutShown,
    pathname,
    cohort,
    productId,
    screenId,
    screenName,
  ])

  const getCurrentPrice = useCallback(
    (value) =>
      hasVatInfo ? getCalculatedPrice(value, taxAmount, countryCode) : value,
    [countryCode, hasVatInfo, taxAmount],
  )

  return (
    <>
      {threeDSecureIframeUrl ? (
        <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
      ) : (
        <>
          <S.Wrapper $isVisible={isCheckoutReady}>
            <S.Title>{t`purchase1.checkout.title`}</S.Title>
            <S.CloseButton
              data-testid="close-btn"
              onClick={handleCloseCheckout}
            />
            <div>
              <S.DescriptionContainer>
                <S.PersonalizedPlan>
                  {t`purchaseCancelOfferMealPlan.checkout.mealPlan`}
                </S.PersonalizedPlan>
                <S.PlanPrice>
                  <Trans
                    i18nKey="purchase1.checkout.mealPlanPrice"
                    values={{
                      mealPlanPrice: 0,
                      currency: CURRENCY_SYMBOLS[currency],
                    }}
                  />
                </S.PlanPrice>
              </S.DescriptionContainer>
              <S.DescriptionContainer>
                <S.PersonalizedPlan>
                  <Trans
                    i18nKey="purchaseCancelOffer.checkout.personalizedPlan"
                    components={[<br />]}
                  />
                </S.PersonalizedPlan>
                <S.PlanPrice>
                  <Trans
                    i18nKey="purchase1.checkout.fullPrice"
                    values={{
                      fullPrice: formatJaPriceWithCommas(
                        getCurrentPrice(
                          oldPriceBeforeCustomPercentDiscount.fullPrice,
                        ),
                        currency,
                      ),
                      currency: CURRENCY_SYMBOLS[currency],
                    }}
                  />
                </S.PlanPrice>
              </S.DescriptionContainer>
              <S.DescriptionContainer>
                <span>{t`purchaseCancelOffer.checkout.offerDiscount`}</span>
                <S.Discount>
                  -
                  <Trans
                    i18nKey="purchase1.checkout.discountAmount"
                    values={{
                      discountAmount: formatJaPriceWithCommas(
                        getCurrentPrice(
                          oldPriceBeforeCustomPercentDiscount.amountOfDiscount,
                        ),
                        currency,
                      ),
                      currency: CURRENCY_SYMBOLS[currency],
                    }}
                  />
                </S.Discount>
              </S.DescriptionContainer>
            </div>

            <S.Separator />
            {hasExcludedVat && (
              <S.ValueAddedContainer>
                <span>
                  {t(`purchase1.valueAddedTax`, { context: countryCode })}
                </span>
                <span>
                  <Trans
                    i18nKey="purchaseIntroOffer.checkout.price"
                    values={{
                      price: formatJaPriceWithCommas(fullPriceTax, currency),
                      currency: CURRENCY_SYMBOLS[currency],
                    }}
                  />
                </span>
              </S.ValueAddedContainer>
            )}

            <S.TotalAmountContainer>
              <span>{t`purchaseCancelOffer.checkout.total`}</span>
              <span>
                <Trans
                  i18nKey="purchase1.checkout.price"
                  values={{
                    price: formatJaPriceWithCommas(fullPrice, currency),
                    currency: CURRENCY_SYMBOLS[currency],
                  }}
                />
              </span>
            </S.TotalAmountContainer>
            <S.DiscountDescription>
              {t('purchaseCancelOffer.checkout.discountDescription', {
                discount: formatJaPriceWithCommas(
                  getCurrentPrice(
                    oldPriceBeforeCustomPercentDiscount.amountOfDiscount,
                  ),
                  currency,
                ),
                percentDiscount: formatJaPriceWithCommas(
                  oldPriceBeforeCustomPercentDiscount.percentOfDiscount,
                  currency,
                ),
                currency: CURRENCY_SYMBOLS[currency],
              })}
            </S.DiscountDescription>
            <S.PaymentContainer>
              <div ref={creditCardPaymentRef}>
                {isCheckoutReady && <CreditCardFormCancelOffer />}
              </div>
              {hasPayPalButton && isCheckoutShown && (
                <>
                  <Separator />
                  <PayPalButton />
                </>
              )}
              <S.ButtonContainer>
                <PaymentRequestButton />
              </S.ButtonContainer>

              <S.Image src={securitySystems} alt="security-systems" />
              <S.Text>{t`purchase1.checkout.moneyBackGuarantee`}</S.Text>
            </S.PaymentContainer>
          </S.Wrapper>
          <PaymentWaitingModal
            isPaymentWaitingShown={isPaymentWaitingShown}
            setIsPaymentWaitingShown={setIsPaymentWaitingShown}
          />
          {!isCheckoutReady && <Spinner />}
        </>
      )}
    </>
  )
}
