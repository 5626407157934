import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AccountLink } from 'components/AccountLink'

import { formatJaPriceWithCommas } from 'helpers/formatPriceWithCommas'

import { Disclaimer } from 'modules/purchase/components/Disclaimer'
import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/purchase/redux/selects/common'

type TProps = {
  className?: string
}

export const IntroOfferDisclaimerV2: React.FC<TProps> = ({
  className = '',
}) => {
  const currency = useSelector(selectCurrency)
  const fullPrice = useSelector(selectSubscriptionFullPrice)
  const trialPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)

  const disclaimerText = useMemo(
    () => (
      <Trans
        i18nKey="purchaseWeeklyOffer.disclaimer"
        values={{
          trialDuration: Math.round(trialPeriodDays / 7),
          count: Math.round(trialPeriodDays / 7),
          fullPrice: formatJaPriceWithCommas(fullPrice, currency),
          trialPrice: formatJaPriceWithCommas(trialPrice, currency),
          periodDuration: Math.round(periodQuantity / 7),
          currency: CURRENCY_SYMBOLS[currency],
        }}
        components={{ linkTag: <AccountLink /> }}
      />
    ),
    [periodQuantity, trialPeriodDays, fullPrice, trialPrice, currency],
  )

  return <Disclaimer className={className} disclaimerText={disclaimerText} />
}
