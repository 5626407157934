import styled from 'styled-components'

import { ContainerBeforePinnedButton } from 'components/ContainerBeforePinnedButton'
import { NavigationButtonBelly } from 'components/NavigationButtonBelly'

import { Color } from 'root-constants'

export const StyledCancelOfferQuestion = {
  ImageContainer: styled.div`
    aspect-ratio: 375/176;
    margin-bottom: 24px;
  `,
  Image: styled.img`
    max-width: 100%;
    min-height: 202px;
    height: auto;
    vertical-align: top;
    margin-bottom: 12px;
  `,
  ImageDescription: styled.p`
    color: ${Color.GRAY_80};
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
  `,
  Title: styled.h1`
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.DARK};
    margin-bottom: 16px;
    margin-top: 16px;
    text-align: center;
  `,
  Subtitle: styled.h2`
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.DARK};
    margin-bottom: 24px;

    strong {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      color: ${Color.PRIMARY};
    }
  `,
  Text: styled.p`
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-bottom: 16px;

    strong {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      color: #ff2d55;
    }
  `,
  NavigationButton: styled(NavigationButtonBelly)`
    background: #f5f6f7;
  `,
  Container: styled(ContainerBeforePinnedButton)`
    background: #f5f6f7;
  `,
}
