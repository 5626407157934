import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { AccountLink } from 'components/AccountLink'
import { FortuneWheel } from 'components/FortuneWheel'
import { Spinner } from 'components/Spinner'
import { Ticker } from 'components/Ticker'

import {
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
  setScreenIdAction,
  setScreenNameAction,
  setSubscriptionListAction,
  startFetching,
} from 'root-redux/actions/common'
import { selectUserAvailableSpins } from 'root-redux/selects/user'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { usePaymentElementStatus } from 'hooks/usePaymentElementStatus'
import { useVatInfo } from 'hooks/useVatInfo'
import { useViewportValue } from 'hooks/useViewportValue'

import { formatJaPriceWithCommas } from 'helpers/formatPriceWithCommas'
import { getCalculatedPrice } from 'helpers/getCalculatedPrice'

import { FeaturedBlock } from 'modules/purchase/components/FeaturedBlock'
import { MoneyBackGuarantee } from 'modules/purchase/components/MoneyBackGuarantee'
import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { PhotoResultV3 } from 'modules/purchase/components/PhotoResultV3'
import {
  SLIDES_FEMALE,
  SLIDES_MALE,
} from 'modules/purchase/components/PhotoResultV3/constant'
import { ReachTarget } from 'modules/purchase/components/ReachTarget'
import { ReceivedPlan } from 'modules/purchase/components/ReceivedPlan'
import { Reviews } from 'modules/purchase/components/Reviews'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'
import { SubscriptionsBlockNewYear } from 'modules/purchase/components/SubscriptionsBlockNewYear'
import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { usePurchaseAnalytics } from 'modules/purchase/hooks/usePurchaseAnalytics'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  setSelectedSubscriptionAction,
} from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'

import {
  CUSTOMER_REVIEWS,
  ScreenId,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { CheckoutSeparateMethods } from '../CheckoutSeparateMethods'
import { StyledPurchaseWithoutIntro as S } from './PurchaseNewYear.styles'

export const PurchaseNewYear: FC = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const buttonWrapperElementRef = useRef<HTMLDivElement>(null)

  const availableSpins = useSelector(selectUserAvailableSpins)

  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState<boolean>(
    false,
  )
  const [isCheckoutShown, setIsCheckoutShown] = useState(false)

  const {
    subscriptions,
    fetchingActionsList,
    selectedSubscriptionId,
    currency,
    periodQuantity,
    countryCode,
    taxAmount,
    fullPrice,
  } = usePurchaseStore()

  const { buttonText } = useDynamicPaywallConfig()

  usePaymentElementStatus()

  const hasIncludedVat = useVatInfo()
  const hasExcludedVat = useVatInfo(true)

  const vatInfo = useMemo(
    () =>
      hasExcludedVat
        ? t('purchase1.excludingTaxes', { context: countryCode })
        : t('purchase1.includingTaxes', { context: countryCode }),
    [countryCode, hasExcludedVat, t],
  )

  usePurchaseAnalytics({
    screenName: ScreenName.ONBOARDING,
    screenId: ScreenId.BEFORE_AFTER_PHOTOS,
  })

  useViewportValue(ScreenName.ONBOARDING, !isCheckoutShown)

  const arePricesReady = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  const disclaimerText = useMemo(
    () => (
      <Trans
        i18nKey="purchaseNewYear.disclaimer_interval"
        values={{
          price: hasExcludedVat
            ? formatJaPriceWithCommas(
                getCalculatedPrice(fullPrice, taxAmount, countryCode),
                currency,
              )
            : formatJaPriceWithCommas(fullPrice, currency),
          currency: CURRENCY_SYMBOLS[currency],
          count: periodQuantity,
          inclVat: hasIncludedVat ? vatInfo : '',
          postProcess: 'interval',
        }}
        components={{ linkTag: <AccountLink /> }}
      />
    ),
    [
      hasExcludedVat,
      fullPrice,
      taxAmount,
      countryCode,
      currency,
      periodQuantity,
      hasIncludedVat,
      vatInfo,
    ],
  )

  useLayoutEffect(() => {
    let pricesTags: string = hasIncludedVat
      ? SubscriptionTags.TAX
      : SubscriptionTags.NO_TAX

    if (availableSpins === 1) {
      pricesTags = `${SubscriptionTags.DISCOUNT_50_OFF},${pricesTags}`
    }

    if (availableSpins === 0) {
      pricesTags = `${SubscriptionTags.DISCOUNT_65_OFF},${pricesTags}`
    }

    dispatch(setScreenNameAction(ScreenName.ONBOARDING))
    dispatch(setScreenIdAction(ScreenId.BEFORE_AFTER_PHOTOS))
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))

    dispatch(
      getSubscriptionListAction(SubscriptionListType.PURCHASE, pricesTags),
    )

    return () => {
      dispatch(setSubscriptionListAction([]))
      dispatch(setSelectedSubscriptionAction(null))
    }
  }, [availableSpins, dispatch, hasIncludedVat])

  useEffect(() => {
    const defaultSubscription = subscriptions.find(({ isDefault }) => isDefault)

    if (defaultSubscription) {
      dispatch(setSelectedSubscriptionAction(defaultSubscription))
    }
  }, [dispatch, subscriptions])

  useEffect(() => {
    const { current: elem } = buttonWrapperElementRef

    if (elem && availableSpins < 2 && subscriptions) {
      elem.scrollIntoView({
        block: 'end',
        behavior: 'smooth',
      })
    }
  }, [subscriptions, availableSpins])

  const handleShowPayment = useCallback((event) => {
    setIsCheckoutShown(true)

    eventLogger.logPlansPageButtonTap({
      screenName: ScreenName.ONBOARDING,
      buttonNumber: event.target.getAttribute('data-order'),
      buttonText: event.target.getAttribute('data-text'),
    })
  }, [])

  const Disclaimer = () => {
    if (availableSpins < 2) {
      return <S.SubscriptionIntroDisclaimer />
    }

    return <S.SubscriptionDisclaimer disclaimerText={disclaimerText} />
  }

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <>
      {!isCheckoutShown && (
        <div>
          <Ticker />

          <ReachTarget />
          <S.Wrapper isLarge>
            <ReceivedPlan />
          </S.Wrapper>

          <S.TimerSubheaderWithTimer
            $isBellyFlow
            elemForComparisonRef={buttonWrapperElementRef}
            onButtonClick={handleShowPayment}
            isInfluencerFlow
          />

          <FortuneWheel />

          <SubscriptionsBlockNewYear
            elemForComparisonRef={buttonWrapperElementRef}
            disclaimer={<Disclaimer />}
          >
            <S.Button
              type="button"
              data-order="2"
              data-text="get_my_plan"
              onClick={handleShowPayment}
            >
              {buttonText || t`actions.getMyPlan`}
            </S.Button>
          </SubscriptionsBlockNewYear>

          <S.Wrapper isLarge>
            <S.Container>
              <FeaturedBlock />
            </S.Container>
            <MoneyBackGuarantee />
            <PhotoResultV3
              femaleSlides={SLIDES_FEMALE}
              maleSlides={SLIDES_MALE}
            />
            <S.FAQList />
            <Reviews reviews={CUSTOMER_REVIEWS} />
            <S.PurchaseButton
              type="button"
              data-order="3"
              data-text="get_my_plan"
              onClick={handleShowPayment}
            >
              {buttonText || t`actions.getMyPlan`}
            </S.PurchaseButton>

            <Disclaimer />

            <SecurityInfo />
          </S.Wrapper>
        </div>
      )}

      <S.CheckoutContainer $isVisible={isCheckoutShown}>
        <CheckoutSeparateMethods
          key={selectedSubscriptionId}
          isCheckoutShown={isCheckoutShown}
          setIsCheckoutShown={setIsCheckoutShown}
        />
      </S.CheckoutContainer>

      {!isCheckoutShown && (
        <PaymentWaitingModal
          isPaymentWaitingShown={isPaymentWaitingShown}
          setIsPaymentWaitingShown={setIsPaymentWaitingShown}
        />
      )}
    </>
  )
}
