import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import { DynamicTimer } from 'modules/purchase/components/DynamicTimer'

import blueCheck from 'assets/images/check-icon-rounded.svg'

import { Color } from 'root-constants'

export const StyledReceivedPlan = {
  ProgramPlanContainer: styled.div<{ $hasBorder?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 16px;
    border: 1px solid ${Color.GRAY_10};
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px 0 #e8e8e8;
    padding: 16px;
    margin-bottom: 50px;
  `,
  GrabPlanTitle: styled.h2`
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 16px;

    strong {
      color: #ff7171;
    }
  `,
  ProgramPlanTitle: styled.h2`
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    text-align: center;
  `,
  ListItem: styled.li`
    position: relative;
    margin-bottom: 12px;
    padding-left: 30px;
    font-size: 14px;
    line-height: 18px;
    text-align: left;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 4px;
      left: 0;
      display: block;
      width: 20px;
      height: 20px;
      color: ${Color.WHITE};
      background: url(${blueCheck}) center center no-repeat;
      background-size: contain;
    }
  `,
  Border: styled(SvgImage)`
    margin: 16px 0;
  `,
  DynamicTimer: styled(DynamicTimer)`
    margin-bottom: 16px;
  `,
}
