import { SatisfactionLevel } from 'pages/questions/Satisfied/constants'

import imageCompletelyDissatisfied from 'assets/images/fix-together.png'
import imageLoveBody from 'assets/images/greate-start.png'
import imageCompletelySatisfied from 'assets/images/your-amazing.png'

export const QUESTION = 'Supportive Message'

export const OPTION_VALUES = {
  [SatisfactionLevel.COMPLETELY_SATISFIED]: {
    image: imageCompletelySatisfied,
    background:
      'linear-gradient(180deg, rgba(230, 240, 254, 0.00) 0%, rgba(230, 240, 254, 0.60) 14.18%, #E6F0FE 26.75%, #E6F0FE 77.22%);',
  },
  [SatisfactionLevel.LOVE_BODY]: {
    image: imageLoveBody,
    background:
      'linear-gradient(180deg, rgba(255, 242, 254, 0.00) 0%, rgba(255, 242, 254, 0.76) 21.79%, #FFF2FE 38.56%, #FFF2FE 77.22%);',
  },
  [SatisfactionLevel.COMPLETELY_DISSATISFIED]: {
    image: imageCompletelyDissatisfied,
    background:
      'linear-gradient(180deg, rgba(255, 243, 251, 0.00) 0%, #FFF3FB 27.09%, #FFF3FB 48.26%, #FFF3FB 77.22%);',
  },
}
