import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { AnimationItem } from 'lottie-web'
import lottie from 'lottie-web/build/player/lottie_light'

import { CongratsModal } from 'components/CongratsModal'

import {
  sendUserAnswersAction,
  updateUserConfigAction,
} from 'root-redux/actions/user'
import { selectUserAvailableSpins } from 'root-redux/selects/user'

import { eventLogger } from 'services/eventLogger.service'

import confetti from 'assets/animation/confetti.json'
import fortuneWheelFlow from 'assets/animation/fortuneWheelFlow.json'
import confettiAudio from 'assets/sounds/confetti.mp3'
import failAudio from 'assets/sounds/fail.mp3'
import wheelAudio from 'assets/sounds/wheel-spin.mp3'

import { StyledFortuneWheel as S } from './FortuneWheel.styles'
import {
  ATTEMPT_NUMBERS,
  AUDIO_START_POINT,
  GAME_STEP,
  WHEEL_ANIMATION_SEGMENTS,
  WHEEL_ANIMATION_START_POINTS,
} from './constants'

export const FortuneWheel: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const availableSpins = useSelector(selectUserAvailableSpins)

  const wheelAnimationRef = useRef<HTMLDivElement>(null)
  const confettiAnimationRef = useRef<HTMLDivElement>(null)
  const counterRef = useRef(availableSpins)

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isAnimationInProgress, setIsAnimationInProgress] = useState(false)
  const [wheelAnimation, setWheelAnimation] = useState<AnimationItem | null>(
    null,
  )

  const wheelSound = useMemo(() => new Audio(wheelAudio), [])
  const confettiSound = useMemo(() => new Audio(confettiAudio), [])
  const failSound = useMemo(() => new Audio(failAudio), [])

  const [
    confettiAnimation,
    setConfettiAnimation,
  ] = useState<AnimationItem | null>(null)

  const handleCompleteWheelAnimation = useCallback(() => {
    confettiSound.play()
    confettiAnimation?.show()
    confettiAnimation?.play()

    counterRef.current -= GAME_STEP
  }, [confettiSound, confettiAnimation])

  useEffect(() => {
    if (wheelAnimationRef.current) {
      const wheelAnimationInstance = lottie.loadAnimation({
        container: wheelAnimationRef.current,
        animationData: fortuneWheelFlow,
        name: 'wheel',
        autoplay: false,
        loop: false,
      })

      setWheelAnimation(wheelAnimationInstance)
      wheelAnimationInstance.goToAndStop(
        WHEEL_ANIMATION_START_POINTS[counterRef.current],
        true,
      )
      wheelAnimationInstance.addEventListener(
        'complete',
        handleCompleteWheelAnimation,
      )
    }

    return () => lottie.destroy('wheel')
  }, [handleCompleteWheelAnimation])

  useEffect(() => {
    if (confettiAnimationRef.current) {
      const confettiAnimationInstance = lottie.loadAnimation({
        container: confettiAnimationRef.current,
        animationData: confetti,
        name: 'confetti',
        autoplay: false,
        loop: false,
      })

      setConfettiAnimation(confettiAnimationInstance)
      confettiAnimationInstance.hide()

      confettiAnimationInstance.addEventListener('complete', () =>
        setIsModalVisible(true),
      )
    }

    return () => lottie.destroy('confetti')
  }, [])

  const resetSounds = useCallback(() => {
    confettiSound.pause()
    failSound.pause()
    confettiSound.currentTime = AUDIO_START_POINT
    failSound.currentTime = AUDIO_START_POINT
  }, [confettiSound, failSound])

  const handleSpin = useCallback(() => {
    setIsAnimationInProgress(true)
    wheelAnimation?.playSegments(
      WHEEL_ANIMATION_SEGMENTS[counterRef.current],
      true,
    )
    wheelSound.play()
    resetSounds()

    eventLogger.logSpinAndWinClicked(ATTEMPT_NUMBERS[availableSpins])
  }, [availableSpins, wheelAnimation, wheelSound, resetSounds])

  const handleCloseModal = () => {
    dispatch(updateUserConfigAction({ availableSpins: counterRef.current }))
    dispatch(sendUserAnswersAction())
    setIsModalVisible(false)
  }

  return (
    <>
      <S.Wrapper>
        <S.Title>
          <Trans i18nKey="purchaseNewYear.title" />
        </S.Title>
        <S.AnimationContainer>
          <S.WheelAnimation ref={wheelAnimationRef} />
          <S.SpinButton
            onClick={handleSpin}
            disabled={isAnimationInProgress || !availableSpins}
          >
            <S.SpinButtonText>{t`purchaseNewYear.spinAndWin`}</S.SpinButtonText>
            <br />
            <S.SpinButtonAttempts>
              {t('purchaseNewYear.attempts', {
                count: availableSpins,
              })}
            </S.SpinButtonAttempts>
          </S.SpinButton>
          <S.ConfettiAnimation ref={confettiAnimationRef} />
        </S.AnimationContainer>
      </S.Wrapper>
      <CongratsModal isShown={isModalVisible} onClose={handleCloseModal} />
    </>
  )
}
