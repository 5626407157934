import styled, { css } from 'styled-components'

import stars from 'assets/images/gift-stars.svg'

import { Color } from 'root-constants'

const commonGiftCardStyles = css`
  border-radius: 40px;
  margin-bottom: 16px;
  min-height: 350px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;

  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 12px;
  }
`
export const StyledSecretGiftCards = {
  Wrapper: styled.div`
    margin-bottom: 50px;
  `,
  SecretGift: styled.div`
    position: relative;
    background: radial-gradient(
      155.58% 145.97% at -9.76% -13.02%,
      #fcd8e5 0%,
      #fdf2ff 47.52%,
      #d6d9f9 76.75%,
      #bbbaff 100%
    );
    padding: 24px 20px 0;
    ${commonGiftCardStyles};

    h2 {
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(91deg, #ff5343 29.31%, #e1226c 70.19%);
    }
  `,
  RiskFree: styled.div`
    background: radial-gradient(
      155.58% 145.97% at -9.76% -13.02%,
      rgba(252, 216, 229, 0.5) 0%,
      rgba(253, 242, 255, 0.5) 47.52%,
      rgba(214, 217, 249, 0.5) 76.75%,
      rgba(187, 186, 255, 0.5) 100%
    );
    padding: 24px 20px 18px;
    ${commonGiftCardStyles};

    a {
      ${commonGiftCardStyles};
      color: ${Color.DARK};
      text-decoration: underline;
      cursor: pointer;
    }

    h2 {
      color: ${Color.DARK};
    }
  `,
  GiftBlockTitle: styled.h3`
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin: 12px 0 24px;
  `,
  RiskFreeImg: styled.img`
    width: 180px;
    height: 180px;
    margin: 12px auto 0;
  `,
  GiftImage: styled.div`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin: 18px auto 0;
    height: 192px;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      height: 192px;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      background: url(${stars}) center no-repeat;
      background-size: contain;
      z-index: 2;
    }
  `,
}
