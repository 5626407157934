import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { Option, OptionType } from 'components/Option'

import {
  GET_CART_LIST,
  getSubscriptionWithCartAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import { selectSubscriptionList } from 'root-redux/selects/common'
import { selectUserCart } from 'root-redux/selects/user'

import {
  DEFAULT_INTRO_OFFER_SUBSCRIPTION_ITEM,
  useDynamicPaywallConfig,
} from 'hooks/useDynamicPaywallConfig'

import { getAxonItem } from 'helpers/getAxonItem'

import { SUBSCRIPTION_ITEMS } from 'modules/purchase/constants'
import { useCohortInfo } from 'modules/purchase/hooks/useCohortInfo'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import {
  selectSubscription,
  selectSubscriptionId,
  selectSubscriptionPlanId,
} from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { GROWTHBOOK_EXPERIMENT, SubscriptionTags } from 'root-constants'

import { CartBlock } from '../CartBlock'
import { StyledSubscriptionsBlockIntroOffer as S } from './SubscriptionsBlockIntroOffer.styles'

export type TThemeProps = {
  clearBackground?: boolean
  brightTheme?: boolean
}

type TProps = {
  elemForComparisonRef?: React.RefObject<HTMLDivElement>
  isCancelOffer?: boolean
  disclaimerVisible?: boolean
  children: React.ReactNode
  customTitle?: React.ReactNode
  withTitle?: boolean
  className?: string
}

export const SubscriptionsBlockIntroOffer: React.FC<TProps & TThemeProps> = ({
  elemForComparisonRef,
  isCancelOffer = false,
  disclaimerVisible = true,
  clearBackground = false,
  children,
  brightTheme = false,
  customTitle,
  withTitle = true,
  className = '',
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const subscriptions = useSelector(selectSubscriptionList)
  const selectedSubscription = useSelector(selectSubscription)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)
  const userCart = useSelector(selectUserCart)
  const selectedSubscriptionPlanId = useSelector(selectSubscriptionPlanId)

  const hasObUpsellTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_992)

  const { isBellyFlow } = useCohortInfo()

  const {
    subscriptionBlockTitle,
    subscriptionBlockType = DEFAULT_INTRO_OFFER_SUBSCRIPTION_ITEM,
  } = useDynamicPaywallConfig()

  const SubscriptionItem = SUBSCRIPTION_ITEMS[subscriptionBlockType]

  const tagForCartProducts = `${SubscriptionTags.NO_TAX},${SubscriptionTags.CART}`

  useEffect(() => {
    selectedSubscription &&
      window.axon &&
      window.axon('track', 'view_item', {
        value:
          selectedSubscription?.trialPrices?.fullPrice ||
          selectedSubscription?.mainPrices.fullPrice,
        currency: selectedSubscription?.currency?.toUpperCase(),
        items: getAxonItem(subscriptions),
      })
  }, [selectedSubscription, subscriptions])

  useEffect(() => {
    if (selectedSubscriptionPlanId && hasObUpsellTest) {
      dispatch(startFetching(GET_CART_LIST))

      dispatch(
        getSubscriptionWithCartAction(
          selectedSubscriptionPlanId,
          tagForCartProducts,
        ),
      )
    }

    if (!hasObUpsellTest) {
      dispatch(stopFetching(GET_CART_LIST))
    }
  }, [
    dispatch,
    hasObUpsellTest,
    selectedSubscriptionPlanId,
    tagForCartProducts,
  ])

  const handleChange = useCallback(
    (value) => {
      const checkedSubscription = subscriptions.find(({ id }) => id === value)
      dispatch(
        setSelectedSubscriptionAction(checkedSubscription as ISubscription),
      )
    },
    [dispatch, subscriptions],
  )

  return (
    <S.Wrapper
      className={className}
      $clearBackground={clearBackground}
      $brightTheme={brightTheme}
      $isBellyFlow={isBellyFlow}
    >
      {(subscriptionBlockTitle && (
        <S.Title>{subscriptionBlockTitle}</S.Title>
      )) ||
        (withTitle && customTitle) ||
        (withTitle && <S.Title>{t`purchase1.subscription.title`}</S.Title>)}
      {subscriptions.map((subscription) => (
        <Option
          type={OptionType.RADIO}
          name="subscription"
          value={subscription.id}
          key={subscription.planId}
          onChange={handleChange}
          withoutMargin
        >
          <SubscriptionItem
            subscription={subscription}
            isClearBackground={clearBackground}
            isSelected={subscription.id === selectedSubscriptionId}
            isCancelOffer={isCancelOffer}
          />
        </Option>
      ))}

      {hasObUpsellTest && userCart.length > 0 && <CartBlock />}
      <S.ButtonContainer ref={elemForComparisonRef} $isBellyFlow={isBellyFlow}>
        {children}
      </S.ButtonContainer>

      {hasObUpsellTest && userCart.length > 0 && <S.CartDisclaimer />}
      {disclaimerVisible && <S.SubscriptionDisclaimer />}
    </S.Wrapper>
  )
}
