import { getStripePurchaseCommonEventParams } from 'modules/purchase/helpers/getStripePurchaseCommonEventParams'

import { IAppState } from 'models/store.model'

export const getStripePurchaseStartedEventParams = (state: IAppState) => {
  const {
    productName,
    productId,
    fullPrice,
    trialPriceId,
    currency,
    screenName,
    screenId,
    stripePriceId,
    accountId,
    accountName,
    paymentSystem,
    isPersonalDataAllowed,
    userName,
    upsellProducts,
  } = getStripePurchaseCommonEventParams(state)

  return {
    productName,
    productId: trialPriceId ? productId : stripePriceId,
    priceDetails: {
      price: fullPrice,
      trial: !!trialPriceId,
      currency,
    },
    screenName,
    screenId,
    accountId,
    accountName,
    paymentSystem,
    isPersonalDataAllowed,
    userName,
    upsellProducts,
  }
}
