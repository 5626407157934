import React, { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AccountLink } from 'components/AccountLink'

import { selectUserCart, selectUserCountryCode } from 'root-redux/selects/user'

import { useVatInfo } from 'hooks/useVatInfo'

import { formatJaPriceWithCommas } from 'helpers/formatPriceWithCommas'
import { getCalculatedPrice } from 'helpers/getCalculatedPrice'

import {
  CURRENCY_SYMBOLS,
  TRIAL_ONE_MONTH_DURATION,
} from 'modules/purchase/constants'
import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
  selectTaxAmount,
} from 'modules/purchase/redux/selects/common'

import { SEVEN_DAY_TRIAL_DURATION, UpsellId } from 'root-constants'

import { Disclaimer } from '../Disclaimer'

export const CartDisclaimer: FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation()

  const currency = useSelector(selectCurrency)
  const currentPrice = useSelector(selectSubscriptionFullPrice)
  const trialPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const userCart = useSelector(selectUserCart)
  const taxAmount = useSelector(selectTaxAmount)
  const country = useSelector(selectUserCountryCode)

  const hasVatInfo = useVatInfo()
  const hasExcludedVat = useVatInfo(true)

  const disclaimerPath = (() => {
    if (trialPeriodDays === Number(SEVEN_DAY_TRIAL_DURATION)) {
      return 'purchaseWithCart.disclaimer_week'
    }

    return 'purchaseWithCart.disclaimer'
  })()

  const vatInfo = (() =>
    hasExcludedVat
      ? t('purchase1.excludingTaxes', { context: country })
      : t('purchase1.includingTaxes', { context: country }))()

  const productsInCart = (() => {
    if (userCart.length === 2) {
      const [superchargeYourself, ultimateToningPilates] = userCart

      return t('purchaseWithCart.bothUpsell', {
        superchargeYourself,
        ultimateToningPilates,
      })
    }

    const [upsellProduct] = userCart

    if (upsellProduct.product === UpsellId.SUPERCHARGE_YOURSELF) {
      return t('purchaseWithCart.superchargeUpsell', { upsellProduct })
    }

    return t('purchaseWithCart.toningUpsell', { upsellProduct })
  })()

  const disclaimerText = (
    <Trans
      i18nKey={disclaimerPath}
      values={{
        month: t('purchaseIntroOffer.month_interval', {
          postProcess: 'interval',
          count: periodQuantity,
        }),
        count: periodQuantity,
        trialCount: Math.round(trialPeriodDays / TRIAL_ONE_MONTH_DURATION),
        fullPrice: hasExcludedVat
          ? formatJaPriceWithCommas(
              getCalculatedPrice(currentPrice, taxAmount, country),
              currency,
            )
          : formatJaPriceWithCommas(currentPrice, currency),
        trialPrice: hasExcludedVat
          ? formatJaPriceWithCommas(
              getCalculatedPrice(trialPrice, taxAmount, country),
              currency,
            )
          : formatJaPriceWithCommas(trialPrice, currency),
        currency: CURRENCY_SYMBOLS[currency],
        upsellProducts: productsInCart,
        inclVat: hasVatInfo ? vatInfo : '',
      }}
      components={{ linkTag: <AccountLink /> }}
      tOptions={{ postProcess: 'interval' }}
    />
  )

  return <Disclaimer className={className} disclaimerText={disclaimerText} />
}
