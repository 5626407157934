import styled, { css } from 'styled-components'

import checkIcon from 'assets/images/check-white.svg'
import giftIcon from 'assets/images/gift-icon.png'
import priceBadgeUrl from 'assets/images/price-badge-wide.svg'
import selectedPriceBadgeCancelOfferUrl from 'assets/images/selected-price-badge-cancel-offer.svg'
import selectedPriceBadgeUrl from 'assets/images/selected-price-badge-wide.svg'

import { Color, MediaBreakpoint } from 'root-constants'

type TProps = {
  $isSelected: boolean
  $isCancelOffer: boolean
  $isBellyFlow: boolean
}

type TWrapperProps = {
  $isSelected: boolean
  $isCancelOffer: boolean
  $isClearBackground: boolean
}

type TPriceProps = { $hasSmallPrices: boolean; $isBellyFlow: boolean }

const commonPriceStyles = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
`

const crossedPriceStyles = css<{ $isBellyFlow: boolean }>`
  color: ${({ $isBellyFlow }) =>
    $isBellyFlow ? `${Color.GRAY_80}` : 'rgba(0, 0, 0, 0.3)'};
  text-decoration: line-through;
  text-decoration-color: ${Color.RED};
`

export const StyledSubscriptionItemIntroOffer = {
  Wrapper: styled.div<TWrapperProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 8px 0;
    border-radius: 16px;
    background: ${Color.WHITE};

    ${({ $isClearBackground, $isCancelOffer, $isSelected }) =>
      $isClearBackground &&
      !$isCancelOffer &&
      `
        border: ${
          $isSelected
            ? `1px solid ${Color.PRIMARY}`
            : `1px solid ${Color.GRAY_40}`
        };
      `};

    ${({ $isClearBackground, $isCancelOffer, $isSelected }) =>
      $isClearBackground &&
      $isCancelOffer &&
      `
        border: ${
          $isSelected ? `1px solid #b1446b` : `1px solid ${Color.GRAY_40}`
        };
      `};
  `,
  Content: styled.div<TProps>`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:after {
      content: '';
      width: 20px;
      height: 20px;
      mix-blend-mode: normal;
      border: 2px solid #e3e4e8;
      border-radius: 8px;
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
      transition: background-color 0.3s ease-out;
      ${({ $isSelected, $isCancelOffer }) =>
        $isSelected &&
        `
          background-image: url(${checkIcon});
          background-position: center center;
          background-repeat: no-repeat;
          background-color: ${$isCancelOffer ? '#b1446b' : Color.PRIMARY};
          border: none;
        `};
    }
  `,
  MostPopularBadge: styled.div`
    position: absolute;
    top: -10px;
    left: 55px;
    transform: translateY(-50%);
    padding: 4px 12px;
    border-radius: 16px;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    background-color: ${Color.PRIMARY};
    color: ${Color.WHITE};
  `,
  PlanContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding-left: 42px;
  `,
  PlanPeriod: styled.span<{ $isBellyFlow: boolean }>`
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: ${({ $isBellyFlow }) =>
      $isBellyFlow ? `${Color.GRAY_80}` : 'rgba(19, 29, 48, 0.5)'};
    padding-bottom: 2px;

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      font-size: 16px;
    }
  `,
  PriceContainer: styled.div`
    display: flex;
    align-items: center;
    width: 100%;
  `,
  CustomPreviousPrice: styled.span<{
    $isBellyFlow: boolean
  }>`
    ${crossedPriceStyles};
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  `,
  PreviousPrice: styled.span<TPriceProps & { $isClearFlow: boolean }>`
    ${commonPriceStyles};
    ${crossedPriceStyles};
    padding-right: 6px;
    ${({ $hasSmallPrices }) =>
      $hasSmallPrices &&
      `
        font-size: 12px;
    `};

    ${({ $isClearFlow }) =>
      $isClearFlow &&
      `
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
    `};
  `,
  CurrentPrice: styled.span<TPriceProps>`
    ${commonPriceStyles};
    color: ${({ $isBellyFlow }) =>
      $isBellyFlow ? `${Color.GRAY_80}` : `${Color.LIGHT_GREY}`};
    ${({ $hasSmallPrices }) =>
      $hasSmallPrices &&
      `
        font-size: 12px;
    `};
  `,
  CustomPrice: styled.p<TProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    min-width: 88px;
    min-height: 57px;
    margin-right: 8px;
    padding: 8px 4px;
    color: ${({ $isBellyFlow }) =>
      $isBellyFlow ? `${Color.GRAY_80}` : 'rgba(19, 29, 48, 0.5)'};
    transition: background 0.3s ease-out;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${priceBadgeUrl});
    background-size: 100%;

    ${({ $isSelected, $isCancelOffer }) =>
      $isSelected &&
      `
        background-image: url(${
          $isCancelOffer
            ? selectedPriceBadgeCancelOfferUrl
            : selectedPriceBadgeUrl
        });
        color: #ffffff;
        
        & > span:first-child {
          color: rgba(255, 255, 255, 0.5);
        }
    
    `};
  `,
  PriceValue: styled.span<{ $isClearFlow: boolean }>`
    font-weight: 700;
    font-size: 15px;
    line-height: 14px;
    padding: 2px 0;

    ${({ $isClearFlow }) =>
      $isClearFlow &&
      `
        font-size: 14px;
    `};
  `,
  Period: styled.span`
    margin-right: 5px;
    font-weight: 700;
    font-size: 8px;
    line-height: 8px;
  `,
  SecretGiftIcon: styled.div`
    position: relative;
    width: 60px;
    height: 24px;
    border-radius: 28px;
    background: ${Color.WHITE};

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 38px;
      height: 35px;
      background: url(${giftIcon}) center center no-repeat;
      background-size: contain;
    }
  `,
  SecretGiftText: styled.p`
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    background: linear-gradient(91deg, #ff5343 29.31%, #e1226c 70.19%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `,
  SecretGiftContainer: styled.div`
    display: flex;
    max-width: 203px;
    padding: 6px 16px 6px 8px;
    margin: 8px 16px;
    align-items: center;
    gap: 8px;
    border-radius: 45px;
    background: radial-gradient(
      666% 273.39% at -13.05% -70.83%,
      #fcd8e5 0%,
      #fbecff 46.79%,
      #d6d9f9 85.32%,
      #bbbaff 100%
    );
  `,
}
