import styled from 'styled-components'

import { Container } from 'components/Container'

import blueCheck from 'assets/images/check-icon-rounded.svg'
import circle from 'assets/images/circle-yellow.svg'
import courseDescriptionBg from 'assets/images/purchase-trial-bg.png'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledCourseDescription = {
  Wrapper: styled.div`
    position: relative;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    min-height: 344px;
    margin: 0 auto 78px;
    padding: 12px 0 0;
    background: url(${courseDescriptionBg}) no-repeat center;
    background-size: 100% 100%;

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
  Course: styled.p`
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 24px;
    color: ${Color.WHITE};

    & > span {
      font-family: 'Caveat', cursive;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      color: ${Color.WHITE};
      text-transform: capitalize;
    }
  `,
  ReachTarget: styled.p`
    color: ${Color.WHITE};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-align: center;

    & > strong {
      text-transform: none;
      color: ${Color.WHITE};
      font-size: 24px;
      font-weight: 700;
      line-height: 40px;
    }

    & > span {
      position: relative;
      text-transform: none;
      color: #ffe065;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;

      &::before {
        content: '';
        position: absolute;
        top: -10px;
        left: -10px;
        width: 75px;
        height: 54px;
        display: inline-block;
        background: url(${circle}) center no-repeat;
        background-size: contain;
      }
    }
  `,
  GoalContainer: styled(Container)`
    margin-bottom: 24px;
  `,
  Goal: styled.p`
    position: relative;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-left: 30px;
    margin-bottom: 12px;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      width: 20px;
      height: 20px;
      color: ${Color.WHITE};
      background: url(${blueCheck}) center no-repeat;
      background-size: contain;
    }
  `,
  CarouselContainer: styled.div`
    position: absolute;
    bottom: -56px;
    left: 50%;
    transform: translateX(-50%);
    width: 273px;
  `,
  Image: styled.img`
    min-height: 210px;
    width: 273px;
  `,
}
