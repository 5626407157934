import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AccountLink } from 'components/AccountLink'
import { ContactWithSupport } from 'components/ContactWithSupport'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { selectUserCountryCode } from 'root-redux/selects/user'

import { useVatInfo } from 'hooks/useVatInfo'

import { formatJaPriceWithCommas } from 'helpers/formatPriceWithCommas'
import { getCalculatedPrice } from 'helpers/getCalculatedPrice'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodPrice,
  selectTaxAmount,
} from 'modules/purchase/redux/selects/common'

import featuredImagePng from 'assets/images/featured.png'
import featuredImageWebp from 'assets/images/featured.webp'

import { PagesSource } from 'root-constants'

import { StyledFeaturedBlockV2 as S } from './FeaturedBlockV2.styles'

export const FeaturedBlockV2: React.FC = () => {
  const { t } = useTranslation()
  const currency = useSelector(selectCurrency)
  const country = useSelector(selectUserCountryCode)
  const price = useSelector(selectSubscriptionFullPrice)
  const trialPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const taxAmount = useSelector(selectTaxAmount)
  const hasVatInfo = useVatInfo()
  const hasExcludedVat = useVatInfo(true)

  const vatInfo = useMemo(
    () =>
      hasExcludedVat
        ? t('purchase1.excludingTaxes', { context: country })
        : t('purchase1.includingTaxes', { context: country }),
    [country, hasExcludedVat, t],
  )

  const getCurrentPrice = useCallback(
    (value?: number | string) =>
      hasVatInfo && value
        ? getCalculatedPrice(value, taxAmount, country)
        : value || '',

    [country, hasVatInfo, taxAmount],
  )

  return (
    <S.Container>
      <S.Title>{t`purchase1.featuredBlockTitle`}</S.Title>
      <picture>
        <source srcSet={featuredImageWebp} type="image/webp" />
        <img src={featuredImagePng} alt="body" />
      </picture>
      <S.Disclaimer>
        <Trans
          i18nKey="purchaseIntroOffer.flexibleWeeklyDisclaimer"
          values={{
            month: t('purchaseIntroOffer.month_interval', {
              postProcess: 'interval',
              count: periodQuantity,
            }),
            price: formatJaPriceWithCommas(getCurrentPrice(price), currency),
            count: 1,
            trialPrice: formatJaPriceWithCommas(
              getCurrentPrice(trialPrice),
              currency,
            ),
            currency: CURRENCY_SYMBOLS[currency],
            inclVat: hasVatInfo ? vatInfo : '',
          }}
          components={{ linkTag: <AccountLink /> }}
        />
        <TermsOfUseLink source={PagesSource.PAYWALL} />{' '}
        {t('commonComponents.or')}{' '}
        <ContactWithSupport
          source={PagesSource.PAYWALL}
          text={t('commonComponents.contactSupportDisclaimer')}
        />
      </S.Disclaimer>
    </S.Container>
  )
}
