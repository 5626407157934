import styled from 'styled-components'

import { Button } from 'components/Button'

type TStyledModalProps = {
  $isShown: boolean
}

export const StyledModal = {
  Wrapper: styled.div<TStyledModalProps>`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ $isShown }) => ($isShown ? 10 : -1)};
    width: max(100%, var(--min-screen-width));
    height: calc(var(--full-height) - calc(var(--full-height) - 100%));
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.25);
    opacity: ${({ $isShown }) => ($isShown ? 1 : 0)};
    transition-duration: 250ms;
    transition-property: opacity;
    transition-timing-function: ease-out;
  `,
  Content: styled.div`
    width: calc(100% - 40px);
    max-width: 360px;
    min-height: 358px;
    margin: auto;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-radius: 40px;
    background: radial-gradient(
      99.56% 95.21% at 27.06% 53.57%,
      #fffdea 0%,
      #cbefff 50.99%,
      #a4ceff 94.36%
    );
  `,
  Title: styled.h2`
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 4px;
  `,
  Text: styled.p<{ $isShown: boolean }>`
    color: #6d717a;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    opacity: ${({ $isShown }) => ($isShown ? 1 : 0)};
    transition-duration: 500ms;
    transition-property: opacity;
    transition-timing-function: ease-out;
    transition-delay: 1.5s;
  `,
  Button: styled(Button)`
    border-radius: 30px;
    background: linear-gradient(
      90deg,
      #ffa33a 0%,
      #ff321f 49.22%,
      #c91100 100%
    );
  `,
  ButtonContainer: styled.div<{ $isShown: boolean }>`
    width: 100%;
    margin-top: 30px;
    border-radius: 30px;
    opacity: ${({ $isShown }) => ($isShown ? 1 : 0)};
    transition-duration: 500ms;
    transition-property: opacity;
    transition-timing-function: ease-out;
    transition-delay: 1.5s;
  `,
  Error: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 24px;
  `,
  CookieAnimation: styled.div``,
}
