import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SvgImage } from 'components/SvgImage'

import {
  selectLanguage,
  selectSubscriptionList,
} from 'root-redux/selects/common'
import { selectUserCountryCode } from 'root-redux/selects/user'

import { formatJaPriceWithCommas } from 'helpers/formatPriceWithCommas'
import { getAxonItem } from 'helpers/getAxonItem'
import { getCalculatedPrice } from 'helpers/getCalculatedPrice'

import {
  CURRENCY_SYMBOLS,
  Currency,
  DECORATING_DISCOUNT,
  DEFAULT_DYNAMIC_DISCOUNT,
  ONE_HUNDRED_PERCENTS,
} from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import {
  selectCurrency,
  selectDynamicDiscount,
  selectSubscription,
  selectTaxAmount,
} from 'modules/purchase/redux/selects/common'

import calendarIcon from 'assets/images/sprite/flat-calendar.svg'
import markdownIcon from 'assets/images/sprite/green-flat-markdown.svg'

import { Locale } from 'root-constants'

import { StyledSubscriptionBlockWithDescription as S } from './SubscriptionBlockWithDescription.styles'

type TProps = {
  children: React.ReactNode
}

export const SubscriptionBlockWithDescription: FC<TProps> = ({ children }) => {
  const subscriptions = useSelector(selectSubscriptionList)
  const selectedSubscription = useSelector(selectSubscription)
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const taxAmount = useSelector(selectTaxAmount)
  const country = useSelector(selectUserCountryCode)
  const currency = useSelector(selectCurrency)
  const lang = useSelector(selectLanguage)

  const { trialPrice, fullPrice, discountPercentage } = usePurchaseStore()

  useEffect(() => {
    selectedSubscription &&
      window.axon &&
      window.axon('track', 'view_item', {
        value:
          selectedSubscription?.trialPrices?.fullPrice ||
          selectedSubscription?.mainPrices.fullPrice,
        currency: selectedSubscription?.currency?.toUpperCase(),
        items: getAxonItem(subscriptions),
      })
  }, [selectedSubscription, subscriptions])

  const getCurrentPrice = useCallback(
    (value) => getCalculatedPrice(value, taxAmount, country),
    [country, taxAmount],
  )

  const getOldPrice = useCallback(
    ({
      price,
      isFormattedDiscount,
    }: {
      price: number
      isFormattedDiscount?: boolean
    }) => {
      const discount = dynamicDiscount?.amount || DEFAULT_DYNAMIC_DISCOUNT

      const calculatedOldPrice =
        price / ((ONE_HUNDRED_PERCENTS - discount) / ONE_HUNDRED_PERCENTS)

      if (!isFormattedDiscount) {
        return (+getCurrentPrice(calculatedOldPrice)).toFixed(2)
      }

      return (
        Math.ceil(+getCurrentPrice(calculatedOldPrice)) - DECORATING_DISCOUNT
      ).toFixed(2)
    },
    [dynamicDiscount?.amount, getCurrentPrice],
  )

  const oldSubscriptionPrice = useMemo(
    () =>
      getOldPrice({
        price: trialPrice,
        isFormattedDiscount: false,
      }),
    [trialPrice, getOldPrice],
  )

  return (
    <>
      <S.Total>
        <S.TotalTitle
          $hasSmallText={
            lang !== Locale.JAPANESE &&
            lang !== Locale.DEUTSCH &&
            lang !== Locale.ENGLISH
          }
        >
          <Trans i18nKey="purchaseWithoutIntro.totalDueToday" />
        </S.TotalTitle>

        <S.PriceContainer>
          <S.PriceBlock>
            <S.PriceWithoutDiscount
              $hasSmallText={
                currency === Currency.BRL || currency === Currency.MXN
              }
            >
              <Trans
                i18nKey="purchase1.checkout.price"
                values={{
                  price: formatJaPriceWithCommas(
                    oldSubscriptionPrice,
                    currency,
                  ),
                  currency: CURRENCY_SYMBOLS[currency],
                }}
              />
            </S.PriceWithoutDiscount>
            <S.PriceWithDiscount
              $hasSmallText={
                currency === Currency.BRL || currency === Currency.MXN
              }
            >
              <Trans
                i18nKey="purchase1.checkout.price"
                values={{
                  price: formatJaPriceWithCommas(
                    getCurrentPrice(trialPrice),
                    currency,
                  ),
                  currency: CURRENCY_SYMBOLS[currency],
                }}
              />
            </S.PriceWithDiscount>
          </S.PriceBlock>

          <S.YouSave>
            <Trans
              i18nKey="purchaseWithoutIntro.youSave"
              values={{
                discountPercentage:
                  dynamicDiscount?.amount || discountPercentage,
              }}
            />
          </S.YouSave>
        </S.PriceContainer>
      </S.Total>

      <S.Disclaimer>
        <Trans
          i18nKey="purchaseWithoutIntro.smallDisclaimer"
          values={{
            currency: CURRENCY_SYMBOLS[currency],
            dailyPrice: formatJaPriceWithCommas(
              getCurrentPrice(trialPrice),
              currency,
            ),
            weeklyPrice: formatJaPriceWithCommas(
              getCurrentPrice(fullPrice),
              currency,
            ),
          }}
        />
      </S.Disclaimer>

      <S.Benefit>
        <SvgImage svg={calendarIcon} width={24} height={24} />

        <S.TextBlock>
          <S.BenefitTitle>
            <Trans i18nKey="purchaseWithoutIntro.noCommitment" />
          </S.BenefitTitle>

          <S.BenefitDescription>
            <Trans i18nKey="purchaseWithoutIntro.noCommitmentDescription" />
          </S.BenefitDescription>
        </S.TextBlock>
      </S.Benefit>

      <S.Benefit>
        <SvgImage svg={markdownIcon} width={24} height={24} />

        <S.TextBlock>
          <S.BenefitTitle>
            <Trans i18nKey="purchaseWithoutIntro.moneyBack" />
          </S.BenefitTitle>

          <S.BenefitDescription>
            <Trans i18nKey="purchaseWithoutIntro.moneyBackDescription" />
          </S.BenefitDescription>
        </S.TextBlock>
      </S.Benefit>

      {children}
    </>
  )
}
