import { TimeInterval } from 'root-constants'

export function createUpsellProductId({
  periodName,
  periodQuantity,
  price,
  trialPrice,
  periodDays,
}: {
  periodName: TimeInterval | null
  periodQuantity: number
  price: number
  trialPrice: number
  periodDays: number
}): string {
  return `dancebit_${periodDays}_days_${trialPrice}_${periodQuantity}_${periodName}_${price}_upsell`
}
