import styled, { css } from 'styled-components'

import { Timer } from 'modules/purchase/components/Timer'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledSubheaderWithTimerTwoWeek = {
  Title: styled.h2`
    padding-bottom: 12px;
    color: ${Color.DARK};
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    max-width: 256px;
  `,
  Text: styled.p`
    padding-bottom: 8px;
    color: ${Color.DARK};
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  `,
  TimerContainer: styled.div<{ $isButtonVisible?: boolean }>`
    margin-bottom: 16px;
    text-align: center;
    border-radius: 20px;
    border: 1px solid #ffdad6;
    background: #ffe5e2;
    padding: 16px 24px;

    ${({ $isButtonVisible }) =>
      $isButtonVisible &&
      css`
        position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        border-radius: 0;
        width: 100%;
        display: flex;
        padding: 8px 16px;
        justify-content: space-between;

        @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
          max-width: 360px;
        }
      `}
  `,
  TimerText: styled.p<{ $isButtonVisible?: boolean }>`
    max-width: ${({ $isButtonVisible }) => $isButtonVisible && '84px'};
    display: flex;
    align-items: center;
    text-align: left;
    color: ${Color.DARK};
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  `,
  SubheaderTimer: styled(Timer)`
    min-width: 88px;
    padding: 0 10px 0 15px;

    & div {
      font-size: 34px;
      font-weight: 700;
      line-height: 38px;
    }
  `,
  ButtonContainer: styled.div<{ $isButtonVisible?: boolean }>`
    display: ${({ $isButtonVisible }) =>
      $isButtonVisible ? 'initial' : 'none'};
    padding: 0;
  `,
  TimerButton: styled.button`
    height: 48px;
    padding: 10px;
    border: none;
    border-radius: 30px;
    background-color: ${Color.PRIMARY};
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: ${Color.WHITE};
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
  `,
  Time: styled.div``,
  Timer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  `,
  TimerSign: styled.div`
    border-radius: 10px;
    background: ${Color.WHITE};
    padding: 6px;
    width: 44px;
    height: 44px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: #eb3524;
    text-align: center;
  `,
}
