import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import { resetErrorAction } from 'root-redux/actions/common'
import { getUserStatusAction } from 'root-redux/actions/user'
import {
  selectActionList,
  selectError,
  selectIsPrimerRetryProcessing,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { useLockScroll } from 'hooks/useLockScroll'

import {
  AnimationState,
  DEFAULT_ERROR_DESCRIPTION,
} from 'modules/purchase/constants'

import paymentErrorAnimation from 'assets/animation/paymentErrorAnimation.json'
import paymentSuccessAnimation from 'assets/animation/paymentSuccessAnimation.json'
import paymentWaitingAnimation from 'assets/animation/paymentWaitingAnimation.json'

import { Color, DEFAULT_MODAL_TIMEOUT } from 'root-constants'

import {
  PURCHASE,
  UPDATE_PRIMER_CLIENT_SESSION,
} from '../../redux/actions/common'
import { StyledPaymentWaitingModal as S } from './PaymentPrimerWaitingModal.styles'

type TProps = {
  isPaymentWaitingShown: boolean
  tryAgain?: () => void
  setIsPaymentWaitingShown: (value: boolean) => void
}

export const PaymentPrimerWaitingModal: React.FC<TProps> = ({
  isPaymentWaitingShown,
  setIsPaymentWaitingShown,
  tryAgain,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const uuid = useSelector(selectUUID)
  const fetchingActionsList = useSelector(selectActionList)
  const error = useSelector(selectError)
  const isPrimerRetryProcessing = useSelector(selectIsPrimerRetryProcessing)

  const rootRef = useRef<HTMLDivElement>(null)
  const loadingAnimationRef = useRef<HTMLDivElement | null>(null)
  const completeAnimationRef = useRef<HTMLDivElement | null>(null)
  const errorAnimationRef = useRef<HTMLDivElement | null>(null)

  const [animationState, setAnimationState] = useState<AnimationState | null>(
    null,
  )

  const isPurchaseInProcess = useMemo(
    () =>
      fetchingActionsList.includes(PURCHASE) ||
      fetchingActionsList.includes(UPDATE_PRIMER_CLIENT_SESSION),
    [fetchingActionsList],
  )

  const handlePaymentWaitingTimeout = useCallback(() => {
    dispatch(getUserStatusAction(uuid))
  }, [dispatch, uuid])

  useEffect(() => {
    window.ononline = () => {
      dispatch(getUserStatusAction(uuid))
    }
  }, [dispatch, uuid])

  useEffect(() => {
    if (isPurchaseInProcess) {
      setIsPaymentWaitingShown(true)
    }

    if (
      !isPurchaseInProcess &&
      isPaymentWaitingShown &&
      error &&
      !isPrimerRetryProcessing
    ) {
      setAnimationState(AnimationState.ERROR)
    }

    if (
      !isPurchaseInProcess &&
      isPaymentWaitingShown &&
      !error &&
      !isPrimerRetryProcessing &&
      window.navigator.onLine
    ) {
      setAnimationState(AnimationState.SUCCESS)
    }

    if (isPurchaseInProcess || isPrimerRetryProcessing) {
      setAnimationState(AnimationState.LOADING)
    }
  }, [
    error,
    isPurchaseInProcess,
    isPaymentWaitingShown,
    setIsPaymentWaitingShown,
    isPrimerRetryProcessing,
  ])

  useEffect(() => {
    switch (animationState) {
      case AnimationState.SUCCESS:
        if (completeAnimationRef.current) {
          lottie.loadAnimation({
            container: completeAnimationRef.current,
            animationData: paymentSuccessAnimation,
            loop: false,
          })
        }
        break
      case AnimationState.ERROR:
        if (errorAnimationRef.current) {
          lottie.loadAnimation({
            container: errorAnimationRef.current,
            animationData: paymentErrorAnimation,
            loop: false,
          })
        }
        break
      default:
        if (loadingAnimationRef.current) {
          lottie.destroy()

          lottie.loadAnimation({
            container: loadingAnimationRef.current,
            animationData: paymentWaitingAnimation,
            loop: true,
          })
        }
        break
    }

    return () => lottie.destroy()
  }, [animationState])

  useEffect(() => {
    if (isPaymentWaitingShown && rootRef.current) {
      rootRef.current.focus()
    }
  }, [isPaymentWaitingShown])

  useEffect(() => {
    let timeoutId

    if (animationState === AnimationState.SUCCESS && isPaymentWaitingShown) {
      timeoutId = setTimeout(handlePaymentWaitingTimeout, DEFAULT_MODAL_TIMEOUT)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [
    animationState,
    dispatch,
    handlePaymentWaitingTimeout,
    isPaymentWaitingShown,
    setIsPaymentWaitingShown,
    uuid,
  ])

  const handleResetError = useCallback(() => {
    if (window.navigator.onLine) {
      dispatch(getUserStatusAction(uuid))
      setIsPaymentWaitingShown(false)
      setAnimationState(null)
      setAnimationState(AnimationState.LOADING)
      dispatch(resetErrorAction())

      tryAgain && tryAgain()
    }
  }, [dispatch, setIsPaymentWaitingShown, tryAgain, uuid])

  useLockScroll(isPaymentWaitingShown)

  return (
    <S.Wrapper $isShown={isPaymentWaitingShown} ref={rootRef} tabIndex={-1}>
      <S.Content>
        <S.LottieContainer>
          {animationState === AnimationState.LOADING && (
            <>
              <S.Animation ref={loadingAnimationRef} />
              <S.Title>{t`purchase1.paymentWaiting.processing`}</S.Title>
              <S.Subtitle>
                {t`purchase1.paymentWaiting.processingPayment`}
              </S.Subtitle>
            </>
          )}
          {animationState === AnimationState.SUCCESS && (
            <>
              <S.Animation ref={completeAnimationRef} />
              <S.Title>
                <Trans i18nKey="purchase1.paymentWaiting.paymentWasSuccessful" />
              </S.Title>
            </>
          )}
          {animationState === AnimationState.ERROR && (
            <>
              <S.Animation ref={errorAnimationRef} />
              <S.Subtitle>
                {t`purchase1.paymentWaiting.errorDuringPayment`}
              </S.Subtitle>
              <S.Info>
                {error?.description || error || DEFAULT_ERROR_DESCRIPTION}
              </S.Info>
              <S.ErrorButton
                type="button"
                onClick={handleResetError}
                style={{
                  backgroundColor: Color.ERROR,
                }}
              >
                {t`actions.tryAgain`}
              </S.ErrorButton>
            </>
          )}
        </S.LottieContainer>
      </S.Content>
    </S.Wrapper>
  )
}
