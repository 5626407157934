import { Cohort } from 'root-constants'

import { PageId } from './pages'

export const PROGRESSBAR_STEPS = [
  [{ id: PageId.LOGIN, text: 'commonComponents.buyPlan' }],
  [{ id: PageId.DOWNLOAD, text: 'commonComponents.createAccount' }],
]

export const CHAT_BOT_PROGRESSBAR_STEPS = [
  [{ id: PageId.LOGIN, text: 'commonComponents.buyPlan' }],
  [{ id: PageId.DOWNLOAD, text: 'commonComponents.createAccount' }],
]

export const UPSELL_PROGRESSBAR_STEPS = [
  [{ id: PageId.UPSELL, text: 'commonComponents.buyPlan' }],
  [{ id: PageId.LOGIN, text: 'commonComponents.premiumBundle' }],
  [{ id: PageId.DOWNLOAD, text: 'commonComponents.createAccount' }],
]

export const PROGRESS_BAR_STEPS_MAP = {
  [Cohort.DANCEBIT_SHORT]: CHAT_BOT_PROGRESSBAR_STEPS,
  [Cohort.DANCEBIT_CHAT_UPGRADE_12]: CHAT_BOT_PROGRESSBAR_STEPS,
  [Cohort.DANCEBIT_CHAT_UPGRADE_12_PDF]: CHAT_BOT_PROGRESSBAR_STEPS,
  [Cohort.DANCEBIT_CHAT_UPGRADE_12_MEAL]: CHAT_BOT_PROGRESSBAR_STEPS,
}
