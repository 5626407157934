import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Option, OptionType } from 'components/Option'

import { selectSubscriptionList } from 'root-redux/selects/common'

import { getAxonItem } from 'helpers/getAxonItem'

import { SubscriptionItemNewYear } from 'modules/purchase/components/SubscriptionItemNewYear'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import {
  selectSubscription,
  selectSubscriptionId,
} from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { StyledSubscriptionsBlockIntroOffer as S } from './SubscriptionsBlockNewYear.styles'

type TProps = {
  elemForComparisonRef?: React.RefObject<HTMLDivElement>
  disclaimer: React.ReactNode
  children: React.ReactNode
}

export const SubscriptionsBlockNewYear: React.FC<TProps> = ({
  elemForComparisonRef,
  disclaimer,
  children,
}) => {
  const dispatch = useDispatch()

  const subscriptions = useSelector(selectSubscriptionList)
  const selectedSubscription = useSelector(selectSubscription)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)

  useEffect(() => {
    selectedSubscription &&
      window.axon &&
      window.axon('track', 'view_item', {
        value:
          selectedSubscription?.trialPrices?.fullPrice ||
          selectedSubscription?.mainPrices.fullPrice,
        currency: selectedSubscription?.currency?.toUpperCase(),
        items: getAxonItem(subscriptions),
      })
  }, [selectedSubscription, subscriptions])

  const handleChange = useCallback(
    (value) => {
      const checkedSubscription = subscriptions.find(({ id }) => id === value)
      dispatch(
        setSelectedSubscriptionAction(checkedSubscription as ISubscription),
      )
    },
    [dispatch, subscriptions],
  )

  return (
    <S.Wrapper>
      {subscriptions.map((subscription) => (
        <Option
          type={OptionType.RADIO}
          name="subscription"
          value={subscription.id}
          key={subscription.planId}
          onChange={handleChange}
          withoutMargin
        >
          <SubscriptionItemNewYear
            subscription={subscription}
            isSelected={subscription.id === selectedSubscriptionId}
          />
        </Option>
      ))}

      <S.ButtonContainer ref={elemForComparisonRef}>
        {children}
      </S.ButtonContainer>

      {disclaimer}
    </S.Wrapper>
  )
}
