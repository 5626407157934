import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { COHORTS_WITH_V2_PAGES_MAP } from 'page-constants'
import {
  ADULT_COHORT,
  Cohort,
  GROWTHBOOK_EXPERIMENT,
  SEVEN_DAY_PLAN_COHORTS,
  TRIAL_COHORT,
} from 'root-constants'

const CLEAR_CHILD_COHORT = 'db_intro_fast1'

export const useCohortInfo = (): Record<string, boolean> => {
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const hasDynamicParamsTest = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.DAN_1172)

  const cohortToUse = (parentCohort || cohort) as Cohort

  const isSaleFlow = useMemo(
    () =>
      cohortToUse === Cohort.DB_BELLY_SALE ||
      cohort === Cohort.DB_ADULT_SALE ||
      cohort === Cohort.DB_GIFT ||
      cohort === Cohort.DB_BELLY_WEEK ||
      hasDynamicParamsTest,
    [hasDynamicParamsTest, cohort, cohortToUse],
  )

  const isGiftFlow = useMemo(() => cohort === Cohort.DB_GIFT, [cohort])

  const isTrialCohort = useMemo(() => TRIAL_COHORT.includes(cohortToUse), [
    cohortToUse,
  ])

  const isBellyFlow = useMemo(
    () => COHORTS_WITH_V2_PAGES_MAP.includes(cohortToUse),
    [cohortToUse],
  )

  const isSevenDayPlanCohort = useMemo(
    () =>
      SEVEN_DAY_PLAN_COHORTS.includes(cohortToUse) &&
      cohort !== Cohort.DB_BELLY_WEEK2,
    [cohort, cohortToUse],
  )

  const isShortFlow = useMemo(() => cohortToUse === Cohort.DANCEBIT_SHORT, [
    cohortToUse,
  ])

  const isSomaticFlow = useMemo(() => cohortToUse === Cohort.DB_SOMATIC, [
    cohortToUse,
  ])

  const isLegsFlow = useMemo(() => cohortToUse === Cohort.DB_LEGS, [
    cohortToUse,
  ])

  const isInfluencerFlow = useMemo(() => cohortToUse === Cohort.DB_INFLUENCER, [
    cohortToUse,
  ])

  const isAgeFlow = useMemo(
    () => ADULT_COHORT.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const isNewYearFlow = useMemo(
    () => cohort === Cohort.DB_WHEEL2 || cohort === Cohort.DB_WHEEL,
    [cohort],
  )

  const isClearFlow = useMemo(() => (cohort as string) === CLEAR_CHILD_COHORT, [
    cohort,
  ])

  return {
    isClearFlow,
    isGiftFlow,
    isLegsFlow,
    isSomaticFlow,
    isBellyFlow,
    isInfluencerFlow,
    isSaleFlow,
    isTrialCohort,
    isSevenDayPlanCohort,
    isShortFlow,
    isAgeFlow,
    isNewYearFlow,
  }
}
